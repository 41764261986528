import React, { useState } from "react";
import { Typography, Button } from "@mui/material";

const ReadMore = ({
  text,
  fontSize = 16,
  fontWeight = 400,
  color,
  canReadMore = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <Typography
        sx={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          textAlign: "justify",
          color: "gray",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: isExpanded ? "none" : 3,
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </Typography>
      {canReadMore && text && (
        <Button
          onClick={toggleReadMore}
          sx={{ textTransform: "none", color: "black", textAlign: "start" }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </Button>
      )}
    </div>
  );
};

export default ReadMore;
