import { Circle } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Home3 from '../../assets/home3.jpg'
import Check1 from '../../assets/checklist1.png'
import Check2 from '../../assets/explore.png'
import Check3 from '../../assets/homeimprove.png'
import { scrollUp } from '../../constants'
import SEO from '../../components/SEO'

const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${Home3})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const NewRoofBenefits = () => {
    const classes = useStyles()
    const navigate = useNavigate()

    const choices = [
        `Experience and Expertise - BPO Homes has been in the real estate industry for many years, and our team of experts has a deep understanding of the local market.`,
        `Wide Range of Properties - We offer a wide range of properties that fit a variety of budgets, styles, and needs, so you can find the perfect home that meets all of your criteria.`,
        `Exceptional Customer Service - We pride ourselves on providing exceptional customer service, and our team is dedicated to ensuring that our clients are happy with their home buying experience.`,
        'Detailed Home Inspections - Our home inspectors are highly trained and experienced in identifying potential issues with a property, including the roof, and can provide detailed reports that help homebuyers make informed decisions.',
        'Competitive Pricing - We offer competitive pricing for all of our services, including home inspections, so you can get the most value for your money.'
    ]


  return (
    <div>
            <SEO>
				<title>The Benefits of a New Roof for Your Home</title>
				<meta name='og:title' content="The Benefits of a New Roof for Your Home" />
				<meta
					name='description'
					content="Improve your home and protect your family with a new roof. Discover the benefits of installing a new roof with our expert guide. Get started today!"
				/>
				<meta
					name='og:description'
					content="Improve your home and protect your family with a new roof. Discover the benefits of installing a new roof with our expert guide. Get started today!"
				/>
			</SEO>
        <Box className={classes.banner}></Box>
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
            <Typography component={'h1'} textAlign={'center'} gutterBottom variant='h4'>The Benefits of a New Roof for Your Home</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor}} />
        </Box>
        
        <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                
                    {/* BENEFITS OF NEW ROOF */}

                    <Typography paragraph>
                        Of all the things that make up a home, the roof is perhaps one of the most important. It protects the house and its occupants from the elements, provides insulation, and adds to the overall curb appeal of the property. Whether you're a first-time homebuyer or a seasoned real estate investor, it's important to understand the benefits of a new roof for your home. In this article, we'll explore some of the key advantages and explain why BPO Homes is the ideal partner for your home buying needs. 
                    </Typography>
                    <Typography className={classes.title} variant='h6'>Improved Curb Appeal</Typography>
                    <Typography paragraph>
                        When it comes to selling a home, first impressions are everything. A new roof can significantly improve the overall look of a home and create a lasting impression with potential buyers. A freshly installed roof not only looks better, but it can also increase the value of your property. By choosing BPO Homes, you can find homes with a new roof, and give yourself a competitive edge when selling.

                    </Typography>
                    <Typography className={classes.title} variant='h6'>Increased Energy Efficiency</Typography>
                    <Typography paragraph>
                        Energy efficiency is a crucial factor in modern homes. A new roof can improve a home's energy efficiency, which in turn saves on heating and cooling costs. Newer roofing materials are designed to reflect heat, which can reduce the workload of air conditioning units during the summer months.
                    </Typography>
                    <Typography paragraph>
                        A well-insulated roof can also reduce the amount of heat lost in the winter, keeping your home warmer and more comfortable. BPO Homes can help you find homes with energy-efficient features that can significantly reduce your monthly utility bills.

                    </Typography>
                    <Typography className={classes.title} variant='h6'>Better Protection</Typography>
                    <Typography paragraph>
                        The primary function of a roof is to protect your home from the elements. A new roof can provide better protection from rain, snow, and other weather conditions. With a new roof, you can be confident that your home is protected from leaks, mold, and other water-related damage. This can save you thousands of dollars in costly repairs in the long run. By choosing BPO Homes, you can have the assurance that your new home has a roof that's in excellent condition.

                    </Typography>

                    <Typography className={classes.title} variant='h6'>Enhanced Safety</Typography>
                    <Typography paragraph>
                    An old or damaged roof can pose a safety hazard. It can cause leaks, which can lead to water damage or mold, and it can even collapse if it's not sturdy enough to support itself. 
                    A new roof can improve the safety of your home, giving you peace of mind that your family and belongings are protected. BPO Homes' inspectors are experts in identifying any safety issues during the home inspection process, so you can rest assured that your new home is safe.

                    </Typography>
                    <Typography className={classes.title} variant='h6'>Longer Lifespan</Typography>
                    <Typography paragraph>
                        The lifespan of a typical roof can vary from 20 to 50 years. A new roof can have a longer lifespan, which can save you money on future repairs or replacement. By choosing a home with a newer roof, you can be confident that it will last for years to come. BPO Homes can help you find homes with well-maintained roofs or recently replaced roofs, so you don't have to worry about costly roof repairs or replacement any time soon.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Increased Home Value</Typography>
                    <Typography paragraph>
                        A new roof can significantly increase the resale value of a home. It can improve the overall aesthetic appeal of the property, and it can provide a sense of security to potential buyers. If you're planning to sell your home in the future, a new roof can give you a competitive edge in the real estate market. By choosing BPO Homes, you can find a home with a new roof that can increase your home's overall value.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Why Choose BPO Homes for Home Buying?</Typography>
                    <Typography paragraph>
                        When it comes to buying a home, choosing the right real estate company can make all the difference. Here are some reasons why you should choose BPO Homes for your home buying needs:
                    </Typography>

                    <List>
                    {
                        choices?.map((el, index) => {
                            return(
                                <ListItem>
                                    <ListItemIcon sx={{minWidth: '35px'}}><Circle sx={{fontSize: '.7rem'}} color='secondary' /></ListItemIcon>
                                    <ListItemText>{el}</ListItemText>
                                </ListItem>
                            )
                        })
                    }
                    </List>

                    <Typography className={classes.title} variant='h6'>Conclusion</Typography>
                    <Typography paragraph>
                        A new roof can bring a wide range of benefits to your home, from improving its overall appearance to enhancing its safety and increasing its value. And when it comes to buying a home, BPO Homes is the company you can trust. Our commitment to providing exceptional customer service, expert guidance, and detailed home inspections can help you find the perfect home with a high-quality roof. 
                    </Typography>
                    <Typography paragraph>
                        So, why wait? Contact us today to start your home buying journey and see the difference that BPO Homes can make for you.
                    </Typography>


                   
                    
                </Box>
            </Container>
        </Box>

            <Box mt={4} mb={5}>
            <Container>
                    <Typography  mt={3}>To prepare for a sale, you should plan to set aside at least two months. This will give you time to investigate the local market, select a trustworthy agent, properly clean your house, and finish any little repairs.
                    </Typography>
                    <Typography variant='h6' mt={2} mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/bpo-guide')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Why Buyers Need A Broker's Price Opinion</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/explore')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' mb={3.5} color={'textSecondary'}>Explore My Options</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/home-improvement')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Home Improvement and Remodeling Trends in 2022</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>
    </div>
  )
}

export default NewRoofBenefits