import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
}));

export default ExpandMore;

export const ViewMoreCard = ({ display, readmore, withViewMore = true }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card sx={{ borderRadius: 2 }} elevation={0}>
      <CardContent sx={{ py: 0 }}>{display}</CardContent>
      {withViewMore && (
        <>
          <Collapse
            sx={{ px: 2, pt: 0, mt: 0 }}
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            {readmore}
          </Collapse>
          <CardActions
            sx={{ m: 0, p: 0, textAlign: "right", mt: 2 }}
            disableSpacing
          >
            <Typography ml={"69%"} alignSelf={"right"} variant="caption">
              View More
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
        </>
      )}
    </Card>
  );
};
