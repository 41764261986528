import moment from 'moment'
/**
 *
 * @param {Ref} element a ref of the element to scroll to the top off
 */
const scrollToTop = (element) => {
	element.current.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
};

/**
 *
 * @param {number} days the number days to calculate for after today (including today)
 * @returns an array with key display in the form (Mar 30, Monday) and iso date of as value
 *  
 **/

const getDatesFromToday = (days = 30, weekday = 'short') => {
	const dates = [];
	for (let i = 0; i < days; i++) {
		dates.push({
			display: new Date(new Date().setDate(new Date().getDate() + i))
				.toDateString('en', {
					month: 'short',
					weekday,
					day: 'numeric',
				}),
				
			value: new Date().setDate(new Date().getDate() + i),
		});
	}

	return dates;
};

const getOpenHouseDateFormat = (val) => {
	// console.log(val)
	const day = val.substr(0,4)
	const time = val.substr(5)
	const options = { weekday: 'short'};
	const event = new Date(Date(day)).toLocaleDateString('en-GH', options); 
	return `${event.toUpperCase()}, ${time.toUpperCase()}`
}

const getOpenHouseTime = (date, time) => {
	const year = new Date().getFullYear()
	const start = moment(`${date?.substr(0,4)}/${year}`).format('ddd'); 
	return (`OPEN ${start}, ${time?.substr(4)}`).toUpperCase()
}

const crmlsOpenHouseTime = (start, end) => {
	const st = start?.slice(0, -1)
	const startDate = st?.join('-')
	
	const ed = end?.slice(0, -1)
	const endDate = ed?.join('-')

	const openDate = moment(`${startDate}`).format('ddd'); 
	const openTimeFrom = new Date(startDate)?.toLocaleTimeString('en-US', {hour: 'numeric', minute:'numeric'})
	const openTimeTo = new Date(endDate)?.toLocaleTimeString('en-US', {hour: 'numeric', minute:'numeric'})

	return (`OPEN ${openDate}, ${openTimeFrom} - ${openTimeTo}`).toUpperCase()
}

//get name from string with underscore and capitalize first letter
const removeUnderandCapitalize = (string) => {
	return string
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

/**
 *
 * @param {*} number number to format
 * @param {*} decimals number of decimals to show, default 2
 * @returns formatted number
 */
const formatNumber = (number, decimals = 2) => Number(number).toLocaleString('en-US', { minimumFractionDigits: decimals });

/**
 *
 * @param {*} url non formatted url
 * @returns url with embedd
 */
function getYoutubeEmbedLink(url) {
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
	const match = url.match(regExp);

	return match && match[2].length === 11 ? match[2] : null;
}

/**
 *
 * @param {[]} subs
 * @returns
 */

const getSubscriptionStatus = (subs) => {
	const status = {
		bpoFarm: false,
		premierAgentWebsite: false,
		liveLeads: false,
		dealAnalyzer: false,
	};

	if (subs.length === 0) {
		return status;
	}
	if (subs.some((item) => item.package === 'bpo-farm')) {
		return {
			bpoFarm: true,
			premierAgentWebsite: true,
			liveLeads: true,
			dealAnalyzer: true,
		};
	}
	if (subs.some((item) => item.package === 'combo-package')) {
		status.dealAnalyzer = true;
		status.premierAgentWebsite = true;
		status.liveLeads = true;
	}
	if (subs.some((item) => item.package === 'premier-agent-website')) {
		status.premierAgentWebsite = true;
		status.liveLeads = true;
	}
	if (subs.some((item) => item.package === 'deal-analyzer')) {
		status.dealAnalyzer = true;
	}

	return status;
};

/**
 *
 * @param {string} url URL to check
 * @returns a url with https:// if it doesn't have it
 */
const getValidUrl = (url) => {
	if (!url) {
		return '';
	}
	if (url.includes('://')) return url;
	else return 'https://' + url;
};

/**
 *
 * @param {string} phoneNumberString number to return in the format +1 (123) 456-7890
 * @returns
 */
function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		// var intlCode = match[1] ? '+1 ' : '';
		return ['(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

function convertCurrencySystem(labelValue) {
	// Nine Zeroes for Billions
	return Math.abs(Number(labelValue)) >= 1.0e9
		? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
		: // Six Zeroes for Millions
		Math.abs(Number(labelValue)) >= 1.0e6
		? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
		: // Three Zeroes for Thousands
		Math.abs(Number(labelValue)) >= 1.0e3
		? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
		: Math.abs(Number(labelValue));
}

function getAllDaysInMonth(year, month) {
	const today = new Date().getDate();
	const date = new Date(year, month, today);

	const dates = [];

	while (date.getMonth() === month) {
		dates.push(new Date(date).toDateString());
		date.setDate(date.getDate() + 1);
	}

	return dates;
}

function commaSeparate(x) {
	return x === 0 || x === '0'
		? 0
		: x
		? x
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				.trim()
		: '';
}


export {
	scrollToTop,
	getDatesFromToday,
	removeUnderandCapitalize,
	formatNumber,
	getYoutubeEmbedLink,
	getSubscriptionStatus,
	getValidUrl,
	formatPhoneNumber,
	convertCurrencySystem,
	getAllDaysInMonth,
	commaSeparate,
	getOpenHouseDateFormat,
	getOpenHouseTime,
	crmlsOpenHouseTime
};
