import { Favorite, FavoriteBorder, Share } from '@mui/icons-material'
import { Box, Chip, CircularProgress, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addToFavorite, removeFavorite } from '../actions'

const MobileListingPreview = (props) => {
    const {listing, open, closeDrawer, addToFavorite, removeFavorite} = props
    const currentUser = useSelector(state => state.currentUser)
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const [isFav, setIsFav] = useState(false)


    useEffect(()=> {
        if(currentUser){
            //console.log(currentUser)
            const res = currentUser?.favs.filter(el => {
                return el.property_id === listing?.mlsId
            }).length > 0
            setIsFav(res)
        }else{
            setIsFav(false)
        }
    },[currentUser, listing])

    const addToFav = async(e) => {
        e.stopPropagation()
        if(currentUser){
            if(currentUser.favs.filter(el => el.property_id === listing?.mlsId)[0]){
                //remove from favs
                setLoad(true)
                const status = await removeFavorite(listing?.mlsId)
                if(status === 204){
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Property removed from favorites'})
                }else{
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Sorry, could not add to favorite'})
                }
            }else{
                //add to favs
                setLoad(true)
                
                const data = {
                    user_id: currentUser.id,
                    property_id: listing?.mlsId,
                    address: listing?.address,
                    price: Number(listing?.listPrice),
                    image: listing?.photos[0],
                    bedrooms: listing?.property?.bedrooms,
                    baths: listing?.property?.bathsFull,
                    area: listing?.property?.area,
                    list_date: listing?.listDate
                }
                //console.log(data)
                const status = await addToFavorite(data)
                if(status === 200){
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Property added to favorites'})
                }else{
                    setLoad(false)
                    dispatch({type: 'SUCCESS', payload: 'Sorry, could not add to favorite'})
                }
            }
        }else{
            dispatch({type: 'AUTH_LOGIN'})
        }
    }

    const getDaysOnMarket = (listDate) => {
        if(listDate){
            const diff = new Date(Date.now()).getTime() - new Date(listDate).getTime()
            const dy = Math.round(diff / (1000 * 3600 * 24))
            if(dy === 0 || dy === 1){
                return 'New on BPO Homes'
            }else{
                return `${dy} days on BPO Homes`
            }
        }else{
            return '0 days'
        }
    }
  return (
    <Drawer open={open} onClose={closeDrawer} anchor='bottom' sx={{'& .MuiBackdrop-root': { background: 'rgba(0,0,0, 10%)'}}}>
				<Box onClick={()=> window.location.assign(`/listing/${listing?.mlsId}/details/${listing?.listing === 'mls' ? 'mls' : 'crmls'}`)} sx={{
						backgroundImage: `linear-gradient(180deg, rgba(0,0,0,50%), rgba(0,0,0,50%)), url(${listing?.photos[1] ? listing?.photos[1]: listing?.photos[0]})`,
						backgroundPosition: 'center',
						backgroundSize: 'cover', 
						height: '12rem', padding: '1rem', display: 'flex', 
						flexDirection: 'column', 
						justifyContent: 'space-between',
						color: '#fff'
					}}>
					<Box sx={{display: 'flex', gap: '.5rem', justifyContent: 'space-between', alignItems: 'center'}}>
						<Chip size='small' color='primary' label={<Typography sx={{fontSize: '.7rem', fontWeight: 400}}>{getDaysOnMarket(listing?.listDate)}</Typography>} />
						<span>
							<IconButton disableTouchRipple onClick={addToFav}>
                            { load ? <CircularProgress size='1.33rem' color='paper' /> 
                                : isFav ? <Favorite color='paper' fontSize='small' /> : <FavoriteBorder color='paper' fontSize='small' /> 
                            }
                            </IconButton>
							<IconButton disableTouchRipple><Share color='paper' /></IconButton>
						</span>
					</Box>
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Box width={'45%'}>
							<Typography variant='h5'>${listing?.listPrice?.toLocaleString()}</Typography>
							<Typography variant='body2' sx={{fontSize: '.8rem'}} noWrap>{_.startCase(listing?.address.full + ', ' + listing?.address.city + ', ' + listing?.address.state + listing?.address.postalCode)}  </Typography>
						</Box>
						<Box sx={{display: 'flex', gap: '1rem', marginLeft: 'auto'}}>
							<Stack>
								<Typography textAlign={'center'}>{listing?.property?.bedrooms ? listing?.property?.bedrooms : '__'}</Typography>
								<Typography textAlign={'center'} sx={{fontSize: '.9rem'}}>Beds</Typography>
							</Stack>
							<Divider orientation='vertical' flexItem sx={{bgcolor: '#fff'}} />
							<Stack>
								<Typography textAlign={'center'}>{listing?.property?.bathsFull ? listing?.property?.bathsFull : '__'}</Typography>
								<Typography textAlign={'center'} sx={{fontSize: '.9rem'}}>Baths</Typography>
							</Stack>
							<Divider orientation='vertical' flexItem sx={{bgcolor: '#fff'}}/>
							<Stack>
								<Typography textAlign={'center'}>{listing?.property?.area}</Typography>
								<Typography textAlign={'center'} sx={{fontSize: '.9rem'}}>Sq.Ft</Typography>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Drawer>
  )
}

const mapStateToprops = (state) => {
    return state
}

export default connect(mapStateToprops, {addToFavorite, removeFavorite})(MobileListingPreview)