import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import BG from '../../assets/buyerscore.png'
import Check1 from '../../assets/buycheck.png'
import Check2 from '../../assets/checklist1.png'
import Check3 from '../../assets/checklist2.png'
import { useNavigate } from 'react-router-dom'
import { scrollUp } from '../../constants'


const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
    listIcon: {
        fontSize: '.8rem',
        minWidth: '32px',
        color: '#000'
    }
}))
const BuyerScore = () => {
    const classes = useStyles()
    const navigate = useNavigate()

  return (
    <div>
        <Box className={classes.banner}></Box>
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem'}}>
            <Typography textAlign={'center'} sx={{color: '#fff'}} gutterBottom variant='h5'>How to Raise Your Credit Score to Get Your First Home Mortgage</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '.5rem auto', background: (theme) => theme.primaryColor}} />
        </Box>
        <>
            <Container>
                <Box padding='4rem 0'>
                    <Typography mb={6}>
                        Do you want to raise your credit score? Yes, it's simple to become enamored with the concept of home ownership. A five-bedroom house in your preferred neighborhood with a well-kept yard and, why not? a gorgeous pool is just what you had in mind.
                        But if you want to secure a mortgage (and let's face it, most homebuyers do), you'll probably need to raise your FICO score, often known as your credit score. This score is a condensed evaluation of your history of repaying debts and making timely loan payments. Lenders want to know you'll repay them on time if you borrow money to buy a home, and a credit score is a simple way to calculate those odds.
                        Here is a crash course on how to get the most out of this tiny but crucial number when buying a home.
                    </Typography>

                    {/* Speak */}
                    <Box mt={4} mb={3}>
                        <Typography variant='h6' className={classes.title}>The Credit Report</Typography>
                        <Typography paragraph>
                            Experian, Equifax, and TransUnion are the three main U.S. credit bureaus, and each one publishes its credit scores and reports (a more thorough history that's used to calculate your score). Despite using diverse sources, their scores ought to be approximately comparable. For instance, TransUnion has comprehensive information about prior employers, but Experian takes into account on-time rent payments.
                        </Typography>
                        <Typography paragraph>
                            Financial advisor Bob Forrest of Mutual of Omaha suggests visiting AnnualCreditReport.com to acquire access to these scores and reports because you can get a free copy of your credit report from each credit-reporting agency there once every 12 months. However, your credit score is not included; you must visit each company separately and pay a modest cost for it.
                        </Typography>
                        <Typography paragraph>
                            Alternatively, call your credit card provider: According to Michael Chadwick, proprietor of Chadwick Financial Advisors in Unionville, CT, some companies, including Discover and Capital One, provide free access to scores and reports
                        </Typography>
                    
                    </Box>
                </Box>

            </Container>

            {/* Divider Section */}
            <Box style={{height: '20rem', backgroundImage: `linear-gradient(#1378A595, #1378a5cf), url(${BG})`}}>
                <Container sx={{display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center', width: '70%'}}>
                    <Typography textAlign={'center'} variant='h6' sx={{color: '#fff'}}>When you receive your report, carefully go through each page, paying special attention to the "adverse accounts" section that lists late payments and other mistakes.</Typography>
                </Container>
            </Box>

            <Container>
                <Box>
                    {/* Evaluate */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>Evaluate your situation.</Typography>
                        <Typography paragraph>
                            It's straightforward: The better your credit history, the higher your score, and the greater your chances of getting a mortgage. For a 3.5% down payment, the Federal Housing Administration demands a credit score of at least 580, while large lenders frequently demand at least 620. What can you do, then, if your credit report isn't in great shape? There are techniques to clear it up, so don't panic.
                        </Typography>
                    </Box>

                    {/* credit */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>How to raise your credit score by disputing errors</Typography>
                        <Typography paragraph>
                            According to a 2013 Federal Trade Commission research, 5% of credit reports have mistakes that could falsely lower your score. So, if you find any, begin by writing a dispute letter to the bureau and including as much supporting information as you can, following FTC recommendations. Additionally, you must get in touch with the company that submitted the false information, such as a bank or healthcare provider, and request that it alter the information with the bureau. It can take some time, and you might need to provide evidence to support your claims. But after the inaccurate information has been eliminated, your score ought to increase.
                        </Typography>
                    </Box>

                    {/* Remove */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>Remove One-off Errors</Typography>
                        <Typography paragraph>
                            You have made one or more late payments; who hasn't? Make a phone call to the business that recorded the late payment, and request that it be taken off of your record. "Most organizations will notify their reporting section to delete this from your credit report if you made an oversight and missed just a couple of payments," adds Forrest. It's true that if you have a history of late payments, this won't help, but for oversights and little mistakes, it's a simple approach to raise your credit score.
                        </Typography>
                    </Box>

                    {/* Expand */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>Expand your boundaries</Typography>
                        <Typography paragraph>
                            Paying off your debt is a simple approach to improving your credit score. Not a choice at this time? Here's a clever flaw: Request a credit limit increase from your credit card company instead. Your debt-to-credit ratio—which evaluates how much you owe vs how much you can borrow—improves as a result.
                            If your credit card limit is $1,500, having $1,000 in debt is bad.
                        </Typography>
                        <Typography paragraph>
                            If your limit is $5,000, it's not nearly as severe," says Forrest. The basic math reflects favorably on your borrowing habits: even though you owe the same amount, you are using a far smaller proportion of your available credit.
                        </Typography>
                    </Box>

                    {/* Pay */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>Pay Promptly</Typography>
                        <Typography paragraph>
                            It's time to alter if you frequently make payments after the due date. Your credit score can be raised by you on your own. Commit to paying your bills on time every time; think about setting up automated payments to make sure it gets done.
                        </Typography>
                    </Box>

                    {/* Take */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>Take Your Time</Typography>
                        <Typography paragraph>
                            Negative things (like persistently late or nonexistent payments) can, unfortunately, remain on your record for up to seven years. The positive news in the "payment history" section of your report, which accounts for 35% of your score, changing your habits has a significant impact. Starting early is crucial to ensure that you're in a good position once you start looking at houses and locate one that makes you swoon.
                            After you've improved your credit, it's time to take on the next significant challenge: saving for a down payment.
                        </Typography>
                    </Box>
                    
                    <Box mt={4} mb={5}>
                        <Typography variant='h6' mb={3}>Read Also:</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} lg={4}>
                                <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                        navigate('/blog/bpo-guide')
                                        scrollUp()
                                    }}>
                                    <CardHeader style={{backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                    <CardContent style={{padding: '2rem'}}>
                                        <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                        <Typography variant='body1' color={'textSecondary'}>Why Buyer Needs A Broker's price Opinion</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                        navigate('/blog/buyer-checklist')
                                        scrollUp()
                                    }}>
                                    <CardHeader style={{backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                    <CardContent style={{padding: '2rem'}}>
                                        <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                        <Typography variant='body1' color={'textSecondary'}>Your Best Checklist For Buying A Home</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                    navigate('/blog/buyer-firsttimer')
                                    scrollUp()
                                }}>
                                    <CardHeader style={{backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                    <CardContent style={{padding: '2rem'}}>
                                        <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                        <Typography variant='body1' mb={3.5} color={'textSecondary'}>Buying A Home in 2022?</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>

    </div>
  )
}

export default BuyerScore