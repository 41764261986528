//* @param {Ref} element a ref of the element to scroll to the top off
//*
export const scrollToTop = (element) => {
   element.current.scroll({
       top: 0,
       left: 0,
       behavior: 'smooth',
   });
};


/**
 *
 * @param {string} phoneNumberString number to return in the format +1 (123) 456-7890
 * @returns
 */
export const formatPhoneNumber = (phoneNumberString) => {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		// var intlCode = match[1] ? '+1 ' : '';
		return ['(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

export const convertCurrencySystem = (labelValue) => {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

    : Math.abs(Number(labelValue));

}

export const getAllDaysInMonth = (year, month) => {
	const today = new Date().getDate()
	const date = new Date(year, month, today);
  
	const dates = [];
  
	while (date.getMonth() === month) {
	  dates.push(new Date(date).toDateString());
	  date.setDate(date.getDate() + 1);
	}
  
	return dates;
  }

