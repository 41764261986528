import axios from "axios";

export const brokerageBaseURL = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en-US,en;q=0.9",
  },
});

const securedBrokerageBaseURL = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en-US,en;q=0.9",
  },
});


securedBrokerageBaseURL.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('brokerageToken')

    if (token) {

      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default securedBrokerageBaseURL;
