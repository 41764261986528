import { Circle, Person, Search } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardHeader, Container, Grid, IconButton, InputAdornment, ListItem, ListItemIcon, ListItemText, Stack, styled, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BannerImg from '../../assets/explore.png'
import Check1 from '../../assets/sellerguide.png'
import Check2 from '../../assets/bpoguide.png'
import Check3 from '../../assets/homeimprove.png'
import { scrollUp } from '../../constants'
import base from '../../api/apis'
import { useState } from 'react'
import _ from 'lodash'
import SEO from '../../components/SEO'


const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${BannerImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
    search: {
        margin: '2rem auto',
        width: '70%'
    }
}))

const SearchBox = styled(Stack)(({theme}) => ({
	display: 'block'
}))

const Explore = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const [value, setValue] = useState('')
    const [results, setSearchResult] = useState()
    const [searchFocus, setSearchFocus] = useState(true)

    const lists = [
        'Local market knowledge',
        'Listing costs in some markets have decreased**',
        'Support with negotiating and evaluating offers',
        'Priority placement using tools like 3D home tours on BPO Home'
    ]

    const onSearchHandler = async(e) => { 
        //console.log(e.target.value)
		setValue(e.target.value)
        
		if(value !== ''){
			try {
				const res = await base.get(`register/agents/?service_areas__location__icontains=${e.target.value}`)
                if(res.data){
					setSearchResult(res.data.results)
				}
			} catch (error) {
				console.log(error)
			}
		}else{
            //console.log('its here')
            return
        }
	}

    const clickAway = (e) => {
		window.addEventListener('click', function(e){   
			if (document.getElementById('searchbox').contains(e.target)){
			  setSearchFocus(true)
			} else{
			  setSearchFocus(false)
			}
		  });
	}

    const onAgentSearch = () => {
        window.location.assign(`realty-agents?q=${value}`)
    }

  return (
    <div>
             <SEO>
				<title>Sell Your Home Easily with a Participating BPO Home Premier Agent</title>
				<meta name='og:title' content="Sell Your Home Easily with a Participating BPO Home Premier Agent" />
				<meta
					name='description'
					content="Get local market knowledge, lower listing costs, and expert support with negotiating and evaluating offers when you list your property with a participating BPO Home Premier Agent."
				/>
				<meta
					name='og:description'
					content="Get local market knowledge, lower listing costs, and expert support with negotiating and evaluating offers when you list your property with a participating BPO Home Premier Agent."
				/>
			</SEO>
        <Box className={classes.banner}></Box>
        <Box padding={'4rem 0'} textAlign={'center'}>
            <Container>
                <Typography textAlign={'center'}>When we put you in touch with a participating BPO Homes Agent partner, selling your house is simple. To determine if they are accessible in your area, start by entering your address. Soon they'll be in additional locations!</Typography>
                <Box width={'70%'} sx={{position: 'relative', margin: '0 auto'}}>
                    <TextField id='searchbox' fullWidth sx={{
                        margin: '2rem auto',
                    }} 
                    variant='outlined' 
                    value={value} 
                    onBlur={clickAway}
                    onFocus={()=> setSearchFocus(true)} 
                    onChange={onSearchHandler} 
                    placeholder='Enter a city' 
                    InputProps={{
                        endAdornment: <InputAdornment position='end'><IconButton onClick={onAgentSearch}><Search /></IconButton></InputAdornment>
                    }} />
                    {
                        results?.length > 0 && value.length > 0 && searchFocus ?
                        <Box mt={'-10px'} width={'100%'} bgcolor={'#fff'} sx={{ 
                            overflowY: 'scroll', position:'absolute', top: '90%',
                            zIndex:99, height: '26rem', borderRadius: '0 0 8px 8px',
                            boxShadow: '0 2px 20px rgba(0,0,0,15%)'}}>
                            <SearchBox >
                            <Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: grey[500]}}>Agents near {_.startCase(value)}</Typography>
                            {
                                results?.map(res => {
                                    return (
                                        <ListItem key={res.id} component='a' href={`/agents/${res?.id}`} button >
                                            <ListItemIcon sx={{minWidth: '25px'}}>{<Person sx={{fontSize: '1rem'}} />}</ListItemIcon>
                                            <ListItemText>
                                                <Typography>{res?.first_name + ' ' + res?.last_name}</Typography>
                                                <Typography variant='body2' color={'GrayText'}>{_.startCase(res?.brokerage_address?.toLowerCase()) + ', ' + res?.city }</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })
                            }
                        </SearchBox>
                        </Box>
                        : results?.length === 0 && value.length > 0 && searchFocus ?
                        <Box mt={'-10px'} width={'100%'} bgcolor={'#fff'} sx={{ 
                            overflowY: 'scroll', position:'absolute', top: '90%',
                            zIndex:99, height: '15rem', borderRadius: '0 0 8px 8px',
                            boxShadow: '0 2px 20px rgba(0,0,0,15%)'}}>
                            <SearchBox >
                                <Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: grey[500]}}>Agents near {_.startCase(value)}</Typography>
                                <Typography paragraph textAlign={'center'} >Sorry, no agent found</Typography> 
                                <Button variant='contained' color='secondary' size='small' href='/realty-agents' disableElevation>Search More</Button>
                        </SearchBox>
                        </Box>
                        : null
                    }
                </Box>
            </Container>
        </Box>

        <Box bgcolor={grey[100]} padding={'4rem 0 0 0'}>
            <Container>
                <Typography mb={4} variant='body1'>Why list your property with a participating BPO Home Premier Agent?</Typography>
                <Grid container>
                    {
                        lists?.map((el, index) => {
                            return(
                                <Grid item sm={6} key={index}>
                                    <ListItem>
                                        <ListItemIcon sx={{minWidth: '35px'}}><Circle sx={{fontSize: '.7rem'}} color='secondary' /></ListItemIcon>
                                        <ListItemText>{el}</ListItemText>
                                    </ListItem>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
            {/* Divider Section */}
            <Box sx={{height: '12rem', background: (theme) => theme.secondaryColor, marginTop: '3rem'}}>
                <Container sx={{display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center'}}>
                    <Typography variant='h5' sx={{color: '#fff'}} textAlign='left'>Get A BPO Homes Seller Agent Now!</Typography>
                    <Button size='large' sx={{textTransform: 'none'}} variant='contained' disableElevation color='paper' href='/agents'>Find An Agent</Button>
                </Container>
            </Box>

        </Box>

        <Box mt={4} mb={5}>
            <Container>
                    <Typography variant='h6' mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/sellers-guide')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' mb={3.5} color={'textSecondary'}>Seller's Guide</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                    navigate('/blog/bpo-guide')
                                    scrollUp()
                                }}>
                                <CardHeader style={{backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Why Buyer Needs A Broker's price Opinion</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/home-improvement')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Home Improvement and Remodeling Trends in 2022</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>

    </div>
  )
}

export default Explore