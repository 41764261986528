import React, { useState } from "react";
import { TextComponent } from "./modules/TextComponent";
import { Box, Typography, useMediaQuery } from "@mui/material";

import woman from "../../assets/inverstors/woman.mp4";
import keyVid from "../../assets/inverstors/keyVid.mp4";
import heroBg from "../../assets/inverstors/bgImge.png";
import womanHappy from "../../assets/inverstors/background.png";
import moneyBg from "../../assets/inverstors/background1.png";
import womanLaptop from "../../assets/inverstors/background2.png";
import cartBg from "../../assets/inverstors/background3.png";
import manAndHouse from "../../assets/inverstors/background4.png";
import manAndHouse2 from "../../assets/inverstors/image13.png";
import dealAnalyzer from "../../assets/inverstors/background5.png";
import callAgents from "../../assets/inverstors/background6.png";
import freeFlyers from "../../assets/inverstors/background7.png";
import aiHand from "../../assets/inverstors/background8.png";
import invertorFlyers from "../../assets/inverstors/background9.png";
import manWriting from "../../assets/inverstors/background13.png";
import idxBg from "../../assets/inverstors/background10.png";
import teaching from "../../assets/inverstors/background99.png";
import twoMen from "../../assets/inverstors/background11.png";
import joban from "../../assets/inverstors/background12.png";
import bg14 from "../../assets/inverstors/background14.png";
import socialMedia from "../../assets/inverstors/Social.svg";
import bg15 from "../../assets/inverstors/background15.png";
import bg16 from "../../assets/inverstors/background16.png";
import bg17 from "../../assets/inverstors/background17.png";
import bg18 from "../../assets/inverstors/background19.png";
import tHouse from "../../assets/inverstors/tHouse.png";
import jobanPic from "../../assets/inverstors/image11.png";
import staticWoman from "../../assets/inverstors/staticWoman.png";
import { useTheme } from "@emotion/react";
import CheckIcon from "@mui/icons-material/Check";
import TemporaryDrawer from "./modules/Drawer";
import { invertorsScript } from "./script";

export const AgentsComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [callerScript, setCallerScript] = useState("");
  const [anchor, setAnchor] = useState("");

  const onClick = (prop) => {
    setOpen(true);
    setCallerScript(invertorsScript[prop.id]);
    setAnchor(prop.anchor);
  };

  return (
    <div
      style={{
        width: "99vw",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TemporaryDrawer
        open={open}
        callerScript={callerScript}
        setOpen={setOpen}
        anchor={anchor}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: isMobile ? "center" : "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: -50,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${staticWoman})`,
            backgroundSize: isMobile ? "150% 100%" : "100% 106%",
            backgroundRepeat: "no-repeat",
            opacity: 1,
            zIndex: -10,
          },
        }}
      >
        <video
          muted
          autoPlay
          loop
          style={{
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
          }}
        >
          <source src={woman} type="video/mp4" />
        </video>

        <Box
          height={"100vh"}
          sx={{
            top: "20%",
            position: "absolute",
          }}
        >
          <TextComponent
            btnWidth={"24rem"}
            headerColor={"white"}
            bodyColor={"white"}
            mainScript={scriptArray[0].mainText}
            subScript={scriptArray[0].subText}
          />
        </Box>
      </Box>
      {/* second */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mx: 4 }}>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : null,
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${heroBg})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 108%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"}>
            <div style={{ margin: isMobile ? "" : "7rem" }}>
              <TextComponent
                alignment={"start"}
                mainScript={scriptArray[1].mainText}
                subScript={scriptArray[1].subText}
                onClick={() => onClick({ id: 1, anchor: "" })}
              />
            </div>
          </Box>
        </Box>
        {/* 3rd */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${womanHappy})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                onClick={() => onClick({ id: 2 })}
                anchor="left"
                mainScript={scriptArray[24].mainText}
                subScript={scriptArray[24].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 4th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${moneyBg})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                onClick={() => onClick({ id: 3, anchor: "" })}
                mainScript={scriptArray[2].mainText}
                subScript={scriptArray[2].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 5rd */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "right",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${womanLaptop})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -40,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{ paddingRight: "10%" }}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                onClick={() => onClick({ id: 4 })}
                alignment={"start"}
                mainScript={scriptArray[3].mainText}
                subScript={scriptArray[3].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 6th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${cartBg})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                onClick={() => onClick({ id: 5, anchor: "" })}
                mainScript={scriptArray[4].mainText}
                subScript={scriptArray[4].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 7rd */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "right",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${manAndHouse2})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 105%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{ paddingRight: "10%" }}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"start"}
                onClick={() => onClick({ id: 6 })}
                mainScript={scriptArray[5].mainText}
                subScript={scriptArray[5].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 8rd */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${manAndHouse})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -20,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                onClick={() => onClick({ id: 7, anchor: "" })}
                mainScript={scriptArray[6].mainText}
                subScript={scriptArray[6].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 9rd */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${dealAnalyzer})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                onClick={() => onClick({ id: 8 })}
                mainScript={scriptArray[7].mainText}
                subScript={scriptArray[7].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 10rd */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${callAgents})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                onClick={() => onClick({ id: 9, anchor: "" })}
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                mainScript={scriptArray[8].mainText}
                subScript={
                  <>
                    {scriptArray[8].subText.farmHeader}

                    {scriptArray[8].subText.body.map((txt) => (
                      <Typography mt={2} textAlign={"start"} color={"white"}>
                        <CheckIcon fontSize="10px" /> {txt}
                      </Typography>
                    ))}
                  </>
                }
              />
            </div>
          </Box>
        </Box>
        {/* 11th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${freeFlyers})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                onClick={() => onClick({ id: 10 })}
                mainScript={scriptArray[9].mainText}
                subScript={scriptArray[9].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 12th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${aiHand})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                onClick={() => onClick({ id: 11, anchor: "" })}
                headerColor={"white"}
                bodyColor={"white"}
                alignment={"center"}
                mainScript={scriptArray[10].mainText}
                subScript={scriptArray[10].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 13th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${invertorFlyers})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                onClick={() => onClick({ id: 12 })}
                mainScript={scriptArray[11].mainText}
                subScript={scriptArray[11].subText}
              />
            </div>
          </Box>
        </Box>

        {/* 14th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${idxBg})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                onClick={() => onClick({ id: 13, anchor: "" })}
                mainScript={scriptArray[12].mainText}
                subScript={
                  <>
                    <Typography mb={2}>
                      {scriptArray[12].subText.firstText}
                    </Typography>
                    <Typography>
                      {scriptArray[12].subText.secondText}
                    </Typography>
                  </>
                }
              />
            </div>
          </Box>
        </Box>
        {/* 15th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${teaching})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                alignment={"center"}
                onClick={() => onClick({ id: 14 })}
                mainScript={scriptArray[13].mainText}
                subScript={scriptArray[13].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 16th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${twoMen})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                onClick={() => onClick({ id: 15, anchor: "" })}
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                mainScript={scriptArray[14].mainText}
                subScript={scriptArray[14].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 17th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${tHouse})`,
              backgroundSize: "70% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          >
            <img src={jobanPic} alt="CEO in front of a house" height={"700"} />
          </div>
          <video
            muted
            autoPlay
            loop
            style={{ position: "absolute", left: "60%", zIndex: -2 }}
          >
            <source src={keyVid} type="video/mp4" />
          </video>
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                onClick={() => onClick({ id: 16 })}
                alignment={"center"}
                mainScript={scriptArray[15].mainText}
                subScript={scriptArray[15].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 18th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${joban})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "10%" }}>
              <TextComponent
                onClick={() => onClick({ id: 17, anchor: "" })}
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                mainScript={scriptArray[16].mainText}
                subScript={scriptArray[16].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 19th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",

            justifyContent: isMobile ? "center" : "start",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${manWriting})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -10,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ margin: "7rem" }}>
              <TextComponent
                onClick={() => onClick({ id: 18 })}
                alignment={"start"}
                mainScript={scriptArray[17].mainText}
                subScript={scriptArray[17].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 20th */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${bg14})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ margin: "7rem" }}>
              <TextComponent
                onClick={() => onClick({ id: 19, anchor: "" })}
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                mainScript={scriptArray[18].mainText}
                subScript={scriptArray[18].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 18 */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${bg15})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 120%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ margin: "7rem" }}>
              <TextComponent
                onClick={() => onClick({ id: 20 })}
                alignment={"center"}
                mainScript={scriptArray[19].mainText}
                subScript={scriptArray[19].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 19 */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${socialMedia})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 140%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ margin: "7rem" }}>
              <TextComponent
                onClick={() => onClick({ id: 21, anchor: "" })}
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                mainScript={scriptArray[20].mainText}
                subScript={scriptArray[20].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 18 */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${bg17})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -100,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ margin: "7rem" }}>
              <TextComponent
                alignment={"center"}
                onClick={() => onClick({ id: 22 })}
                mainScript={scriptArray[21].mainText}
                subScript={scriptArray[21].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 18 */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${bg16})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 100%",
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ margin: "7rem" }}>
              <TextComponent
                alignment={"center"}
                headerColor={"white"}
                bodyColor={"white"}
                onClick={() => onClick({ id: 23, anchor: "" })}
                mainScript={scriptArray[22].mainText}
                subScript={scriptArray[22].subText}
              />
            </div>
          </Box>
        </Box>
        {/* 18 */}
        <Box
          sx={{
            display: "flex",
            position: "relative",
            width: "100%",
            justifyContent: isMobile ? "center" : "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${bg18})`,
              backgroundSize: isMobile ? "150% 100%" : "100% 115%",
              backgroundPositionY: isMobile ? "" : -150,
              backgroundRepeat: "no-repeat",
              opacity: 1,
              zIndex: -1,
            },
          }}
        >
          <Box height={"100vh"} sx={{}}>
            <div style={{ marginTop: "16%" }}>
              <TextComponent
                onClick={() => onClick({ id: 24 })}
                alignment={"center"}
                mainScript={scriptArray[23].mainText}
                // subScript={scriptArray[23].subText}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const scriptArray = [
  {
    mainText: (
      <>
        Building Successful <br />
        Real Estate Agents Into Investors
      </>
    ),
    subText: (
      <>
        Only Brokerage Teaching Agents How to Become Real Estate Investors{" "}
        <br />
        Not Just Your Standard Agent
      </>
    ),
  },
  {
    mainText: (
      <>
        Don't be a Standard Agent
        <br />
        Become a Successful Investor
      </>
    ),
    subText: (
      <>Learn to Become a Successful Real Estate Investor with BPO Homes</>
    ),
  },

  {
    mainText: (
      <>
        Keep 100% of your Commission <br />
        BPO Realty Commission Caps Starting at Only 2k
      </>
    ),
    subText: <>(E&0 and Yearly Training Fee Not Included price)</>,
  },
  {
    mainText: (
      <>
        Access to Technology that <br />
        helps you Generate Listings, <br />
        BPO Reports and More..!!
      </>
    ),
    subText: (
      <>
        Access to technology that helps you generate BPO reports <br />
        (Broker Price Opinions).
      </>
    ),
  },
  {
    mainText: (
      <>
        Free Farm Marketing for Realty <br />
        Packages $149.00 and above
      </>
    ),
    subText: (
      <>
        Automated marketing tools to reach out to sellers in your target area
        <br />
        (available with higher-tier packages).
      </>
    ),
  },
  {
    mainText: (
      <>
        Cash Flow 4U Consistent Buyer to <br />
        Back your Real Estate Investment Deals
      </>
    ),
    subText: (
      <>
        Access to a network of buyers interested in purchasing your investment
        properties
      </>
    ),
  },
  {
    mainText: (
      <>
        Learn to Wholesale & <br />
        Subject to Transactions
      </>
    ),
    subText: (
      <>
        Access to technology that helps you generate BPO reports <br />
        (Broker Price Opinions).
      </>
    ),
  },

  {
    mainText: <>Free Deal Analyzer</>,
    subText: (
      <>
        Use a free tool to analyze potential real estate deals and assess their
        profitability.
      </>
    ),
  },
  {
    mainText: <>Farm Marketing</>,
    subText: {
      farmHeader: <>Farm Marketing Includes;</>,
      body: [
        "Yearly Custom Designed MMS Text to Sellers",
        "Yearly Custom Designed Emails to Sellers",
        "Yearly Direct Call Center Calling to Sellers",
      ],
    },
  },
  {
    mainText: <>Free Luxury Investor Websites</>,
    subText: (
      <>
        Get a free website designed to attract high-end real estate investors.
      </>
    ),
  },
  {
    mainText: <>Free Lofty CRM + Lofty AI</>,
    subText: (
      <>
        Free customer relationship management (CRM) software with built-in AI
        <br /> features to manage your leads and business.
      </>
    ),
  },
  {
    mainText: <>Investor List Organizer</>,
    subText: <>Organize and manage your list of potential investors.</>,
  },
  {
    mainText: (
      <>
        Free Fully Customizable <br />
        IDX Agent Website
      </>
    ),
    subText: {
      firstText: (
        <>
          Mater the Wholesaling and (Subject to) strategies and <br />
          earn up to 6Commissions on a Single Transaction
        </>
      ),
      secondText: (
        <>
          Set Up Your Very Own Luxury Customizable IDX Website to Set Up Buyer
          <br />
          Searches
        </>
      ),
    },
  },
  {
    mainText: (
      <>
        MDA Business Program with FREE <br />
        Coaching & Mentorship for Life
      </>
    ),
    subText: (
      <>
        Benefit from ongoing coaching and mentorship from <br />
        experienced professionals throughout your real estate career.
      </>
    ),
  },
  {
    mainText: <>Free Investor Agent Training</>,
    subText: (
      <>
        Access complimentary training programs specifically designed <br />
        for real estate investors.
      </>
    ),
  },
  {
    mainText: (
      <>
        Live Power Coaching with <br />
        CEO Joban Brown
      </>
    ),
    subText: (
      <>
        Receive exclusive coaching sessions with industry expert <br />
        Joban Brown (free of charge).
      </>
    ),
  },
  {
    mainText: (
      <>
        Full Training Center Contracts 
        <br />
        on BPO Tech
      </>
    ),
    subText: (
      <>
        Comprehensive training materials and resources <br />
        to help you master BPO technology.
      </>
    ),
  },
  {
    mainText: (
      <>
        Free CE Courses (In Applicable <br />
        States and Agent Tenure Rules Apply)
      </>
    ),
    subText: (
      <>
        Get access to free continuing education (CE) courses from
        <br />
        (availability depends on your state and agent license requirements).
      </>
    ),
  },
  {
    mainText: (
      <>
        Free Marketing Designs + Access to <br />
        BPO Homes Custom Design Team
      </>
    ),
    subText: (
      <>
        Receive free marketing materials and have the opportunity to work with
        <br />
        BPO Homes' design team.
      </>
    ),
  },
  {
    mainText: <>Free Digital Business Card</>,
    subText: (
      <>Get a digital business card to share your contact information easily.</>
    ),
  },
  {
    mainText: (
      <>
        Free Social Media Advertisement <br /> (Coming Soon 2025)
      </>
    ),
    subText: (
      <>
        Social media advertising services will be available in the future
        (coming soon).
      </>
    ),
  },
  {
    mainText: <>Low Cost Transaction Coordinators</>,
    subText: (
      <>Our Internal Transaction Coordinators ONLY $199 Per Transaction</>
    ),
  },
  {
    mainText: <>Revenue Share (Coming Soon 2025)</>,
    subText: (
      <>
        Revenue sharing program where you can earn additional income based on
        <br /> your performance (coming soon).
      </>
    ),
  },
  {
    mainText: (
      <>
        Company Stock Offerings
        <br />
        (Only Applicable when Rounds are Open)
      </>
    ),
    subText: (
      <>
        Potential opportunity to invest in BPO Homes' company stock <br />
        (subject to availability).
      </>
    ),
  },
  {
    mainText: <>Unlimited Buyer & Seller Leads</>,
    subText: (
      <>Get connected with clients interested in buying or selling estate</>
    ),
  },
];
