import React from 'react'
import SEO from '../../components/SEO'
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography, styled } from '@mui/material'
import BannerImg from '../../assets/04-quality-faucets.jpg'
import Fixtures from '../../assets/02-plumbing-fixtures.jpg'
import Faucets from '../../assets/03-faucets.jpg'
import Plumbing from '../../assets/01-plumbing-real-estate.jpg'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { scrollUp } from '../../constants'


const Banner = styled(Box)({
    height: '60vh',
    backgroundImage: `url(${BannerImg})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover'
})

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const Investors = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    // const lists = [
    //     `Easy to install - no need for plumbers or specialized tools`,
    //     `Eco-friendly construction helps reduce water waste`,
    //     `Durable design provides years of reliable service`,
    //     `Sleek modern look adds value to any home décor`
    // ]

  return (
    <div>
        <SEO>
				<title>3 Must-Know Home Facts For Homebuyers And Investors</title>
				<meta name='og:title' content="3 Must-Know Home Facts For Homebuyers And Investors" />
				<meta
					name='description'
					content=""
				/>
				<meta
					name='og:description'
					content=""
				/>
			</SEO>
            <Banner />
            <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
                <Typography textAlign={'center'} gutterBottom variant='h4'>3 Must-Know Home Facts For Homebuyers And Investors</Typography>
                <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', bgcolor: 'primary.main'}} />
            </Box>

            <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                    <Typography paragraph mb={5}>
                        Homebuyers and investors often feel overwhelmed by the process of buying or investing in a home. With so many details to consider, it can be hard to know where to start!
                    </Typography>
                    <Typography paragraph>
                        That's why we've put together this handy guide of essential facts you need to know before making your next real estate purchase. Learn all about mortgages, taxes, home insurance, and more - everything you need to make an informed decision when it comes to buying or investing in property. Let’s dive in and take a look at 10 must-know facts for anyone interested in purchasing a home!
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Mortgages And Loan Options</Typography>
                    <Typography paragraph>
                        When it comes to buying a home, financing is an important factor. Homebuyers and investors need to be aware of their loan options and the associated terms and rates. Down payments are generally required when taking out a mortgage. This amount can vary depending on different factors such as credit score, type of loan, and personal financial situation.
                    </Typography>
                    <Typography paragraph>
                        It's also important to understand how interest rate will affect total monthly payments over the life of the loan - higher interest rates usually mean higher monthly payments. It’s essential for those looking into purchasing a home or investing in real estate to research all available loan options prior to making any decisions. Knowing what down payment requirements are necessary, as well as understanding current market trends for interest rates, will help you make the best choice for your particular situation. Taking time to review these must-know facts could save buyers thousands in the long run.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Taxes And Other Costs</Typography>
                    <Typography paragraph>
                    When buying a home, it's important to consider the costs beyond just the purchase price of the property. Property taxes and closing costs are two additional expenses you should factor into your budget when investing in real estate.
                    </Typography>
                    <Typography paragraph>
                        Property taxes can vary widely depending on where you live and how much the assessed value of your property is relative to other homes in your area. Generally speaking, local governments assess an annual tax based on the market value of a given piece of real estate. It's important to understand what kind of taxes will apply to any potential home before making an offer or signing a contract so that you know exactly what to expect financially every year while owning a particular property.
                    </Typography>
                    <Typography paragraph>
                        Closing costs refer to all the fees associated with purchasing a house including attorneys' fees, title insurance, appraisal fees, loan origination points and more. As part of their service offering, many lenders provide estimates for these types of charges upfront so you have a good idea as to what they'll be prior to committing to financing for a specific property. Getting preapproved for a mortgage also helps buyers determine their overall budget since it takes into account not only principal and interest but also estimated closing costs from specified providers.
                    </Typography>
                    <Typography paragraph>
                        It pays off for prospective homeowners and investors alike to become informed about both property taxes and closing costs related to any real estate transaction; doing so will help ensure that no unexpected surprises arise during – or after – the process of securing ownership rights over any future residence.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Home Insurance And Maintenance</Typography>
                    <Typography paragraph>
                        Taxes and other costs are only the beginning of what homebuyers, sellers, and investors need to know before they take the next step in their real estate journey. Home insurance is another critical factor when considering a purchase or investment. In addition, ongoing maintenance must be factored into any budget projections as well.
                    </Typography>
                    <Typography paragraph>
                        The cost of homeowner’s insurance can vary greatly depending on location, but there are some key aspects that buyers should consider no matter where they live. These include coverage for damages from natural disasters like floods or earthquakes, liability protection against accidents occurring on your property, and additional living expenses if you ever needed to evacuate your home due to an emergency situation.
                    </Typography>
                    <Typography paragraph>
                        It's also important to get advice from local experts who understand the specific risks associated with buying a particular property. When it comes to maintaining a home, regular inspections are essential - both inside and outside. Look out for signs of pests such as termites or larger animals like squirrels getting into attics. Check gutters and drainage systems, too; clogs can cause water damage to roofs over time. Make sure all electrical outlets and appliances have been updated according to current codes and standards. Lastly, always consult qualified professionals about renovation costs prior to committing to major projects around the house. A quick call could save thousands down the road!
                    </Typography>
                    {/* <List>
                        {
                            lists?.map( (el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon sx={{minWidth: '35px'}}><Circle sx={{fontSize: '.7rem'}} color='secondary' /></ListItemIcon>
                                        <ListItemText>{el}</ListItemText>
                                     </ListItem>
                                )
                            })
                        }
                    </List> */}

                    <Typography paragraph>
                        No matter how big or small your dream home may be, taking these steps now will help ensure that everything runs smoothly in the future – so don't forget your inspection tips!
                    </Typography>
                    
                    <Typography className={classes.title} variant='h6'>Conclusion</Typography>
                    <Typography paragraph>
                        As a homebuyer or investor, it's important to be well-informed about the costs and risks associated with buying a home. It pays off to do your research ahead of time so you can go into the process feeling confident.
                    </Typography>
                    <Typography paragraph>
                        With this knowledge in hand, you'll be able to make an informed decision — one that will help ensure you get the most out of your investment over the long term. Now that you know these 10 must-know facts for home buyers and investors, you're ready to take on any real estate transaction with confidence. Good luck!
                    </Typography>
                   
                </Box>
            </Container>
        </Box>

        {/* READ ALSO */}
        <Box mt={4} mb={5}>
                <Container>
                    
                    <Typography variant='h6' mt={2} mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/plumbing-real-estate')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Plumbing})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>The Importance Of Plumbing In Real Estate: A Guide For Homebuyers</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Plumbing plays an essential role in the real estate world...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                         <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/plumbing-fixtures')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Fixtures})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>The Latest Plumbing Fixtures To Upgrade Your Home</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Are you looking for ways to upgrade your home? Plumbing is... </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/faucets')
                                scrollUp()
                              }}>
                                <CardHeader style={{backgroundImage: `url(${Faucets})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>A Guide To Choosing The Right Faucet For Your Home</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Choosing the right faucet for your home can be a daunting task...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

            </Container>
                </Box>

    </div>
  )
}

export default Investors