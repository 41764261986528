import { Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import BG from '../../assets/buyerfirst.png'
import Check1 from '../../assets/buycheck.png'
import Check2 from '../../assets/checklist1.png'
import Check3 from '../../assets/checklist2.png'
import { useNavigate } from 'react-router-dom'
import { scrollUp } from '../../constants'


const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${BG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
    listIcon: {
        fontSize: '.8rem',
        minWidth: '32px',
        color: '#000'
    }
}))
const BuyerFirstTime = () => {
    const classes = useStyles()
    const navigate = useNavigate()


  return (
    <div>
        <Box className={classes.banner}></Box>
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem'}}>
            <Typography textAlign={'center'} sx={{color: '#fff'}} gutterBottom variant='h5'>Buying a Home in 2023?</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '.5rem auto', background: (theme) => theme.primaryColor}} />
        </Box>
        <>
            <Container>
                <Box padding='4rem 0'>
                    <Typography mb={6}>
                    Undoubtedly, purchasing a property is a difficult endeavor, but we came across a few claims that unnecessarily prevented prospective buyers from realizing their goals. A little bit of background knowledge can assist avoid costly blunders when it comes to seeking counsel, funding, and the inspection procedure.
                    We know how challenging the entire process can be. You can navigate your first experience and make it as painless as you can by following the advice provided here. As rising mortgage interest rates compound costs, it is crucial to be aware of the hazards when buying a property. Here is the advice I've worked so hard to gain for 2022 home buyers.
                        </Typography>

                    {/* Speak */}
                    <Box mt={4} mb={3}>
                        <Typography variant='h6' className={classes.title}>1. Speak with agents and lenders before making a decision.</Typography>
                        <Typography paragraph>
                            Your choice of mortgage lender and real estate agent will have a huge impact on how enjoyable your home-buying process will be. You depend on them to finalize your transaction, and the representation you select can have a big impact on both your budget and your level of worry.
                        </Typography>
                        <Typography paragraph>
                            Before you sign anything, your real estate agent should demonstrate their level of experience. You can find the finest of agents at BPO Homes Premier agent website. The agent’s profile and full listings can be seen on the website, to give you the best options available.  You should outline your needs and goals during the initial meeting. To help the agent determine your preferences, you will be guided through the home-buying process and shown a few suitable residences. Keep in mind that unless you sign a contract, you are not required to work with them. Even if your contact with your lender may not be as intimate, you should still speak with them and thoroughly investigate them before committing to a formal working partnership.
                            To evaluate their qualifications and determine your compatibility, speak with various mortgage lenders and real estate brokers. Your realtor should provide clear explanations, be knowledgeable about the area you want to live in, and reply to your inquiries promptly and courteously. You must, above all, have faith in them. It may be challenging, time-consuming, and expensive to transfer your agent or lender once you're under contract, which means you've signed the paperwork to buy a property. 
                        </Typography>
                        {/* <List>
                             <ListItem>
                                <ListItemIcon><Circle fontSize='small' className={classes.listIcon} /></ListItemIcon>
                                <ListItemText>
                                    Your debt-to-income ratio (DTI), or simply your monthly spending divided by your monthly income, is the first thing you must ascertain. This is your total monthly debt divided by your gross monthly income.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Circle fontSize='small' className={classes.listIcon} /></ListItemIcon>
                                <ListItemText>
                                    Include any recurrent debts, such as student loans, auto payments, child support payments, or anything else you make a monthly payment for when you are thinking about your debts.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Circle fontSize='small' className={classes.listIcon} /></ListItemIcon>
                                <ListItemText>
                                    Your DTI should be no greater than 43% to qualify for a mortgage with the majority of lenders. But keep in mind that's the "outside" amount you can spend; it doesn't follow that you should.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Circle fontSize='small' className={classes.listIcon} /></ListItemIcon>
                                <ListItemText>
                                    Do you know what it means to be "housing poor"? That means you overspent on your house to the point where you have no money left over for extras like dining out or travel, let alone the numerous costs that will be incurred in addition to your mortgage. Budget not only for the "fun" things that come with homeownership, like routine maintenance and even unforeseen big-ticket items like a new water heater, but also for the "unfun" things, like furnishings, new paint, and plants for your new patio pots.
                                </ListItemText>
                            </ListItem>
                            
                        </List> */}
                    </Box>
                </Box>

            </Container>

            {/* Divider Section */}
            <Box style={{height: '15rem', backgroundImage: `linear-gradient(#1378A595, #1378a5cf), url(${BG})`}}>
                <Container sx={{display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center', width: '50%'}}>
                    <Typography variant='h5' sx={{color: '#fff'}}>Find an agent near you.</Typography>
                    <Button size='large' variant='contained' disableElevation color='paper' href='/agents'>Find Agent</Button>
                </Container>
            </Box>

            <Container>
                <Box>
                    {/* Be Adaptable */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>2. Be adaptable in your house hunting.</Typography>
                        <Typography paragraph>
                            It will be simpler to seek with an open mind once you have an excellent agent in place than it will be to later modify your expectations. I've seen first-time homeowners put themselves in a difficult situation by becoming fixated on their ideal house. It's improbable that the great majority of first-time homebuyers will receive their first choice. Your chances of succeeding are higher the more adaptable you are. 
                        </Typography>
                        <Typography paragraph>
                            Before beginning your search, it could be useful to list the qualities that are most important to you; this will enable you to better evaluate your selections once you start seeing potential homes. If one market is too expensive for you, you might need to think about moving. Here's where you may be proactive and gain an advantage over other purchasers by looking into a new building on the borders of a burgeoning market or finding out about forthcoming neighborhood infrastructure projects along transit routes. 
                            You can learn where possible commercial growth is taking place by looking at open and closed permits for residential construction. You might be able to negotiate a lower price for a home if you're prepared to put off your ambitions for a few years. This strategy can also increase your equity over a brief period. You can usually get this information on the websites of your county or city. You should also be able to get assistance from your agent in finding this data.
                        </Typography>
                    </Box>

                    {/* vital */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>3. It's vital to comprehend your real estate deal.</Typography>
                        <Typography paragraph>
                            Although long and dry, real estate contracts are not legally binding until you sign. When submitting an offer, any parameter is negotiable. As for anything that doesn't make sense, ask why. Remember that you can ask for changes before submitting the contract to the seller if any element of it does not meet your demands. 
                        </Typography>
                        <Typography paragraph>
                             When in doubt, or to get a reality check, you should take your professional, seasoned agent's counsel. Understand the "contingency elections." These dictate the kinds of agreements and inspections that can take place and when, such as conventional home inspections, pest inspections, whether you can get a mortgage, and more.
                        </Typography>
                    </Box>

                    {/* vital */}
                    <Box mt={5} mb={3}>
                        <Typography variant='h6' className={classes.title}>4. A home inspection won't give all the information.</Typography>
                        <Typography paragraph>
                            The homebuyer typically gets 10 to 14 days to arrange for a house inspection once the contract has been signed by all parties. According to the inspection's findings, your real estate agent (or lawyer, depending on the state) may ask for financial accommodations or urge that repairs be included in the contract to allay any worries or prospective issues. And also, this is where a BPO report becomes very important. A BPO report from BPO homes will give you the actual price of the property you want to buy. 
                            A comprehensive inspection report may be too much to handle, especially if the house is older. No home is perfect, but the majority of issues can be fixed. However, as long as you are still in the contingency period, you always have the option of negotiating or walking away if you run into something you don't want to deal with.
                        </Typography>
                        <Typography paragraph>
                            Inspectors, however, are not infallible. They won't be able to move the present occupant's possessions to conduct an inspection or access behind walls. Additionally, they won't be liable for any errors or omissions in their assessment.
                            You could feel under pressure to skip an inspection if you're looking in a competitive, hot market where properties are selling quickly. If you're thinking of doing that, get assistance: invite a building or system expert to the presentation. It might enlighten you more about the condition of the house and point out significant warning signs.
                        </Typography>
                    </Box>
                    
                    <Box mt={4} mb={5}>
                        <Typography variant='h6' mb={3}>Read Also:</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} lg={4}>
                                <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => { 
                                    navigate('/blog/buyer-checklist')
                                    scrollUp()
                                }}>
                                    <CardHeader style={{backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                    <CardContent style={{padding: '2rem'}}>
                                        <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                        <Typography variant='body1' color={'textSecondary'}>Your Best Checklist for Buying A Home</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => { 
                                    navigate('/blog/bpo-guide')
                                    scrollUp()
                                }}>
                                    <CardHeader style={{backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                    <CardContent style={{padding: '2rem'}}>
                                        <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                        <Typography variant='body1' color={'textSecondary'}>Why Buyer Needs A Broker's price Opinion?</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={4}>
                                <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                    navigate('/blog/buyer-score')
                                    scrollUp()
                                }}>
                                    <CardHeader style={{backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                    <CardContent style={{padding: '2rem'}}>
                                        <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                        <Typography variant='body1' color={'textSecondary'}>How to Raise Your Credit Score to Get Your First Home Mortgage</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>

    </div>
  )
}

export default BuyerFirstTime