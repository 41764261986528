import React, { useState, useReducer } from 'react';
import BannerImg from '../assets/sellBg1.jpg'
import Logo from '../assets/bpoVerticalLogo.png';
import Unsecure from '../assets/unsecure-profile.png';
import HomeLogo from '../assets/homelogo.svg';
import Meeting from '../assets/schedule.png'
import ScheduleBG from '../assets/scheduleBG.png'
import SectionBG from '../assets/sellBg3.jpg'
// import OwlCarousel from 'react-owl-carousel'
import Ad4 from '../assets/adsec4.png';
import { Box, Grid, TextField, Typography, Button, styled, Container, Divider, Dialog, DialogContent, List, ListItem, ListItemIcon, ListItemText, DialogActions, FormLabel, IconButton } from '@mui/material';
import { ArrowBack, CheckCircle, Circle, Close, ErrorOutline, Info } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { alpha, Stack } from '@mui/system';
import { quesLists, timeData } from '../constants';
import { getDatesFromToday } from '../utils/functions';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import PlacesSearch from '../components/PlacesSearch'
import SEO from '../components/SEO';


const StyledBtn = styled(Button)(({theme})=> ({
	textTransform: 'none',
	height: '100%'
}))

const StyledLabel = styled(FormLabel)(({theme})=> ({
	fontSize: '1rem',
	marginBottom: '.3rem',
	display: 'block'
}))

const WrapBox = styled(Box)(({theme, quest})=> ({
	background: alpha('#fff', 0.95), 
	padding: quest === 6 ? '3rem' : '4rem 3rem', 
	borderRadius: '10px'
}))

const Banner = styled(Box)(({theme})=> ({
	backgroundImage: `linear-gradient(180deg, rgba(0,0,0,50%), rgba(0,0,0,50%)),  url(${BannerImg})`,
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	height: '60vh',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center', color: '#fff'
}))



function Sell() {
	// const navigate = useNavigate();
	const [value, setValue] = useState('')
	const [open, setOpen] = useState(false)
	const [info, setInfo] = useState(false)
	const [disable, setDisable] = useState(true)
	const [page, setPage] = useState()
	const [question, setQuestion] = useState(0)
	const [selected, setSelected] = useState()
	const [loading, setLoading] = useState(false)
	const [response, setResponse] = useState({open: false, status: null})

	const dates = getDatesFromToday()

	const formState = { 
		meetingAddress: '',
		meetingType: '',
		meetingDate: '',
		meetingTime: '',
		sellingPlan: '',
		currentCondition: '',
		whySell: '',
		haveAnAgent: '',
		wantToBuyAHome: '',
		homeImprovement: '',
		firstname: '',
		lastname: '',
		phone: '',
		email: '',
		otherInfo: ''
	}

	const formReducer = (state, action) => {
		switch (action.type) {
			case 'ADDRESS':
				return {...state, meetingAddress: action.payload}
			case 'TYPE': 
				return {...state, meetingType: action.payload}
			case 'DATE':
				return {...state, meetingDate: action.payload}
			case 'TIME':
				return {...state, meetingTime: action.payload}
			case 'SELLING_PLAN':
				return {...state, sellingPlan: action.payload}
			case 'CURRENT_CONDITION':
				return {...state, currentCondition: action.payload}
			case 'WHY_SELL':
				return {...state, whySell: action.payload}
			case 'HAVE_AN_AGENT':
				return {...state, haveAnAgent: action.payload}
			case 'BUY_HOME':
				return {...state, wantToBuyAHome: action.payload}
			case 'FIRSTNAME':
				return {...state, firstname: action.payload}
			case 'LASTNAME':
				return {...state, lastname: action.payload}
			case 'PHONE':
				return {...state, phone: action.payload}
			case 'EMAIL':
				return {...state, email: action.payload}
			case 'OTHER_INFO':
				return {...state, otherInfo: action.payload}
			case 'HOME_IMPROVEMENT':
				return {...state, homeImprovement: action.payload}
			case 'RESET':
				return formState
			default:
				return state;
		}
	}

	const [formInput, dispatch] = useReducer(formReducer, formState)
	
	const startSchedule = () => {
		//check input
		if(value === ''){
			return
		}
		// open Dialog & set first page
		dispatch({type: 'ADDRESS', payload: value})
		setOpen(true)
		setPage(0)
		//
	}

	const onFormSubmit = async() => {
		if(page === 0){
			if(formInput.meetingType === ''){
				return
			}
			setPage(1)
		}
		if(page === 1){
			if(formInput.meetingDate === '' || formInput.meetingTime === ''){
				return
			}
			setPage(2)
			setQuestion(0)
		}
		if(page === 2){
			//console.log(formInput)
			setLoading(true)
			const data = {
				property_address : formInput.meetingAddress,
				schedule_type : formInput.meetingType,
				meeting_date : new Date(formInput.meetingDate).toISOString(),
				meeting_time : formInput.meetingTime,
				sale_schedule : formInput.sellingPlan,
				property_state : formInput.currentCondition,
				reason_for_selling : formInput.whySell,
				working_with_an_agent : formInput.haveAnAgent,
				look_forward_to_buying : formInput.wantToBuyAHome,
				home_improvements : formInput.homeImprovement,
				first_name : formInput.firstname,
				last_name : formInput.lastname,
				email : formInput.email,
				phone : formInput.phone,
				other_information : formInput.otherInfo,
				lead_type: 'sell'
			}
			try {
				const res = await axios.post('https://dev-api.bpohomes.com/leads/report/', data)
				if(res.data){
					//console.log(res.data)
					setResponse({open: true, status: 'success'})
				}
			} catch (error) {
				//console.log(error)
				setLoading(false)
				setResponse({open: true, status: 'error'})
			}
		}
	}

	const selectMeeting = (val) => {
		setSelected(val)
		dispatch({type: 'TYPE', payload: val})
		setDisable(false)
	}

	const stepBack = () => {
		if(page === 1){
			setPage(0)
		}if(page === 2){
			setPage(1)
		}
	}

	const nextQuestion = () => {
		if(question === 0){
			if(formInput.sellingPlan === ''){
				return
			}
			setQuestion(1)
		}if(question === 1){
			if(formInput.currentCondition === ''){
				return
			}
			setQuestion(2)
		}if(question === 2){
			if(formInput.whySell === ''){
				return
			}
			setQuestion(3)
		}if(question === 3){
			if(formInput.haveAnAgent === ''){
				return
			}
			setQuestion(4)
		}if(question === 4){
			if(formInput.wantToBuyAHome === ''){
				return
			}
			setQuestion(5)
		}if(question === 5){
			setQuestion(6)
		}
	}

	const prevQuestion = () => {
		if(question === 0){
			setPage(1)
		}if(question === 1){
			setQuestion(0)
		}if(question === 2){
			setQuestion(1)
		}if(question === 3){
			setQuestion(2)
		}if(question === 4){
			setQuestion(3)
		}if(question === 5){
			setQuestion(4)
		}if(question === 6){
			setQuestion(5)
		}
	}

	const optionSelect = (option) => {
		if(question === 0){
			dispatch({type: 'SELLING_PLAN', payload: option})
		}if(question === 1){
			dispatch({type: 'CURRENT_CONDITION', payload: option})
		}if(question === 2){
			dispatch({type: 'WHY_SELL', payload: option})
		}if(question === 3){
			dispatch({type: 'HAVE_AN_AGENT', payload: option})
		}if(question === 4){
			dispatch({type: 'BUY_HOME', payload: option})
		}
	}

	const onDialogClose = () => {
		setOpen(false)
		dispatch({type: 'RESET'})
		setSelected()
		setLoading(false)
		setResponse({open: false, status: null})
	}
	
	return (
		<>

			<SEO>
				<title>Sell Your Home with a Reputable Local Agent | BPO Homes</title>
				<meta name='og:title' content='Sell Your Home with a Reputable Local Agent | BPO Homes' />
				<meta
					name='description'
					content='Get an unbiased report and avoid underlisting your property. BPO Homes offers top online marketing services to ensure a smooth and successful selling experience with a reputable local agent. Find an agent near you today.'
				/>
				<meta
					name='og:description'
					content='Get an unbiased report and avoid underlisting your property. BPO Homes offers top online marketing services to ensure a smooth and successful selling experience with a reputable local agent. Find an agent near you today.'
				/>
			</SEO>
			
			<Banner>
				<Typography variant='h4' textAlign={'center'} sx={{fontWeight: 700, fontSize: '2.8rem'}}>Dont underlist your Property</Typography>
				{/* <Typography variant='h6' textAlign={'center'} >Request A BPO Today</Typography> */}
			</Banner>

			<Box py={'4rem'} sx={{background: `linear-gradient(0deg, rgba(19, 120, 165, 0.85), rgba(19, 120, 165, 0.85)),url(${Ad4})`,
					backgroundSize: 'cover',
					color: 'white',
					width: '100%',
					textAlign:'center'
				}}>
				<Container>
					<Typography textAlign={'center'} variant='h6' textTransform={'uppercase'} sx={{fontWeight: 600}} >Get an unbiased report</Typography>
					<Divider sx={{height: '5px', width: '20%', border: 'none', bgcolor: 'primary.main', m: '1rem auto 2rem auto'}} />
					<Typography paragraph textAlign={'center'}>
					In today’s market, selling is very competitive.  We keep our Sellers at the front of the pack.  At BPO Homes, we deliver Top online marketing services in which our agents utilize to get our Listings sold as quickly as possible.  From Facebook Advertisements, MLS resources, Staging, Broker tours & open houses, our Real Estate Agents are trained to deliver the ultimate selling experience to ensure the process is as smooth as possible.
					</Typography>
					<Typography paragraph textAlign={'center'}>
					Some unethical Agents will sign a Listing Agreement with a Seller with the idea that they can get you to come down on the price later
						after you have listed your home. Although the Agent really knows as a local professional that your home is actually overpriced,
						essentially not worth anywhere close to the price you want to list your home for. Many Agents are taught to just get the listing no matter
						what and get the Seller to come down later.
					</Typography>
					
					<Typography textAlign={'center'} paragraph>To resolve this it's very simple, click here & get a Bpo Report today!</Typography>
					<Button variant='contained' size='large' href='https://bpohomestech.com/order-bpo-report' disableElevation sx={{margin: '2rem auto', color: 'secondary.main', display: 'inline-block'}} color='paper' >GET A BPO REPORT TODAY</Button>
				</Container>
			</Box>
			<Box py={'6rem'}>
				<Container>
						<Grid container spacing={4} mb={'3rem'}>
							<Grid item xs={12} sm={12} md={7} lg={7} sx={{padding: '2rem', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'flex-start'}} order={{xs: 2, sm: 1, md: 1, lg: 1}}>
								<Typography variant='h5' mb={4} sx={{fontWeight: 600}}>Sell your home with a reputable local agent</Typography>
								<Typography mb={'3rem'}>
									When we link you with a collaborating BPO Homes Agent partner, selling your home is simple. Proceed by entering your zip code to see if
									they are available in your area. They'll be in more locations soon!
								</Typography>
								<Box sx={{width: {xs: '100%', sm: '100%', md: '100%', lg: '100%'} }}>
									<Grid container spacing={3} >
										<Grid item xs={9} sm={9} >
										<PlacesSearch
												mui={true} fullWidth
												value={value} 
												onChange={setValue}
												sx={{}}
												placeholder='Enter an address, city, state or zipcode'
												
											/>
											{/* <TextField variant='outlined' value={formInput.meetingAddress} 
											onChange={(e)=> dispatch({type: 'ADDRESS', payload: e.target.value})} fullWidth 
											placeholder='Enter an address, city, state or zipcode' /> */}
										</Grid>
										<Grid item xs={3} sm={3}>
											<StyledBtn disableElevation color='secondary' fullWidth variant='contained' onClick={startSchedule}>Next</StyledBtn>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={5} lg={5} sx={{textAlign: {xs: 'center', sm:'center', lg: 'right'} }}order={{xs: 1, sm: 2, md: 2, lg: 2}}>
								<img src={HomeLogo} width='75%' alt='home-logo' />
							</Grid>
						</Grid>

						<Grid container spacing={4}>
							<Grid item  xs={12} sm={12} md={5} lg={5} sx={{textAlign: {xs: 'center', sm:'center', lg: 'left'} }}>
								<img src={Unsecure} width='70%' alt='secure' />
							</Grid>
							<Grid item lg={7} sx={{padding: '2rem', display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'flex-start'}}>
								<Typography variant='h5' mb={4} sx={{fontWeight: 600}}>Risks of selling without a real estate agent</Typography>
								<Typography mb={'3rem'}>
									Stress, loss of time, loss of money: owners who embark on this adventure alone should be ready for major setbacks.
								</Typography>
								<Button size='large' disableElevation color='secondary' href='/agents' variant='contained' sx={{textTransform: 'none', height: '3.5rem'}}>Find An Agent</Button>
								
							</Grid>
							
						</Grid>


				</Container>
			</Box>

			<Box mb={'-6rem'} sx={{ padding: '3rem 0',
			backgroundImage: {xs: `linear-gradient(90deg, rgba(19, 120, 165, 0.8) 100%, transparent 0% ), url(${SectionBG})`, lg: `linear-gradient(90deg, rgba(19, 120, 165, 0.8) 60%, transparent 40% ), url(${SectionBG})`}, 
			backgroundSize: 'cover', backgroundPosition: 'center'}}>
			<Container>
				<Grid container>
					<Grid item xs={12} sm={12} lg={7} sx={{padding: '4rem', color: '#fff'}}>
						<Typography variant='h5' mb={'2rem'}>FIND AN OFFICE NEAR YOU!</Typography>
						<Typography mb={'3rem'}>Currently we have offices in Northern California and Southern California Coming Soon!</Typography>

						<Button size='large' disableElevation color='paper' href='/join' variant='outlined' sx={{textTransform: 'none', height: '3.5rem', width: '15rem'}}>Locate an Office</Button>
					</Grid>
				</Grid>
			</Container>
			</Box>

			{/* Dialog */}
			<Dialog open={open} fullScreen >
				<Container maxWidth='xl'>
					<Box padding={'1.5rem 0'} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative'}}>
						<IconButton sx={{position: 'absolute', top: '28%', right: '0%'}} onClick={onDialogClose}><Close fontSize='small' /></IconButton>
						<img src={Logo} width='15%' alt='logo' />
						<Typography variant='body2' color={'textSecondary'} sx={{marginRight: '5rem'}}>Need Help? <span style={{color: '#000'}}>Call 1(888) 616-5270</span></Typography>
					</Box>
				</Container> 
				<Divider sx={{bgcolor: grey[200]}} />
				<DialogContent sx={{ 
					display: 'flex', flexDirection: 'column', 
					justifyContent: 'center', alignItems: 'flex-start', 
					padding: '4rem 0', backgroundImage: page === 2 && `url(${ScheduleBG})`, backgroundSize: 'cover' }}>
					<Container maxWidth='xl'>
					{
						page === 0 && 
						<Grid container spacing={3}>
							<Grid item lg={6} sx={{padding: '3rem', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
								{/* 1551 San Joaquin Ave, San Jose, CA, 95118 */}
								<Typography variant='body1' color='textSecondary'>{formInput.meetingAddress}</Typography>
								<Typography variant='h5' sx={{fontSize: '1.8rem', paddingRight: '8rem'}} mb={'3rem'} mt={'2rem'}>BPO Homes Agent are readily available to help you sell your home.</Typography>
								
								<List disablePadding>
									<ListItem disablePadding>
										<ListItemIcon sx={{minWidth: '30px'}}><Circle sx={{fontSize: '.6rem'}} color='secondary' /></ListItemIcon>
										<ListItemText>Meet your BPO Homes Agent</ListItemText>
									</ListItem>
									<ListItem disablePadding>
										<ListItemIcon sx={{minWidth: '30px'}}><Circle sx={{fontSize: '.6rem'}} color='secondary' /></ListItemIcon>
										<ListItemText>Get the BPO report to know your real home value</ListItemText>
									</ListItem>
									<ListItem disablePadding>
										<ListItemIcon sx={{minWidth: '30px'}}><Circle sx={{fontSize: '.6rem'}} color='secondary'  /></ListItemIcon>
										<ListItemText>Exploring selling strategies </ListItemText>
									</ListItem>
								</List>

								<Box sx={{display: 'flex', gap: '1rem', marginBottom: '2rem', marginTop: '3rem'}}>
									<Button variant={selected === 'home' ? 'contained' : 'outlined'} disableElevation color='secondary' 
										sx={{textTransform: 'none', height:'3.5rem', width: '15rem'}} onClick={()=> selectMeeting('home')}>Meet at Home</Button>
									<Button variant={selected === 'video' ? 'contained' : 'outlined'} disableElevation color='secondary' 
									sx={{textTransform: 'none', height:'3.5rem', width: '15rem'}} onClick={()=> selectMeeting('video')}>Meet via Video Call</Button>
								</Box>

								<Typography onClick={()=> setInfo(true)} color='primary' mb={'2rem'} variant='body2' sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer'}}><Info fontSize='small' color='primary' sx={{marginRight: '.6rem'}} /> Staying safe during <b style={{marginLeft: '5px'}}> COVID-19</b></Typography>
								
								<Button variant='contained' disableElevation color='secondary' 
									sx={{textTransform: 'none', height:'3.5rem', width: '70%'}} 
									onClick={onFormSubmit} disabled={disable} >Schedule a Meeting</Button>
								
								<Typography mt={'3rem'} variant='body2' color={'textSecondary'}>Need Help? <span style={{color: '#000'}}>Call 1(888) 616-5270</span></Typography>
							</Grid>
							<Grid item lg={6} >
								<Box sx={{borderRadius:'20px', height: '100%', backgroundImage: `url(${Meeting})`, backgroundSize: 'cover', backgroundPosition: 'top-center'}}/>
							</Grid>
						</Grid>
											
					}
					{
						page === 1 &&
						<Box>
							<Typography textAlign={'center'} mb={2} variant='h5'>When would you like to meet with a BPO Homes Agent?</Typography>
							<Typography mb={'3rem'} textAlign={'center'} color={'textSecondary'}>{formInput.meetingAddress}</Typography>

							<Box sx={{width: '70%', margin: '0 auto 2rem auto'}}>
								<Typography mb={2}>Pick a date</Typography>
								
								<Box sx={{width: '100%', overflowX: 'scroll'}}>
									<Box sx={{display: 'flex', gap: '1rem'}}>
										{
											dates.map((date, index) => {
												const newDate = date.display.split(' ')
												return (
													<Box onClick={()=> dispatch({type: 'DATE', payload: date.display})} key={index} 
													sx={{
														flex: 1,
														textAlign: 'center',
														borderRadius: '10px',
														cursor: 'pointer',
														bgcolor: formInput.meetingDate === date.display ? 'secondary.main' : null,
														padding: '1rem 3.5rem', 
														border: '1px solid lightgrey'}}>
														<Typography variant='body2' sx={{color: formInput.meetingDate === date.display && '#fff'}}>{newDate[0]}</Typography>
														<Typography sx={{fontSize: '2.4rem', color: formInput.meetingDate === date.display ? '#fff' : grey[400]}}>{newDate[2]}</Typography>
														<Typography variant='body2' sx={{color: formInput.meetingDate === date.display && '#fff'}}>{newDate[1].toUpperCase()}</Typography>
													</Box>
												)
											})
										}
									</Box>
								</Box>
							</Box>

							<Box sx={{width: '70%', margin: '0 auto'}}>
								<Typography mb={2}>Pick a time</Typography>
							
								<Box sx={{width: '100%', overflowX: 'scroll'}}>
									<Box sx={{display: 'flex', gap: '1rem'}}>
										{
											timeData.map((time, index) => {
												return (
													<Box onClick={()=> dispatch({type: 'TIME', payload: time})} key={index} 
													sx={{
														textAlign: 'center',
														borderRadius: '10px',
														cursor: 'pointer',
														bgcolor: formInput.meetingTime === time ? 'secondary.main' : null,
														padding: '1rem 3.5rem', 
														border: '1px solid lightgrey'}}>
														<Typography sx={{color: formInput.meetingTime === time && '#fff'}}>{time}</Typography>
													</Box>
												)
											})
										}
									</Box>
								</Box>
							</Box>

							<Box sx={{display: 'flex', gap: '1rem', justifyContent: 'center', marginBottom: '2rem', marginTop: '3rem'}}>
								<Button variant='outlined' disableElevation color='secondary' 
									sx={{textTransform: 'none', height:'3.5rem', width: '15rem'}} onClick={stepBack}>Back</Button>
								<Button variant='contained' disableElevation color='secondary' 
								sx={{textTransform: 'none', height:'3.5rem', width: '15rem'}} onClick={onFormSubmit}>Next</Button>
							</Box>
						</Box>
					} 
					{
						page === 2 &&
						<WrapBox quest={question}>
							<ArrowBack color='secondary' onClick={prevQuestion} sx={{cursor: 'pointer'}} />
							{
								quesLists[question].map((el, index) => {
									return (
										<Box key={index}>
											<Typography textAlign={'center'} mb={1} variant='h5'>{el.title}</Typography>
											<Typography mb={'3rem'} textAlign={'center'} color={'textSecondary'}>{el.subTitle}</Typography>
											
											<Box sx={{width: '50%', margin: '0 auto'}}>
												<Grid container spacing={3}>
													{
														el.options === 'improvementForm' ? 
														<TextField variant='outlined' onChange={(e)=>dispatch({type:'HOME_IMPROVEMENT', payload: e.target.value})} 
														value={formInput.homeImprovement} fullWidth multiline rows={5} 
														placeholder='Enter any improvements you’ve made (optional)' /> 
														:
														el.options === 'personalDetailsForm' ?
															<>
																<Grid item lg={6}>
																	<StyledLabel >First Name</StyledLabel>
																	<TextField variant='outlined' value={formInput.firstname} onChange={(e)=>dispatch({type: 'FIRSTNAME', payload: e.target.value})} fullWidth placeholder='Enter firstname'  /> 
																</Grid>
																<Grid item lg={6}>
																	<StyledLabel>Last Name</StyledLabel>
																	<TextField variant='outlined' value={formInput.lastname} 
																	onChange={(e)=>dispatch({type: 'LASTNAME', payload: e.target.value})} 
																	fullWidth placeholder='Enter lastname'  /> 
																</Grid>
																<Grid item lg={6}>
																	<StyledLabel>Email Address</StyledLabel>
																	<TextField variant='outlined' value={formInput.email} 
																	onChange={(e)=>dispatch({type: 'EMAIL', payload: e.target.value})}
																	fullWidth placeholder='Enter Email address'  /> 
																</Grid>
																<Grid item lg={6}>
																	<StyledLabel>Phone Number</StyledLabel>
																	<TextField variant='outlined' value={formInput.phone} 
																	onChange={(e)=>dispatch({type: 'PHONE', payload: e.target.value})}
																	fullWidth placeholder='Enter Phone number'  /> 
																</Grid>
																<Grid item lg={12}>
																	<StyledLabel>Other Information</StyledLabel>
																	<TextField variant='outlined' value={formInput.otherInfo} 
																	onChange={(e)=>dispatch({type: 'OTHER_INFO', payload: e.target.value})}
																	multiline rows={5} fullWidth placeholder='Any information you might want us to know'  /> 
																</Grid>

															</> 
															:
															el.options.map((option, index) => {
																return (
																	<Grid key={index} item lg={6}>
																		<Button variant={formInput[el.formID] === option ? 'contained' : 'outlined'} disableElevation color='secondary' onClick={() => optionSelect(option)} 
																		sx={{textTransform: 'none', height:'3.5rem'}} fullWidth>{option}</Button>
																	</Grid>
																)
															})
													}
												</Grid>
												{
													question === 6 ? 
													<LoadingButton loading={loading} variant='contained'  disableElevation color='secondary' 
													sx={{textTransform: 'none', height:'3.5rem',display: 'block', width: '15rem', margin: '3rem auto 0 auto'}} 
													onClick={onFormSubmit} >Submit</LoadingButton>
													:
													<Button variant='contained' disableElevation color='secondary' 
													sx={{textTransform: 'none', height:'3.5rem',display: 'block', width: '15rem', margin: '3rem auto 0 auto'}} 
													onClick={nextQuestion}>Next</Button>

												}
											</Box>
										</Box>
									)
								})
								
							}

						</WrapBox>	
					}
					</Container>
				</DialogContent>
				{
					page === 0 ? null :
					<DialogActions sx={{bgcolor: grey[100]}}>
						<Container maxWidth='xl'>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '1.5rem 0'}}>
								<Stack>
									<Typography sx={{fontSize: '.9rem'}} color={'textSecondary'}>ADDRESS</Typography>
									<Typography noWrap sx={{fontSize: '1rem'}}>{formInput.meetingAddress}</Typography>
								</Stack>
								<Stack>
									<Typography sx={{fontSize: '.9rem'}} color={'textSecondary'}>SCHEDULE</Typography>
									<Typography noWrap sx={{fontSize: '1rem'}}>{formInput.meetingDate + ' at ' + formInput.meetingTime}</Typography>
								</Stack>
								<Stack>
									<Typography sx={{fontSize: '.9rem'}} color={'textSecondary'}>MEET</Typography>
									<Typography noWrap sx={{fontSize: '1rem'}}>{formInput.meetingType === 'video' ? 'Via Video Call' : 'At Home'}</Typography>
								</Stack>
								
							</Box>
						</Container>
					</DialogActions>
				}
			</Dialog>

			<Dialog open={info}>
				<DialogContent sx={{padding: '3rem', textAlign: 'center'}}>
				<IconButton onClick={()=> setInfo(false)} sx={{position: 'absolute', top: '5%', right: '3%'}}><Close fontSize='small' /></IconButton>
					<Typography sx={{fontSize: '1.3rem', fontWeight: 600}} variant='h6' mb={2}>Staying safe during COVID-19</Typography>
					<Typography variant='body2'>Unvaccinated attendees are required to wear a mask durring tours. Local guidance may also require that fully vaccinated individuals wear a mask. Due to local regulations, the number of tour attendees may be limited. Please contact your agent for details</Typography>
				</DialogContent>
			</Dialog>

			{/* Loader */}
			<Dialog open={response.open}>
				<DialogContent sx={{padding: '3rem', textAlign: 'center'}}>
				{ response.status === 'success' ? <CheckCircle color='primary' fontSize='large' /> : <ErrorOutline sx={{color: 'red'}} fontSize='large' /> }
				<IconButton onClick={onDialogClose} sx={{position: 'absolute', top: '5%', right: '3%'}}><Close fontSize='small' /></IconButton>
					<Typography sx={{fontSize: '1.3rem', fontWeight: 600}} variant='h6' mb={2}>{ response.status === 'success' ? 'Thank You' : 'Error' }</Typography>
					<Typography variant='body2'>{ response.status === 'success' ? 'Our Agent will get in touch with you shortly. Thank you for choosing BPO Homes' : 'Sorry, something went wrong. Please try again'}</Typography>
				</DialogContent>
			</Dialog>

		</>
	);
}

export default Sell;
