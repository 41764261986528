import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container, Typography } from '@mui/material';
const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '60vh',
		padding: '4rem 8rem',
		[theme.breakpoints.down('md')]: {
			padding: '20px',
		},
	},
	content: {
		textAlign: 'justify',
		fontWeight: 300,
	},
}));

const headers = [
	{
		name: 'Presentation',
		subs: [
			{
				content:
					'We offer a wide scope of administrations that emphasize all phases of the home life cycle: leasing, purchasing, selling, funding, and home improvement.',
				render: false,
			},

			{
				content:
					"We realize that everybody's home excursion is unique, and that settling on the ideal locations to call home is a private, individual experience. Alongside an abundance of data about homes, neighborhoods, economic situations, nearby realtors, and funding choices, we additionally utilize the data you give us to offer our types of assistance and assist you with viewings & evaluations of your next home.",
				render: false,
			},
			{
				content:
					"We cautiously assess how we use information to attempt to ensure that we're involving your data to offer some incentive for you. On this page, we intend to make sense of all that you really want to be aware of your security at BPO Homes. On the off chance that you have any inquiries that we haven't addressed here, you can constantly get in touch with us at privacy@bpohomes.com.",
				render: false,
			},
		],
	},
	{
		name: 'Data gathered by BPO Homes',
		subs: [
			{
				content:
					'At the point when you utilize our administrations, we gather an assortment of data from and about you and your gadgets. A portion of this data distinguishes you straightforwardly (like an email address) while some of it is related to you through your record, profile, or gadget (like the homes you decide to save or your area information).',
				render: false,
			},
			{
				content:
					"The sort of information we gather about you relies upon how you use BPO Homes. Assuming that you just utilize our administrations to pursue for homes, we'll have less data about you than if we assist you with selling a home, get a home loan, or sign your rent, or then again assuming you utilize any of our other exchange administrations. Regardless of whether you have a record with us, we might in any case gather some information about you, similar to your email address and telephone number assuming you decide to contact a realtor through one of our platforms or services, or through your IP address and other gadget data when you peruse our destinations or utilize our applications.",
				render: false,
			},
			{
				content:
					"At the point when you utilize our administrations, we frequently get some information about yourself. This normally incorporates fundamental things like your name, email address, and telephone number. Assuming that you utilize our exchange administrations, we could ask you for more touchy information like your pay, lodging history, and FICO rating. Assuming you contribute data, for example, specialist surveys, we'll store that also.",
				render: false,
			},
			{
				content:
					"You may likewise give data about others through our administrations. For instance, assuming you share a home posting with somebody, we will gather that individual's email address as a feature of your record data.",
				render: false,
			},
			{
				content: 'The following are a few instances of what data we gather for our primary items and administrations and how we gather it.',
				render: false,
			},
			{
				content:
					"Whenever you use BPO Home brands to find a home to purchase, rebuild, fabricate or lease,we give you loads of devices to see your next home. Assuming you let us know what you're searching for, for example, the kind of home or number of rooms, we might save that data so we can fit your list items to what you're searching for.",
				render: false,
			},
			{
				content:
					'We likewise assist you with interfacing with neighborhood realtors, home manufacturers, property directors, contract credit officials, and other realtors. Assuming you decide to get in touch with one of these professionals through our foundation, we request your name, email address, and telephone number so we can contact you and interface you with the perfect individual.',
				render: false,
			},
			{
				content:
					'You can likewise guarantee your home on BPOHomes.com and alter home realities like the area, number of washrooms, and latest redesign year. We interface the data you give to your record.',
				render: false,
			},
			{
				content:
					"Assuming you are a realtor, you might have the option to interface your outsider email account(like Gmail) to your BPO Homes account. Assuming you empower that association, BPO Homes will get to your messages, contacts, and settings just to offer the mentioned types of assistance to you. We don't access or gather any of the items in your email messages in giving interest-based promoting. We may use data obtained from Gmail APIs just in accordance with Google's Limited Use Requirements.",
				render: false,
			},
			{
				content:
					'We likewise gather your contact and installment subtleties, alongside proficient data like, related postings and deals, and authorizing history.',
				render: false,
			},
		],
	},

	{
		name: 'Data from Public Sources and Third Parties',
		subs: [
			{
				content:
					"BPO Homes gathers data from public sources, for example, region and state assessment and property records, government offices. The majority of this data is about properties and names of proprietors. Be that as it may, some data we gather, similar to the personality of a land's owner or land permit data, may connect with people.",
				render: false,
			},
			{
				content:
					'We likewise gather data about you from different outsiders to offer better support to you, or as a component of the work application process. This might incorporate segment data and different characteristics about you.',
				render: false,
			},
			{
				content: 'Alongside the data you give us straightforwardly, we gather an assortment of data consequently as you use BPO Homes services.',
				render: false,
			},
		],
	},
	{
		name: 'Movement data',
		subs: [
			{
				content:
					"We gather data about how you use BPO Homes. This incorporates things like your home pursuit history, homes you view, buy movement, what you've tapped on and different purposes of our elements, and how much time you spend checking out at various pieces of our sites. Whenever you call or message BPO Homes or a realtor utilizing our foundation, we gather information about your call or message, including the date and season of the call or message, your telephone number, and the substance of the call or message.",
				render: false,
			},
		],
	},
	{
		name: 'Gadget data',
		subs: [
			{
				content:
					"Like different sites and applications, we gather information about the programs and gadgets you use to get to BPO Homes. The information we gather incorporates program or gadget model and settings, working framework, extraordinary identifiers, and the adaptation of the application you're utilizing. We likewise gather information about how your programs and gadgets associate with our administrations, including IP address, crash reports, framework action, and the date, time, and the URL of the site you visited before BPO Homes.",
				render: false,
			},
			{
				content:
					'You have some control over our assortment of specific information, for example, your cell phone model or the language your cell phone utilizes, by changing the protection and security settings on your cell phone.',
				render: false,
			},
		],
	},
	{
		name: 'Area Information',
		subs: [
			{
				content:
					'Assuming you empower area administrations on your cell phone, BPO Homes gathers the area of your gadget. We utilize that data to give you area based data and promotions, similar to homes and realtors in your space, and to offer area based administrations, such as permitting you to open and visit BPO Homes Offers homes on your own timetable and guaranteeing your home on BPO Homes If you would rather not utilize these , you can switch off area administrations on your gadget.',
				render: false,
			},
			{
				content:
					'We likewise gather area data for comparable purposes from your program in the event that you empower it. You can handicap access through your program settings.',
				render: false,
			},
			{
				content: 'Threats, pixel labels, and other following innovations',
				render: false,
			},
			{
				content:
					'We and our accomplices utilize different apparatuses to gather information when you visit our destinations and applications, including, pixel labels, and other comparable advances.',
				render: false,
			},
			{
				content: 'Electronic information that can be moved to your PC or other gadget to distinguish your program.',
				render: false,
			},
			{
				content:
					"Whenever you use BPO Homes we and our accomplices might utilize different devices to assemble data about how you view and utilize our administrations and content, and to interface your movement with different information we store about you. The utilization of assists us with serving you better by getting what you're keen on, following patterns, estimating the adequacy of advertisements, saving your inclinations, and putting away data you might need to recover consistently, like your #1 homes. We likewise permit explicit, supported accomplices to gather information from your program or gadget for promoting and estimation purposes utilizing their own treats or comparative instruments.",
				render: false,
			},
			{
				content:
					"Whenever, you can utilize our instruments to oversee what sorts and other following advances you're OK with. Look at how to get to these apparatuses. You can likewise debilitate through and through by changing the  on your program. Be that as it may, assuming you decide to handicap some or all settings, many pieces of our administrations may never again work.",
				render: false,
			},
			{
				content:
					'Likewise, on the off chance that you give your email address to us, we might utilize a mixed, incomprehensible structure (referred to an as "hashed" form) of your email address to convey fitted advertisements to you on BPOHomes.com or on different sites, including by means of Facebook\'s Custom Audience Feature or other comparable devices. Click here to find out about how you have some control over the promotions you see on Facebook.',
				render: false,
			},
			{
				content:
					'For data about how you can deal with your inclinations, look at the segment of this arrangement called "Your protection instruments and decisions.',
				render: false,
			},
		],
	},
	{
		name: 'Informal organizations',
		subs: [
			{
				content:
					'Assuming you utilize web-based entertainment logins (like Google and Facebook) to sign in to BPOHomes.com, we might get to your public online entertainment profile data. You can constantly restrict this sharing utilizing your online entertainment administration settings.',
				render: false,
			},
			{
				content: 'Deductions',
				render: false,
			},
			{
				content:
					'To work on our administrations and make them more valuable to you, we use information about your movement to comprehend and surmise your inclinations. For instance, on the off chance that you ordinarily look for homes for lease instead of homes to purchase, we might accept that you are not hoping to purchase a home and show you more data supportive for tenants.',
				render: false,
			},
			{
				content: 'Business Information',
				render: false,
			},
			{
				content:
					'We gather data about the items and administrations you buy or consider buying from us.This incorporates items for experts, like our promoting items, as well as purchaser administrations like BPO Homes Offers.',
				render: false,
			},
			{
				content: 'Why BPO Homes utilizes your data',
				render: false,
			},
			{
				content: 'Finishing your exchanges',
				render: false,
			},
			{
				content:
					'Whenever you use BPO Homes to finish land exchanges, such as trading your home, we utilize your own data to check your personality and interaction and complete your exchange.',
				render: false,
			},
			{
				content: 'Giving and working on our administrations',
				render: false,
			},
			{
				content:
					'We utilize your data to offer our types of assistance to you. This incorporates things like getting back list items and handling your rental application. We likewise utilize your data to work on our current administrations and foster new ones. For instance, we track how you utilize our sites and applications and utilize that data to investigate issues and change things to work on your experience.',
				render: false,
			},
			{
				content:
					'Speaking with you and interfacing you with others we utilize the contact data you give us, similar to your telephone number and email address, to speak with you about our administrations. This incorporates things like answering remarks, questions, and demands you send us. It additionally incorporates advising you of exchange or administration updates and changes. We may likewise illuminate you about our administrations, offers, advancements, news, and different updates we think might bear some significance with you.',
				render: false,
			},
			{
				content:
					'Assuming you ask us to, we additionally utilize your data to interface you to realtors, similar to when you need to contact a specialist to talk about a home you find available to be purchased on our foundation.',
				render: false,
			},
		],
	},
	{
		name: 'Customizing your experience',
		subs: [
			{
				content:
					'We utilize the data we gather about you and your movement on our administrations to customize the administrations we offer. We additionally use it to show publicizing, content, or highlights that we figure you would like. This incorporates things like altered query items that match your inclinations and earlier hunt measures and, assuming you ask us to, interfacing you to a realtor the most appropriate to help you.',
				render: false,
			},
			{
				content:
					"Assuming we realize you're working with a specific realtor, we might share data about your saved homes and searches with that specialist to allow them to serve you all the more successfully. You control what we share with land professionals you find on our foundation.",
				render: false,
			},
			{
				content: 'Different purposes of your data',
				render: false,
			},
			{
				content:
					'We likewise utilize your information to recognize, examine, and forestall deceitful exchanges and other criminal operations and to safeguard the privileges and property of BPO Homes, its clients, and others.',
				render: false,
			},
			{
				content:
					"Once in a while, there might be an explanation we might want to involve your data such that isn't depicted here. Assuming that occurs, we'll tell you so you can choose if you're alright with it.",
				render: false,
			},
		],
	},
	{
		name: 'Sharing your data',
		subs: [
			{
				content: `We comprehend that imparting data to outsiders is a major liability. We're focused on sharing data just when required for an authentic business or legitimate reason, and afterward just with beneficiaries who will safeguard it.`,
				render: false,
			},
		],
	},
	{
		name: 'Data you request that we share',
		subs: [
			{
				render: false,
				content: `We share data when you request that we share it. For instance, assuming that you decide to contact a realtor, contract loan specialist, financial backer, manufacturer, property supervisor, or other land ace through our foundation, we will send them your name and contact data. Likewise, assuming that you apply for rental lodging through BPO Homes, your application data will be shipped off to the landowners you select.`,
			},
		],
	},
	{
		name: 'Specialist co-ops and colleagues',
		subs: [
			{
				content:
					"Whenever BPO Homes Inc enlists a specialist co-op to assist with working our business, we might have to give them admittance to data to offer their support. We permit them to utilize the data just to play out the help we've requested that they perform. We vet all specialist organizations cautiously, and BPO Homes Inc generally stays answerable for the protection of your data that is imparted to our specialist co-ops.",
				render: false,
			},
			{
				content:
					'At the point when BPO Homes accomplices with different organizations to offer items and administrations to you, we might import data to those accomplices just depending on the situation to give those items and administrations and simply depending upon the conditions of this protection strategy.',
				render: false,
			},
			{
				content: 'Legitimate and consistence moves',
				render: false,
			},
			{
				content:
					"Whenever BPO Homes needs to share information to fulfill a legitimate or administrative prerequisite, including answering a summon or other legal government demand for information, we will share data just as important to follow that requirement. We will tell you prior to sharing your data in these circumstances except if we're denied from doing as such. For more data about how we answer demands for information, see Government and Civil Information Requests. We may likewise share data if necessary to implement our lawful freedoms, recognize or forestall extortion or security concerns, and safeguard public well being.",
				render: false,
			},
			{
				content:
					'Assuming BPO Homes is associated with a consolidation, securing, or any type of move or offer of some or the entirety of its business, whether as a going concern or as a feature of insolvency, liquidation, or comparable procedure, we might impart your data in association with that exchange.',
				render: false,
			},
			{
				content: 'Public and de identified information',
				render: false,
			},
			{
				content:
					"We might share data that is gathered from public sources or that you decide to share freely (like public surveys and home realities) with outsiders. We may likewise share collected as well as deidentified data that can't sensibly be utilized to distinguish you.",
				render: false,
			},
		],
	},
	{
		name: 'Deals of individual data',
		subs: [
			{
				content:
					'BPO Homes permits specific organizations to put following innovations like treats and pixels on our destinations, which permit those organizations to get data about your action on BPO Homes that is related with your program or gadget. The organizations might utilize that information to serve you more applicable advertisements on our destinations or others. Under some state regulations, sharing information for web based promoting like this might be viewed as a "deal" of data. With the exception of this sort of sharing, BPO Homes doesn\'t sell any of your data.',
				render: false,
			},
			{
				content:
					'You generally have full command about whether these innovations work on your gadgets. You can cripple them completely utilizing our threat assent inclination apparatuses (see the BPO Homes Privacy Center for guidelines), or by tapping the "Don\'t Sell My Information" connect on our sites.',
				render: false,
			},
			{
				content: 'Security instruments and decisions',
				render: false,
			},
			{
				content: 'Getting to and erasing your data',
				render: false,
			},
			{
				content:
					"We've made an assortment of security instruments accessible on our locales. Utilizing the BPO Homes Privacy Center, you can see what sort of information we've gathered about you and the kinds of outsiders we share it with. You can likewise download a duplicate of your BPO Homes information and take it with you to a help outside of BPO Homes. Assuming you might want to erase your BPO Homes information or close your record, you can do that involving the BPO Homes Privacy Center too.",
				render: false,
			},
			{
				content:
					"We give these instruments to your advantage and we won't ever oppress you for utilizing them. Yet, assuming you decide to erase your information or close your record, we will not have the option to offer you benefits that expect us to utilize your information.",
				render: false,
			},
			{
				content: 'Overseeing inclinations',
				render: false,
			},
			{
				content:
					'The BPO Homes Privacy Center has data about how to utilize devices on our sites and applications to oversee and what is permitted when you use BPO Homes.',
				render: false,
			},
			{
				content:
					"BPO Homes outsider promotion servers, advertisement network suppliers, and outsider sponsors give advertisement content that shows up on BPOHomes.com. To gauge how successful those promotions are and to make them more applicable, advertisement suppliers might utilize their own following innovations to gather your data. These are utilized to record clients' action, for example, the pages visited, and to realize what kinds of data are of most interest to the clients. For more data with respect to the decisions you have about these advancements, (for example, quitting), visit the Network Advertising Initiative, Digital Advertising Alliance, Omniture, and PrivacyChoice.",
				render: false,
			},
			{
				content: 'Overseeing interchanges inclinations',
				render: false,
			},
			{
				content: 'Email memberships',
				render: false,
			},
			{
				content:
					"BPO Homes might send you an email for an assortment of reasons. For instance, assuming you save a pursuit, we'll send you messages with new homes available that match your inquiry. Assuming you save a home, we'll update you as often as possible on announcements like when that home has an open house booked and when it has a deal forthcoming. Whenever you utilize our exchange administrations, we'll send you notices and other data about your exchange. On the off chance that you have a record with BPO Homes, you can choose your inclinations through your record settings. Additionally, you can deal with the sorts of email you get by adhering to the guidelines remembered for each email we send you. Regardless of whether you withdraw from some email memberships, we might in any case have to email you with significant conditional or other non-promoting data.",
				render: false,
			},
		],
	},
	{
		name: 'Calls and texts',
		subs: [
			{
				content:
					'A portion of our administrations permit you to give your telephone number to us. Assuming that you give us your number, we might call or text you, for instance, to give you data about helping a proposal on your home through BPO Homes Offers. We will reach you through these techniques just as per material legitimate prerequisites, and you generally can quit further calls or texts.',
				render: false,
			},
			{
				content: 'Safeguarding your data',
				render: false,
			},
			{
				content:
					'We comprehend that regardless of how we gather your data, we have an obligation to safeguard it. We treat that obligation in a serious way. We incorporate security into our items and elements from the beginning to assist with shielding information from unapproved access, divulgence, modification, and annihilation.',
				render: false,
			},
			{
				content:
					"We're continuously searching for dangers and finding sensible ways to answer those dangers, safeguarding the data clients share with us. Obviously, no technique for Internet transmission or information stockpiling can give assurance to your data. Assuming that anything ought to at any point happen to your data, we'll tell you straight away and make an honest effort to make it right. ",
				render: false,
			},
			{
				content:
					'BPO Homes is situated in the United States and the data we gather is administered fundamentally by U.S. regulation. Where we are dependent upon the protection laws of different nations, we follow those necessities. In the event that you access or utilize our administrations or give data to us, your data will be handled and put away in the United States, where you might not have similar privileges and insurances as you do under your nearby regulation',
				render: false,
			},
		],
	},
	{
		name: 'Relevance of this arrangement',
		subs: [
			{
				content: 'This approach applies to each help of our own that connects to it, including BPO Homes Inc.',
				render: false,
			},
			{
				content:
					"Our applications and administrations might connect to other organizations' sites. A few highlights may likewise include the imparting of your data to outsider sites. These outsider sites might gather data about clients on those sites. BPO Homes doesn't control those destinations, and this protection strategy doesn't have any significant bearing to these outer sites and outsiders.",
				render: false,
			},
			{
				content: 'Lawful Disclosures',
				render: false,
			},
			{
				content:
					"In this segment, we've given a few legitimately required revelations under state protection regulations. There ought not be anything amazing in this segment, and we've attempted to make sense of our security rehearses in plain English all through the remainder of the strategy.",
				render: false,
			},
			{
				content:
					'Under the California Consumer Privacy Act or CCPA, California occupants have specific freedoms in regards to their information, including:',
				render: false,
			},
			{
				content:
					"The option to know the classes of individual data we've gathered and the classifications of sources from which we got the data (see Information gathered by BPO Homes Inc) The option to realize the business purposes for sharing individual data (see Why BPO Homes Inc utilizes your data)",
				render: false,
			},
			{
				content: "The option to know the classifications of outsiders with whom we've shared individual data (see Sharing your data)",
				render: false,
			},
			{
				content:
					"The option to get to the particular bits of individual data we've gathered and the option to erase your data (see Privacy instruments and decisions)",
				render: false,
			},
			{
				content:
					'California inhabitants likewise reserve the option to not be victimized assuming they decide to practice their security privileges.',
				render: false,
			},
		],
	},
	{
		name: 'Protecting your information',
		subs: [
			{
				content:
					'We grasp that paying little mind to how we accumulate your information, we have a commitment to protect it. We approach that commitment in a genuine manner. We fuse security into our things and features beginning from the earliest stage to help with protecting data from unapproved access, disclosure, change, and destruction.',
				render: false,
			},
			{
				content:
					"We're constantly looking for risks and track down reasonable ways of noting those risks, shielding the information clients share with us. Clearly, no methodology for Internet transmission or data amassing can give guaranteed affirmation to your information. Accepting anything should anytime happen to your information, we'll tell you in a rush and put forth a legit attempt to make it right.",
				render: false,
			},
			{
				content:
					"BPO Homes Inc is arranged in the United States and the information we assemble is managed mainly by U.S. guidelines. Where we are reliant upon the insurance laws of various countries, we agree to those requirements. If you access or use our organizations or give information to us, your information will be taken care of and set aside in the United States, where you probably won't have comparable opportunities and protections as you do under your close-by guideline.",
				render: false,
			},
		],
	},
	{
		name: 'Genuine nature of this technique',
		subs: [
			{
				content: 'This game plan applies to our own interfaces, including all interfaces BPO Homes Inc & BPO Homes.com.',
				render: false,
			},
			{
				content:
					"Our applications and organizations could associate with other associations' destinations. A couple of components may similarly incorporate the contribution of your information to untouchable destinations. These untouchable destinations could accumulate information about clients on those locales. BPO Homes Inc doesn't control those areas, and this security procedure doesn't have an effect on these external destinations and untouchables.",
				render: false,
			},
		],
	},
	{
		name: 'Legal Disclosures',
		subs: [
			{
				content:
					"In this section, we've given a couple of legally required divulgences under state security guidelines. There should not be anything astonishing in this fragment, and we've endeavored to figure out our insurance practices in plain English all through the rest of the system.",
				render: false,
			},
			{
				content: 'Under the California Consumer Privacy Act or CCPA, California occupants have explicit honors as for their data, including:',
				render: false,
			},
			{
				content:
					"The choice to know the classes of individual information we've accumulated and the characterizations of sources from which we got the information",
				render: false,
			},
			{
				content: 'The choice to understand the business purposes for sharing individual information',
				render: false,
			},
			{
				content: "The choice to know the orders of outcasts with whom we've shared individual information",
				render: false,
			},
			{
				content: "The choice to get to the specific pieces of individual information we've accumulated and the choice to eradicate your information",
				render: false,
			},
			{
				content: 'California tenants moreover hold the choice to not be defrauded accepting they choose to rehearse their security opportunities.',
				render: false,
			},
		],
	},
];

function PrivacyPolicy() {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
    <Container>
        <Typography variant='h1' fontSize='35px' textAlign='center' fontWeight='700' mb='2rem'>
          BPO Homes Privacy Policy
        </Typography>
        <Typography paragraph className={classes.content}>
          At the point when you use BPO Homes administrations to find, purchase, lease, or sell yourhome, get a home loan, or associate with a land
          professional, we realize you're entrusting uswith your information. We have an obligation to regard your security, and strive to safeguard
          your protection. This approach makes sense of what data we gather, why we use it, whom weshare it with, and how we safeguard it, alongside the
          apparatuses you can use to deal with your security.
        </Typography>
        {headers.map((header, index) => (
          <React.Fragment key={index}>
            <Typography variant='h2' fontSize='20px' fontWeight='700' mb='1rem' mt='1rem'>
              {header.name}
            </Typography>
            {header.subs.map((sub, index) =>
              sub.render ? (
                <Typography key={index} paragraph className={classes.content} dangerouslySetInnerHTML={{ __html: sub.content }}></Typography>
              ) : (
                <Typography key={index} paragraph className={classes.content}>
                  {sub.content}
                </Typography>
              )
            )}
          </React.Fragment>
        ))}

    </Container>
		</Box>
	);
}

export default PrivacyPolicy;
