import { Avatar, Box, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import PartnerAbt from '../../assets/partner.png'
import SEO from '../../components/SEO'

const useStyles = makeStyles(theme => ({
    banner: {
        textAlign: 'center',
        height: '70vh',
        backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 50%), rgba(0,0,0, 50%)), url(${PartnerAbt})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bar: {
        background: theme.primaryColor,
        height: '5px',
        border: 'none',
        width: '30%',
        margin: '2rem auto 3rem auto'
    },
    listItem: {
        gap: '2rem',
        padding: '1rem 10px'
    }
}))
const AboutPartner = () => {
    const classes = useStyles()

    const lists = [
        'Partner Premier Agents can be found on our Partner Premier Agent Finder Page in which they are advertised as Buyers & Sellers Agents in 5 cities of Agents Choice',
        'Partner Premier Agents can upload a personal marketing Video promoting Agents services on their BPO Homes custom Premier Agent Web Page',
        'BPO Homes builds Partner Premier Agents a Custom Premier Agent Website featuring Agent Video & all Active & Sold Listings',
        `BPO Homes builds Partner Premier Agents a Custom BPO Report Ordering Page in which
        Agents receive and are able to sell BPO Report orders on their page. Agents can then create BPO Reports within minutes earning $100.00 for generating the BPO Report. To top it off Agents then receive the Lead to the Seller or Buyer who requested the BPO Repor`,
        `BPO Listing Page Builder is included with Partner Premier Agent. Agents may use a Custom Url (Agent Provided) as an example 123parkave.com or and example a FREE URL such as BPOHomes.com/123parkave provided by BPO Homes`,
        `Agents receive a FREE Professional Digital Business card with NFC technology
        One touch of your card and your new potential client has all of your contact information and view your current Active Listings on their mobile phone right at their fingertips`,
        `Agents receive FREE Targeted Facebook Ads for the 1st 12 months of subscription (1 Ad per Month)`,
        `BPO Homes provides Agents with leads generated from BPOHomes.com advertisements 
        (Leads subject to referral fee of 10% to 15% of gross compensation) other required Listing Terms and Seller & Buyer closing cost credits apply for such leads provided to Partner Premier Agents.`,
        `1.5% percent listing fee with .5% credit to seller if Seller makes a new purchase with Partner Premier Agent within the next 365 days from the close of escrow`,
        `0.00255% Buyer closing cost credit applies to Buyer Agent leads (Does not apply to buyers receiving .5% closing credit from previous listing sale)`
    ]

  return (
    <div>
            <SEO>
				<title>Partner Premier Agent - The Ultimate Real Estate Branding Solution</title>
				<meta name='og:title' content='Partner Premier Agent - The Ultimate Real Estate Branding Solution' />
				<meta
					name='description'
					content='Partner Premier Agent is a comprehensive real estate branding platform that helps agents grow their business online. For only $69/month, agents receive a custom website, BPO Report ordering page, Facebook Ads, and more. Get started today and stay ahead of the game!'
				/>
				<meta
					name='og:description'
					content='Partner Premier Agent is a comprehensive real estate branding platform that helps agents grow their business online. For only $69/month, agents receive a custom website, BPO Report ordering page, Facebook Ads, and more. Get started today and stay ahead of the game!'
				/>
			</SEO>
        <Box className={classes.banner}>
            <Stack>
                <Typography variant='h4' sx={{fontWeight: 600}} mb={5}>ABOUT PARTNER PREMIER AGENT</Typography>
            </Stack>
        </Box>

        <Container>
            <Box padding={'4rem 0'}>
                <Typography textAlign={'center'}>Partner Premier Agent is a BPO Homes Platform branding Agents online to assist there business growth. Online business presence is a key ingredient of our World Today and Real Estate tech is forever evolving. As a Real Estate professional you need to keep up with these constant changes in order to keep your head above water. At BPO Homes we understand what it takes to market Agents and keep them competitive.
                </Typography>
                <Divider sx={ {
                    background: (theme)=> theme.primaryColor,
                    height: '5px',
                    border: 'none',
                    width: '30%',
                    margin: '2rem auto 3rem auto'
                }} />
            </Box>

            <Box >
                <Typography variant='h6' textAlign={'center'} sx={{fontWeight: 600}} mb={4}>
                    Let’s now talk below about what you receive as a Partner Premier Agent for only $69.00 per month!
                </Typography>
                <List>
                    {
                        lists?.map((item, index) => {
                            return (
                                <ListItem key={index} className={classes.listItem} >
                                    <ListItemIcon ><Avatar>{index + 1}</Avatar></ListItemIcon>
                                    <ListItemText>
                                        <Typography>{item}</Typography>
                                    </ListItemText>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Box>
        </Container>
    </div>
  )
}

export default AboutPartner