import { Circle } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Floor from '../../assets/floor.jpg'
import Double from '../../assets/Double.jpg'
import Bedroom5 from '../../assets/bedroom5.jpg'
import Junior from '../../assets/Junior.jpg'
import { scrollUp } from '../../constants'
import SEO from '../../components/SEO'

const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${Floor})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const FloorOptions = () => {
    const classes = useStyles()
    const navigate = useNavigate()

    const areas = [
        'Compare prices between materials – take the time to compare and contrast pricing between wood, tile, laminate and carpet before making a final decision.',
        'Consider using a flooring cost calculator – this allows you to input details such as room size and type of material desired in order to get an accurate estimate of how much each project will cost.',
        'Don’t forget to factor in installation costs – while certain types of flooring may seem more affordable upfront, they may require additional labor or special tools which could increase their overall price tag. By taking these factors into account when planning out your next renovation project, you’re sure to find cost-effective flooring solutions that fit within your budget without compromising on quality or aesthetics.',
        
    ]


  return (
    <div>
            <SEO>
				<title>Flooring Options For Every Room In Your Home</title>
				<meta name='og:title' content="Flooring Options For Every Room In Your Home" />
				<meta
					name='description'
					content=""
				/>
				<meta
					name='og:description'
					content=""
				/>
			</SEO>
        <Box className={classes.banner}></Box>
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
            <Typography textAlign={'center'} gutterBottom variant='h4'>Flooring Options For Every Room In Your Home</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor}} />
        </Box>

        <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                    <Typography paragraph mb={5}>
                        Are you looking for the perfect flooring option to complete your home? No matter what room in your house, there’s a type of flooring that will fit perfectly. From classic hardwood floors to modern tile and laminate options, there is something to match any style or budget. Here we’ll explore some of the most popular types of flooring available today so you can find the right one for every room in your home
                    </Typography>
                    <Typography paragraph>
                        Are you ready to tackle your next big project? Whether it’s replacing old floors or adding new ones, choosing the right kind of flooring can make all the difference. You want something that looks great but also stands up against wear and tear — without breaking the bank. That’s why it pays off to do your research before making any decisions about which materials are best for each area of your space.

                    </Typography>
                    <Typography paragraph>
                        From high-traffic living rooms and kitchens to cozy bedrooms and bathrooms, let’s take a look at some of the top contenders when it comes to finding quality flooring solutions for every part of your home.

                    </Typography>

                    <Typography className={classes.title} variant='h6'>Types Of Flooring</Typography>
                    <Typography paragraph>
                        When choosing your flooring, there are several options available to you. Vinyl plank and laminate tile offer a durable solution for high-traffic areas like kitchens or hallways. Hardwood floors bring an elegant feel to any room in the house - they even come in engineered varieties that are more resistant to moisture than solid hardwoods. Cork flooring is another great choice; it’s soft underfoot and comes in many colors and patterns. Finally, bamboo flooring offers durability at a lower cost than traditional hardwood floors.

                    </Typography>
                    <Typography paragraph>
                        No matter which type of flooring you choose, make sure it fits within your budget and lifestyle needs. Before making a purchase decision, consider whether you’ll need additional installation services such as leveling, padding or waterproofing - these costs can add up quickly! And be sure to keep maintenance requirements in mind; some types of flooring require regular waxing or polishing while others do not. Taking all this into account will help ensure your new floors last for years to come.

                    </Typography>
                    
                    <Typography className={classes.title} variant='h6'>Advantages And Disadvantages Of Different Materials</Typography>
                    <Typography paragraph>
                        When selecting the right type of flooring for your home, it’s important to consider both the advantages and disadvantages associated with each material. Let’s take a closer look at some popular options:
                        Wood is one of the most stylish and classic choices for any room in your house. It looks great and can last for many years - but it does require regular maintenance such as waxing or polishing. Plus, wood floors can be quite expensive compared to other materials.
                    </Typography>

                    <Typography paragraph>
                        Tile is another attractive option that comes in a variety of styles, colors and textures. Its durability makes it ideal for high traffic areas like kitchens or bathrooms; however, tile can be cold underfoot and may not offer as much soundproofing as carpeted areas. Carpet is soft and inviting, making it perfect for bedrooms or living rooms where comfort is key. However, carpets tend to show wear over time due to dirt buildup which requires frequent cleaning. In addition, they are generally more difficult to install than hardwood or tile floors

                    </Typography>
                    <Typography paragraph>
                        So there you have it - different types of flooring come with their own distinct advantages and drawbacks depending on your needs and budget. With careful consideration of these factors when choosing flooring for your home, you’ll enjoy beautiful results that will stand up to everyday use.

                    </Typography>

                    <Typography className={classes.title} variant='h6'>Cost Considerations</Typography>
                    <Typography paragraph>
                        As you can see, there are many different flooring options to choose from - but what about cost? When it comes to selecting the perfect flooring for your home, budget is a factor that should not be overlooked. Here are some tips on getting the most value-for-money:
                    </Typography>

                    <Grid container>
                        {
                            areas?.map((el, index) => {
                                return(
                                    <Grid item sm={12} key={index}>
                                        <ListItem>
                                            <ListItemIcon sx={{minWidth: '35px'}}><Circle sx={{fontSize: '.7rem'}} color='secondary' /></ListItemIcon>
                                            <ListItemText>{el}</ListItemText>
                                        </ListItem>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                    <Typography className={classes.title} variant='h6'>Installation Requirements</Typography>
                    <Typography paragraph>
                        When it comes to installing your new flooring, there are a few things you should keep in mind. First and foremost is making sure the subfloor is level before laying down any material. Uneven surfaces can cause issues with adhesive or grout lines which may lead to costly repairs later on. Additionally, some types of flooring require different installation techniques than others – for example, carpet needs to be stretched whereas laminate requires an interlocking system.
                    </Typography>

                    <Typography paragraph>
                        It’s also important to read up on any specific requirements that come with the type of flooring you choose. For instance, certain wood floors need acclimation time prior to installation while tile often calls for specialized tools when cutting and fitting pieces together. If you’re still unsure about how best to go about the project, consider consulting a professional installer who can provide advice and assistance throughout the process
                    </Typography>
                    <Typography paragraph>
                        No matter what route you decide to take make sure all safety protocols are followed during installation - this includes wearing protective gear such as gloves and masks as well as using appropriate power tools with proper ventilation systems if applicable. Taking these steps will ensure your new floor looks great and stands up to everyday wear-and-tear for years to come!

                    </Typography>


                    <Typography className={classes.title} variant='h6'>Conclusion</Typography>
                    <Typography paragraph>
                        Now that you have a better understanding of the different flooring options available for your home, it’s time to decide which is right for you. Ultimately, the choice comes down to personal preference and budget. It’s important to weigh all of the advantages and disadvantages before making any final decisions.
                    </Typography>

                    <Typography paragraph>
                        When installing new floors in your home, make sure you consider factors such as cost, installation requirements, and how well the material will hold up over time. Consider what works best with your lifestyle, whether that be hardwood or tile floors – just remember that proper maintenance is key!
                    </Typography>

                    <Typography paragraph>
                        At the end of the day, no matter what type of flooring you choose for each room in your home, it’ll add character and value when done correctly. So take some time evaluating your options so you can enjoy beautiful floors for years to come!
                    </Typography>
                   
                </Box>
            </Container>
        </Box>

            <Box mt={4} mb={5}>
                <Container>
                    
                    <Typography variant='h6' mt={2} mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/junior-master-bedrooms')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Junior})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Junior Master Bedrooms</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Are you a growing family, looking for extra space without the hassle of having to move?...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/maximizing-space')
                                scrollUp()
                              }}>
                                <CardHeader style={{backgroundImage: `url(${Bedroom5})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Maximizing Space in Small Bedrooms</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Transform your small bedroom into a functional and stylish oasis...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/kitchen-remodeling')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Double})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} noWrap mb={2}>The Benefits Of Upgrading To Double Pane Windows</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Are you ready to upgrade your windows? If so, double pane windows are an excellent choice.... </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>
    </div>
  )
}

export default FloorOptions