import React from 'react'
import SEO from '../../components/SEO'
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, styled } from '@mui/material'
import BannerImg from '../../assets/01-plumbing-real-estate.jpg'
import Kitchen4 from '../../assets/kitchen4.jpg'
import Bedroom5 from '../../assets/bedroom5.jpg'
import Perfect from '../../assets/perfect.jpg'
import { Circle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { scrollUp } from '../../constants'


const Banner = styled(Box)({
    height: '60vh',
    backgroundImage: `url(${BannerImg})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover'
})

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const PlumbingRealEstate = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const lists = [
        'Check for water pressure – Make sure all faucets are running properly and that there is enough water flow throughout the entire house.',
        'Inspect piping material– Look at the pipes under your sink and behind walls to see if they are copper, PVC, galvanized steel, PEX (cross-linked polyethylene), or CPVC (chlorinated polyvinyl chloride).',
        `Verify insulation– Ensure that proper insulation has been applied around exposed pipes in attics and basements to prevent them from freezing during cold weather months. Examine shutoff valves– Test each valve to make sure they work correctly; replace any faulty ones as needed.`,
        `Assess ventilation options– Poorly ventilated areas can cause moisture problems and lead to mold growth, so confirm that all bathrooms have adequate ventilation systems installed.`
    ]

  return (
    <div>
        <SEO>
				<title>The Importance Of Plumbing In Real Estate: A Guide For Homebuyers</title>
				<meta name='og:title' content="The Importance Of Plumbing In Real Estate: A Guide For Homebuyers" />
				<meta
					name='description'
					content=""
				/>
				<meta
					name='og:description'
					content=""
				/>
			</SEO>
            <Banner />
            <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
                <Typography textAlign={'center'} gutterBottom variant='h4'>The Importance Of Plumbing In Real Estate: A Guide For Homebuyers</Typography>
                <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', bgcolor: 'primary.main'}} />
            </Box>

            <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                    <Typography paragraph mb={5}>
                        Plumbing plays an essential role in the real estate world. Every homebuyer should understand its importance to ensure they get the best property for their money.
                    </Typography>
                    <Typography paragraph>
                        At BPO Homes, we believe that knowledge is power. That's why this article will detail everything you need to know about plumbing and how it relates to your next purchase. Read on to learn all about the importance of plumbing when buying a home!
                    </Typography>
                    <Typography paragraph>
                        From leaky pipes to clogged drains, plumbing issues can be costly and time-consuming if not addressed properly. Homebuyers should always look out for signs of potential problems during inspections or walkthroughs of properties they're interested in.
                    </Typography>
                    <Typography paragraph>
                        In addition, understanding what’s included with a house’s plumbing system ensures buyers are getting exactly what they pay for—and nothing more than necessary. With this guide from BPO Homes, you'll have all the information needed to make informed decisions about your new home!
                    </Typography>


                    <Typography className={classes.title} variant='h6'>Identifying Potential Plumbing Problems</Typography>
                    <Typography paragraph>
                        When searching for a new home, it's important to be aware of the plumbing system before purchasing. Plumbing affects everything from water lines to sanitation and is essential for any property.
                    </Typography>
                    <Typography paragraph>
                        Here are some tips on how you can identify potential plumbing problems when viewing homes for sale.
                    </Typography>
                    <Typography paragraph>
                        One way to ensure that there aren't hidden issues in the plumbing system of a home is through testing methods such as pressure tests and dye tests. Pressure tests include running water at specific pressures while checking all fixtures and faucets throughout the house. Dye tests use food-grade colorants injected into toilets or sinks so they can trace the path of flows between different pipes within the house. Both these types of inspections will give you an indication if something needs further investigation by a professional plumber.
                    </Typography>
                    <Typography paragraph>
                        It's also important to check the condition of existing water lines leading into and out of your prospective home, including main service lines, sewer systems, gas piping, sprinkler systems (if applicable), hot water tanks, etc. If possible, look around outside near utility meter accesses to see if there are signs of leaking or corrosion – both could point towards underlying issues with underground pipes that may require repair or replacement over time. Doing research ahead of time and being knowledgeable about what to look out for can make buying a home easier down the road - not only financially but also emotionally!
                    </Typography>


                    <Typography className={classes.title} variant='h6'>Understanding What's Included In A House's Plumbing System</Typography>
                    <Typography paragraph>
                        As a homebuyer, understanding the plumbing system of your prospective property is essential for determining its value. The last thing you want to face on moving day is a broken pipe or outdated fixtures. That’s why it’s important to know what’s included in the house's plumbing system before committing:
                    </Typography>
                    <List>
                        {
                            lists?.map( (el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon sx={{minWidth: '35px'}}><Circle sx={{fontSize: '.7rem'}} color='secondary' /></ListItemIcon>
                                        <ListItemText>{el}</ListItemText>
                                     </ListItem>
                                )
                            })
                        }
                    </List>

                    <Typography paragraph>
                        Overall, knowing what’s included in your new home's plumbing system will give you peace of mind when making an offer. It pays to be prepared—taking time upfront could save you costly repairs down the road!
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Negotiating Property Deals Based On Plumbing Needs</Typography>
                    <Typography paragraph>
                        When it comes to negotiating real estate deals, plumbing needs can play a major role. It's essential for homebuyers to assess the costs associated with any necessary repairs or replacements that may be needed before signing on the dotted line. Buyers should also ensure they clearly understand what is outlined in their contract regarding plumbing services and make sure all expectations are set up front. Before entering into negotiations, buyers must determine if there are any issues related to plumbing that need addressing.
                    </Typography>
                    <Typography paragraph>
                        This could include anything from clogged drains and leaky pipes to outdated fixtures and appliances. Knowing these details ahead of time will help purchasers better understand how much they'll have to spend on fixing any problems prior to closing. In addition, when establishing contracts between buyer and seller, it's important for both parties to agree upon who will pay for any plumbing-related expenses after the sale has been finalized.
                        Establishing this information during negotiations can prevent unexpected surprises down the road and provide additional peace of mind when making such an important purchase decision. As always, consulting a qualified professional throughout the buying process is often invaluable - especially when dealing with potential plumbing issues!
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Conclusion</Typography>
                    <Typography paragraph>
                         When it comes to buying a home, plumbing should be top of mind. It's essential that buyers understand what type of plumbing systems are in place and negotiate accordingly. Homebuyers who arm themselves with knowledge about the importance of plumbing before making an offer can save time and money down the line - both during their purchase process and after they move into their new home.
                    </Typography>

                    <Typography paragraph>
                        So don't forget to factor in this important element when shopping for real estate; it's often the difference between a great investment and one you'll regret!
                    </Typography>
                   
                </Box>
            </Container>
        </Box>

        {/* READ ALSO */}
        <Box mt={4} mb={5}>
                <Container>
                    
                    <Typography variant='h6' mt={2} mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/perfect-design')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Perfect})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Creating The Perfect Master Suite</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Have you ever dreamed of having a luxurious master suite that’s the envy...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/maximizing-space')
                                scrollUp()
                              }}>
                                <CardHeader style={{backgroundImage: `url(${Bedroom5})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Maximizing Space in Small Bedrooms</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Transform your small bedroom into a functional and stylish oasis...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/kitchen-remodeling')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Kitchen4})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Kitchen Remodel</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Create a stylish, functional space that will boost your home's appeal... </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>

    </div>
  )
}

export default PlumbingRealEstate