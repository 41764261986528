import { alpha, Box, Typography } from '@mui/material'
import React from 'react'

const Neighborhood = ({name, image, onClick}) => {
  return (
    <Box onClick={onClick} sx={{
        padding: '2rem', 
        backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 70%), rgba(0,0,0, 50%)), url(${image})`, 
        color: '#fff',
        borderRadius: '8px',
        backgroundPosition: 'center',
        backgroundSize: 'center',
        cursor: 'pointer'
        }}>
        <Typography component={'h3'} sx={{fontWeight: 500, fontSize: '1.1rem'}}>{name} - Homes For Sale</Typography>
        <Typography variant='body2' sx={{ color: alpha('#fff', 0.8)}}>View Listings</Typography>
    </Box>
  )
}

export default Neighborhood