import {
	Avatar,
	Box,
	Card,
	CardContent,
	CircularProgress,
	Container,
	Dialog,
	DialogContent,
	Divider,
	FormLabel,
	Grid,
	Hidden,
	InputAdornment,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Pagination,
	Rating,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import BannerImg from '../assets/header_image.jpg';
import { Call } from '@mui/icons-material';
// import Feeds from '../../components/Feeds/Feeds';
import ListAG from '../assets/listAgent.png';
import { useState, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import base from '../api/apis.js';
import useGeoLocation from '../hooks/useGeoLocation';
import { sampleSize, uniqBy } from 'lodash';
import { formatPhoneNumber } from '../constants/functions';
import { languages, specialties } from '../constants';
import SEO from '../components/SEO';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .feed_cards_wrapper': {
			maxWidth: '100% !important',
		},
	},
	banner: {
		backgroundImage: `url(${BannerImg})`,
		backgroundSize: 'cover',
		height: '50vh',
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			height: '100%',
		},
	},
	label: {
		color: '#fff',
		fontSize: '.9rem',
	},
	field: {
		'& .MuiOutlinedInput-root': {
			marginTop: '10px',
			background: '#fff',
			borderRadius: '5px',
			overflow: 'hidden',
		},
		'& .MuiSelect-outlined': {
			//marginTop: '10px',
			background: '#fff',
			borderRadius: '5px',
			overflow: 'hidden',
		}
	},
	avatar: {
		width: theme.spacing(13),
		height: theme.spacing(13),
	},
	divider: {
		background: theme.secondaryColor,
		height: '1px',
		marginTop: '10px',
	},
	card: {
		marginBottom: '1.5rem',
		borderRadius: '10px',
		cursor: 'pointer',
		'&:hover': {
			boxShadow: '0 1px 10px rgba(0,0,0,11%)',
			border: `1px solid ${theme.secondaryColor}`,
		},
	},
	listIcon: {
		width: '6rem',
		marginBottom: '1rem',
		opacity: 0.8,
	},
	noList: {
		height: '30rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		background: grey[200],
		borderRadius: '10px',
		marginBottom: '2rem',
	},
}));

const FeaturedAgent = ({ image, name, phone, rating, id, border }) => {
	return (
		<>
			<Box
				display={'flex'}
				justifyContent='flex-start'
				alignItems={'center'}
				gap='1rem'
				sx={{padding: '1rem', overflow: 'hidden', cursor: 'pointer', border: border && `1px solid ${grey[200]}`, borderRadius: '10px' }}
				onClick={(e) => { e.stopPropagation(); window.open(`/agents/${id}`, '_blank') }}
			>
				<Avatar src={image} sx={{
                        width: '6rem',
                        height: '6rem',
						'&>img': {
							objectPosition: 'top center',
						}
                    }} />
				<Box>
					<Typography variant='body1' sx={{width: '100%'}} noWrap >{name}</Typography>
					<Rating color='primary' size='small' value={rating} readOnly precision={0.5} />
					<ListItem disablePadding>
						<ListItemIcon sx={{ minWidth: '30px' }}>
							<Call fontSize='small' />
						</ListItemIcon>
						<ListItemText>
							<Typography variant='body2'>{ formatPhoneNumber(phone)}</Typography>
						</ListItemText>
					</ListItem>
				</Box>
			</Box>
		</>
	);
};

const AgentFinder = () => {
	const classes = useStyles();
	const { location } = useGeoLocation();
	const { address } = location;
	const [open, setOpen] = useState(false);
	const [loc, setLoc] = useState('');
	const [name, setName] = useState('');
	const [specs, setSpecs] = useState('');
	const [language, setLanguage] = useState('');
	const [data, setData] = useState(null);
	const [featuredAgents, setFeaturedAgents] = useState(null);
	const [pageCount, setPageCount] = useState(null);
	const [load, setLoad] = useState({location: false, name: false, specs: false, language: false})


	const fetchAgents = async () => {
		try {
			const { data: agents } = await base.get('/register/agents/');
			const { data: featured } = await base.get('register/random_paid_agent/');

			//sample featuredAgents
			setFeaturedAgents((state) => {
				const res = sampleSize(featured, 3);
				console.log(res)
				return res;
			});
			setData(agents.results);
			setPageCount(agents.count);
		} catch (error) {}
	};

	useEffect(() => {
		fetchAgents();
	}, []);

	const handleChange = async (e, page) => {
		setOpen(true);
		const { data: res } = await base.get(`/register/agents/?page=${page}`);
		setData(res.results);
		setOpen(false);
		window.scrollTo({ top: 220, behavior: 'smooth'})
	};

	const onFormSubmit = async (obj) => {
		//call loader
		if(obj.field === 'name'){
			setName(obj.value)
			setLoad(prev => {
				return {...prev, name: true}
			})
		}if(obj.field === 'location'){
			setLoc(obj.value)
			setLoad(prev => {
				const el = {location: false, name: false, specs: false, language: false}
				return {...el, location: true}
			})
		}if(obj.field === 'specs'){
			setSpecs(obj.value)
			setLoad(prev => {
				const el = {location: false, name: false, specs: false, language: false}
				return {...el, specs: true}
			})
		}if(obj.field === 'language'){
			setLanguage(obj.value)
			setLoad(prev => {
				const el = {location: false, name: false, specs: false, language: false}
				return {...el, language: true}
			})
		}

		if (loc === '' && name === '' && language === '' && specs === '') {
			fetchAgents()
		}

		// set queries
		const qloc = `service_areas__location__icontains=${ obj.field === 'location' ? obj.value : loc }`;
		const qname = `search=${ obj.field === 'name' ? obj.value : name }`;
		const qlang = `languages__name__icontains=${ obj.field === 'language' ? obj.value : language}`;
		const qspeciality = `specialties__title__icontains=${ obj.field === 'specs' ? obj.value : specs}`;
		let url = `/register/agents?${qloc}&${qname}&${qlang}&${qspeciality}`;
		let randomApi = `/register/random_paid_agent?${qloc}&${qname}&${qlang}&${qspeciality}`;
		
		// connect to api
		const { data: res } = await base.get(url);
		const { data: featured } = await base.get(randomApi);
		setFeaturedAgents(uniqBy(featured, 'id'));
		setLoad(false);
		setData(res.results);
		setPageCount(res.count);
		window.scrollTo({ top: 260, behavior: 'smooth' });
		
	};

	return (
		<div className={classes.root}>
		<SEO>
				<title>Find & Contact Real Estate Agents in Your Local Area</title>
				<meta name='og:title' content="Find & Contact Real Estate Agents in Your Local Area" />
				<meta
					name='description'
					content="Find trusted real estate agents in your local area with BPOHomes. Connect with the right agent for your needs and feel confident in your real estate journey."
				/>
				<meta
					name='og:description'
					content="Find trusted real estate agents in your local area with BPOHomes. Connect with the right agent for your needs and feel confident in your real estate journey."
				/>
			</SEO>
        {/* Banner */}
        <Box className={classes.banner}>
                <Box></Box>
                <Box padding={'4rem 1rem'} bgcolor={'#09339782'} color={'#fff'} height={'fit-content'}>
                    <Container maxWidth='lg' sx={{padding: '0 4rem'}}>
                        <Typography variant='body2'>Property Address</Typography>
                        <Typography variant='h4' sx={{fontWeight: 500, fontSize: {xs: '1.5rem', sm: '1.5rem', lg: '2rem'}}} mb={3}>Agents in { address?.residential ? address.residential : address?.suburb }</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={10} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <FormLabel sx={{color: '#fff'}}>LOCATION</FormLabel>
                                        <TextField className={classes.field} size='small' placeholder='Palo alto' fullWidth variant='outlined' value={loc} 
                                        InputProps={{
											endAdornment: <InputAdornment position='end'>
												{ load.location && <CircularProgress size={20} value={20} /> }
											</InputAdornment>
										}}
										onChange={(e)=> {
											setLoc(e.target.value)
											onFormSubmit({field: 'location', value: e.target.value}) 
										}} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <FormLabel sx={{color: '#fff'}}>NAME</FormLabel>
                                        <TextField className={classes.field} size='small' placeholder='Agent Name' fullWidth variant='outlined' value={name} 
										InputProps={{
											endAdornment: <InputAdornment position='end'>
												{ load.name && <CircularProgress size={20} value={20} /> }
											</InputAdornment>
										}}
                                        onChange={(e)=> { 
											setName(e.target.value)
											onFormSubmit({field: 'name', value: e.target.value}) 
										}} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <FormLabel sx={{color: '#fff'}}>SPECIALITIES</FormLabel>
                                        <Select sx={{marginTop: '10px'}} displayEmpty
										className={classes.field} size='small' placeholder='Buyer' 
										fullWidth variant='outlined' value={specs} 
										onChange={(e)=> {
											setSpecs(e.target.value)
											onFormSubmit({field: 'specs', value: e.target.value}) 
										}}>
										{
											specialties?.map((el,index) => {
												return (
													<MenuItem value={el} key={index}>{el === '' ? 'Select' : el}</MenuItem>
												)
											})
										}
										</Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <FormLabel sx={{color: '#fff'}}>LANGUAGE</FormLabel>
                                        <Select sx={{marginTop: '10px'}} 
										displayEmpty className={classes.field} size='small' 
										fullWidth variant='outlined' value={language} 
										onChange={(e)=> {
											setLanguage(e.target.value)
											onFormSubmit({field: 'language', value: e.target.value}) 
										}}>
											{
												languages?.map((el, index) => {
													return (
														<MenuItem value={el} key={index}>{el === '' ? 'Select' : el}</MenuItem>
													)
												})
											}
										</Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={2} lg={3} sx={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}}>
                                <Button onClick={onFormSubmit} fullWidth variant='outlined' sx={{color: '#fff', textTransform: 'none', height: '2.8rem', border: '1px solid #fff'}} >Filter</Button>
                            </Grid> */}
                            
                           
                        </Grid>
                    </Container>
                </Box>
        </Box>

			{/* Featured Agents */}
			<Box padding={'2rem 0'} >
				<Container maxWidth='lg'>
				
					<Box>
						<Typography id='feat' variant='h6' sx={{ margin: '2rem 0' }} textAlign='left'>
							FEATURED AGENTS
						</Typography>
							<Hidden smUp>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={4} lg={4}>
									{
										featuredAgents && 
										<FeaturedAgent
											id={featuredAgents[0]?.id}
											name={featuredAgents[0]?.user.first_name.toUpperCase() + ' ' + featuredAgents[0]?.user.last_name.toUpperCase()}
											phone={featuredAgents[0]?.phone}
											image={featuredAgents[0]?.profile_image}
											rating={featuredAgents[0]?.rating ? featuredAgents[0]?.rating : 5}
											border={true}
										/>

									}
									</Grid>	
								</Grid>
							</Hidden>

							<Hidden smDown>
								<Grid container spacing={2}>
									{ featuredAgents?.map((el) => {
										return (
											<Grid item xs={12} sm={6} md={4} lg={4} key={el.id} sx={{}}>
												<FeaturedAgent
													id={el.id}
													name={el.user.first_name.toUpperCase() + ' ' + el.user.last_name.toUpperCase()}
													phone={el.phone}
													image={el.profile_image}
													rating={el.rating ? el.rating : 5}
													border={true}
												/>
											</Grid>
										);
									})
									}
								</Grid>

							</Hidden>
					</Box>
			

					{/* AGENTS */}
					<Box marginTop={'4rem'} marginBottom='2rem'>
						<Grid container sx={{ textAlign: 'center' }}>
							<Grid item xs={12} sm={12} md={6} lg={4}>
								<Typography color={'secondary'}>AGENTS</Typography>
							</Grid>
							<Hidden smDown>
								<Grid item xs={12} sm={12} md={6} lg={8}>
									<Typography color={'secondary'}>LOCATION</Typography>
								</Grid>
							</Hidden>
							<Divider className={classes.divider} />
						</Grid>
					</Box>

					<Box>
                    { 
                        data && data.length > 0 ?
                        data.map((el, index) => {
                            return(
                                <Card key={index} variant='outlined' className={classes.card} onClick={(e)=> { e.stopPropagation(); window.open(`/agents/${el.id}`, '_blank') }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item sm={6} md={4} lg={4}>
                                                <FeaturedAgent
													id={el.id} 
													name={el.user.first_name.toUpperCase() + ' ' + el.user.last_name.toUpperCase()} 
													phone={el.phone} rating={5} image={el.profile_image} />
                                            </Grid>
											<Hidden smDown>
												<Grid item sm={6} md={6} lg={8}>
													<Box height={'100%'} display='flex' justifyContent={'center'} alignItems='center'>
														<Typography textAlign={'left'} noWrap paragraph>{el.brokerage_address ? el.brokerage_address : 'No address'}</Typography>
													</Box>
												</Grid>
											</Hidden>
                                            <Hidden mdUp>
                                                <Divider style={{margin: '1rem 0', background: grey[300]}}/>
                                            </Hidden>
											
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )
                        })
                        :
                        <Box className={classes.noList} >
                            <img src={ListAG} alt='no-listing' className={classes.listIcon} />
                            <Typography paragraph>No Agent List Found.</Typography>
                        </Box>
                    }
           </Box>

					{/* Pagination */}
					<Box marginBottom={'4rem'}>
						<Pagination
							size='medium'
							color='secondary'
							sx={{ display: 'flex', justifyContent: 'center' }}
							shape='rounded'
							variant='outlined'
							count={Math.ceil(pageCount / 10)}
							onChange={handleChange}
						/>
					</Box>

					{/* Feeds */}
					{/* <Feeds className={classes.feeds} /> */}
				</Container>
			</Box>

			{/* Loading Dialog */}
			<Dialog open={open}>
				<DialogContent sx={{ padding: '3rem', width: '20rem', textAlign: 'center' }}>
					<CircularProgress value={30} color='primary' />
					<Typography mt={2}>Loading...</Typography>
				</DialogContent>
			</Dialog>

			{/* Get User Location */}
			{/* <Dialog open={ loaded ? false : true } >
            <DialogContent sx={{padding: '3rem', width: '35rem', textAlign: 'center', position: 'relative'}}>
                <IconButton onClick={onGeoClose} style={{position: 'absolute', right: '3%', top: '3%', background: grey[200]}}><Close fontSize='small' /></IconButton>
                <img src={Pin} width={'35%'} alt='location'/>
                <Typography variant='h6' mt={2} gutterBottom>Know Your Location</Typography>
                <Typography variant='body1' color={'textSecondary'}>By enabling geolocation, we are able to provide <br />you well-filtered listing</Typography>
                <Button variant='contained' disabled={disable ? true : false} disableElevation color='secondary' sx={{textTransform: 'none', marginTop: '1rem', width: '10rem'}}
                onClick={getUserLocation}>{ disable ? 'Enabling...' : 'Enable Location' }</Button>
            </DialogContent>
        </Dialog> */}
        
        
		</div>
	);
};

export default AgentFinder;
