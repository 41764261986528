import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./InternPricing.css";
import stackIcon from "../../../assets/inverstors/icons/stackIcon.svg";
import powerICon from "../../../assets/inverstors/icons/powerIcon.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import base from "../../../api/apis";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const InternPricing = () => {
  const dispatch = useDispatch();
  const [packageAlreadySelected, setPackageAlreadySelected] = useState();
  const [packageType, setPackageType] = useState();

  const getPrices = async () => {
    try {
      const res = await base.get(
        "/register/list_prices/?requiring_type=yearly"
      );
      const packages = res.data[0]?.filter(
        (el) =>
          el.metadata?.product === "internship_program" &&
          el?.metadata?.recurring_type === "one_time"
      );

      setPackageType(packages);
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: "Sorry, could not fetch price lists",
      });
    }
  };

  useEffect(() => {
    getPrices();

    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component={Container}
      sx={{
        mt: 10,
      }}
    >
      <Elements stripe={stripePromise}>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "700",
            fontSize: "4rem",
            mb: 8,
          }}
        >
          Select a Plan
        </Typography>
        <Grid container gap={3} justifyContent={"center"}>
          {/* <Grid item sm={10} md={5}>
            <Typography color={"secondary"} fontWeight={500}>
              Get Started
            </Typography>
            <Typography fontWeight={500} fontSize={35}>
              Pricing plans that scale
            </Typography>
            <Typography fontWeight={400} color={"rgba(71, 84, 103, 1)"}>
              Simple, transparent pricing that grows with you.
            </Typography>
          </Grid> */}

          <Grid item sm={10} md={5} sx={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: -30,
                right: "45%",
              }}
            >
              <img src={powerICon} alt="power icon" style={{}} />
            </div>
            <Paper
              elevation={1}
              className="gradient_bg"
              sx={{
                py: 5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
              }}
            >
              <Typography fontWeight={600}>$79 Per Month</Typography>
              <Typography fontWeight={600} fontSize={50}>
                $
                {(
                  (packageType?.filter(
                    (el) => el.metadata?.package_grade === "pro"
                  )[0]?.unit_amount || 0) / 100
                ).toFixed(2)}
              </Typography>
              <Typography
                textAlign={"center"}
                fontWeight={400}
                my={2}
                color={"rgba(71, 84, 103, 1)"}
              >
                One time Set Up <br />
                Includes Licensing Classes
              </Typography>
              <Typography
                fontWeight={400}
                sx={{ display: "flex", gap: 1 }}
                color={"rgba(71, 84, 103, 1)"}
              >
                <CheckCircleOutlineIcon color="secondary" />
                <strong>$299.00</strong> Rebate at close
                <br />
              </Typography>
              <Typography
                fontWeight={400}
                sx={{ display: "flex" }}
                color={"rgba(71, 84, 103, 1)"}
              >
                of 1st Transaction
              </Typography>
              <StripePay
                setPackageAlreadySelected={setPackageAlreadySelected}
                packageAlreadySelected={packageAlreadySelected}
                termsAgreed={true}
                packageType={
                  packageType?.filter(
                    (el) => el.metadata?.package_grade === "pro"
                  )[0]
                }
                // setup={
                //   option === "realty_agent_basic" || option === "realty_agent_pro"
                //     ? setupFee[1]
                //     : setupFee[0]
                // }
              />
            </Paper>
          </Grid>
          <Grid item sm={10} md={5} sx={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: -30,
                right: "45%",
              }}
            >
              <img src={stackIcon} alt="power icon" style={{}} />
            </div>
            <Paper
              elevation={1}
              className="gradient_bg"
              sx={{
                py: 5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
              }}
            >
              <Typography fontWeight={600}>$79 Per Month</Typography>
              <Typography fontWeight={600} fontSize={50}>
                ${" "}
                {(
                  (packageType?.filter(
                    (el) => el.metadata?.package_grade === "basic"
                  )[0]?.unit_amount || 0) / 100
                ).toFixed(2) || "--"}
              </Typography>
              <Typography
                fontWeight={400}
                my={2}
                textAlign={"center"}
                color={"rgba(71, 84, 103, 1)"}
              >
                One time Set Up 
              </Typography>
              <Typography
                fontWeight={400}
                sx={{ display: "flex", gap: 1, textAlign: "center" }}
                color={"rgba(71, 84, 103, 1)"}
              >
                <CheckCircleOutlineIcon color="secondary" />
                Agents that previously paid <br />
                for classes
              </Typography>
              <StripePay
                setPackageAlreadySelected={setPackageAlreadySelected}
                packageAlreadySelected={packageAlreadySelected}
                termsAgreed={true}
                packageType={
                  packageType?.filter(
                    (el) => el.metadata?.package_grade === "basic"
                  )[0]
                }
              />
            </Paper>
          </Grid>
        </Grid>

        {/* <Container
          sx={{
            backgroundColor: "rgba(12, 17, 29, 1)",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: 4,
            gap: 3,
          }}
        >
          <Typography sx={{ color: "white", fontWeight: 600, fontSize: 35 }}>
            Still thinking about it?
          </Typography>
          <Typography sx={{ color: "white", fontWeight: 400, fontSize: 15 }}>
            Sign up for our newsletter and get first hand information on our
            services
          </Typography>
          <Box sx={{ display: "flex", gap: 3 }}>
            <div>
              <TextField
                variant="filled"
                sx={{
                  backgroundColor: "white",
                  borderRadius: 2,
                  width: " 15vw",
                }}
                placeholder="Enter your email"
              />
              <Typography
                sx={{ color: "white", fontWeight: 400, fontSize: 12 }}
              >
                We care about your data in our <span> privacy policy</span>
              </Typography>
            </div>
            <Button
              variant="contained"
              color="secondary"
              sx={{ textTransform: "none", width: "8vw", height: 55 }}
            >
              Subscribe
            </Button>
          </Box>
        </Container> */}
      </Elements>
    </Box>
  );
};

//------------------------------------------  Stripe payment ------------------------------------//

const StripePay = ({
  termsAgreed,
  addon,
  packageType,
  setup,
  packageAlreadySelected,
  setPackageAlreadySelected,
}) => {
  const currentUser = useSelector((state) => state.currentUser);

  const dispatch = useDispatch();
  const stripe = useStripe();

  useEffect(() => {
    (() => {
      if (packageAlreadySelected?.id && currentUser) {
        //body data
        const body = {
          price_data: [
            {
              price: packageAlreadySelected.id,
              quantity: 1,
            },
          ],
          cancel_url: window.location.href,
          success_url:
            window.location.origin + `/payment-success-intern?type=null&`,
          payment_method_types: ["card"],
          mode: "payment",
          package: "internship_program",
          is_internship: true,
        };

        try {
          base
            .post("/register/create_subscription/", body)
            .then(({ data: { sessionId } }) => {
              if (!sessionId) {
                dispatch({ type: "ERROR", payload: "Something went wrong" });
                return;
              }
              stripe.redirectToCheckout({ sessionId });
              setLoad(false);
            })
            .catch((err) => {
              console.log(err);
              dispatch({ type: "ERROR", payload: "Something went wrong" });
              setLoad(false);
            });
          setPackageAlreadySelected("");
        } catch (error) {
          setPackageAlreadySelected("");
          console.log(error);
          setLoad(false);
        }
      }
    })();
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageAlreadySelected, currentUser]);
  const [load, setLoad] = useState(false);
  const confirmPayment = () => {
    setPackageAlreadySelected(packageType);

    if (!currentUser) {
      dispatch({ type: "AUTH_LOGIN" });
      return;
    }
    if (!termsAgreed) {
      dispatch({
        type: "ERROR",
        payload: "Please agree to the terms and conditions",
      });
      return;
    }
    setLoad(true);
    setPackageAlreadySelected("");

    //body data
    const body = {
      price_data: [
        {
          price: packageType.id,
          quantity: 1,
        },
      ],
      cancel_url: window.location.href,
      success_url:
        window.location.origin + `/payment-success-intern?type=null&`,
      payment_method_types: ["card"],
      mode: "payment",
      package: "internship_program",
      is_internship: true,
    };

    try {
      base
        .post("/register/create_subscription/", body)
        .then(({ data: { sessionId } }) => {
          if (!sessionId) {
            dispatch({ type: "ERROR", payload: "Something went wrong" });
            return;
          }
          stripe.redirectToCheckout({ sessionId });
          setLoad(false);
        })
        .catch((err) => {
          setLoad(false);
          console.log(err);
          dispatch({ type: "ERROR", payload: "Something went wrong" });
        });
    } catch (error) {
      setLoad(false);
      console.log(error);
    } finally {
      setLoad(false);
    }
  };

  return (
    // <Elements stripe={stripePromise}>
    <LoadingButton
      loading={load}
      variant="contained"
      color="secondary"
      sx={{ textTransform: "none", mt: 3, width: "80%" }}
      onClick={confirmPayment}
    >
      Start Now
    </LoadingButton>
    // </Elements>
  );
};

//---------------xx---------------------------  Stripe payment ----------------------xx--------------//
