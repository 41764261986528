import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, Container, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { resetUserPassword } from '../actions';
import LOGO from '../assets/logo_2.png'

const ResetPassword = (props) => {
    const navigate = useNavigate()
    const { resetUserPassword } = props
    const dispatch = useDispatch()
	const { id, token } = useParams();
    const [load, setLoad] = useState(false)
    // console.log(id, token)
    const [pass, setPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')


    const onSubmit = () => {
        if(pass === '' || confirmPass === '') return
        if(pass !== confirmPass ) {
            dispatch({type: 'ERROR', payload: 'Passwords do not match'})
            return
        }
        if(pass.length < 8 || confirmPass.length < 8 ) {
            dispatch({type: 'ERROR', payload: 'Password must be at least 8 characters'})
            return
        }

        setLoad(true)
        setTimeout(async() => {
            await resetUserPassword(id, pass, token)
            setLoad(false)
        }, 2000);
    }

  return (
    <>
    {
        id && token ?
        <Container sx={{height: '80vh'}}>
                <Box sx={{width: '50%', m: '0 auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Card variant='outlined' sx={{borderRadius: '10px'}}>
                        <CardContent sx={{padding: '3rem', textAlign: 'center'}}>
                            {/* <IconButton sx={{bgcolor: grey[200], padding: '1.5rem', mb: 2}}><Lock sx={{fontSize: '3rem'}} /></IconButton> */}
                            <img src={LOGO} style={{width: '45%', cursor: 'pointer', marginBottom: '2rem'}} onClick={()=> navigate('/')} alt='logo' />
                            <Typography variant='h6' textAlign={'center'}>Password Reset</Typography>
                            <Typography variant='body2' textAlign={'center'} mb={3}>Provide your new password to update your account</Typography>
                            <TextField sx={{mb: '1rem'}} type='password' variant='outlined' value={pass} onChange={(e)=> setPass(e.target.value)} fullWidth placeholder='New Password' />
                            <TextField sx={{mb: '1rem'}} type='password' variant='outlined' value={confirmPass} onChange={(e)=> setConfirmPass(e.target.value)} fullWidth placeholder='Confirm Password' />
                            <LoadingButton 
                            variant='contained' onClick={onSubmit}
                            fullWidth loading={load} size='large'
                            disableElevation color='secondary' 
                            sx={{textTransform: 'none', mb: '2rem', height: '3.5rem'}}>Reset Password</LoadingButton>
                        </CardContent>
                    </Card>
                </Box>

        </Container>
        :
        <Navigate to={'/'} />
    }
    </>
  )
}


const mapStateToprops = state => state
export default connect(mapStateToprops, {resetUserPassword})(ResetPassword)