import { Foundation, KingBed, Shower, SquareFoot } from '@mui/icons-material'
import { Box, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import startCase from 'lodash/startCase'
import React from 'react'

const InvestorItem = ({searchTerm, address, type, owner, bed, bath, area, year, salePrice, saleDate, lastSeller}) => {
    console.log(searchTerm, lastSeller, owner)
  return (
    <Box p={'1.5rem'} border={`1px solid ${grey[400]}`} borderRadius={'15px'} mb={'1.5rem'}
        display={'flex'} gap={'2rem'} sx={{transition: 'all .2s ease-in', ':hover': { border: theme => `1px solid ${theme.palette.secondary.main}`}}}
        boxShadow={'0 2px 10px rgba(0,0,0, 0%)'}>
        {/* <Box bgcolor={grey[300]} height={'auto'} minWidth={'7rem'} borderRadius={'12px'} /> */}
        <Box width={'100%'}>
            <Typography mb={.5} sx={{fontSize: '1.2rem', fontWeight: 300}}>{address}</Typography>
            <Stack direction={'row'} gap={'.6rem'}  alignItems={'center'}>
                <Typography variant='body2' color={'GrayText'}>{startCase(type?.toLowerCase())}</Typography>
                <Divider flexItem orientation='vertical' />
                <Typography variant='body2' color={'GrayText'}>Sold by {lastSeller}</Typography>
                <Divider flexItem orientation='vertical' />
                <Typography variant='body2' color={'GrayText'}>Sold on {saleDate}</Typography>
            </Stack>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'flex-end'}>
                <Stack direction={'row'} gap={'1rem'}>
                        <Chip variant='outlined' label={
                            <Tooltip title='Beds' arrow>
                                <Stack direction={'row'} gap={'.2rem'}>
                                    <KingBed sx={{fontSize: '1.4rem'}} color='secondary' /> 
                                    <Typography variant='body2' color={'textPrimary'}>{bed || 0}</Typography>
                                </Stack>
                            </Tooltip>
                        } />
                        <Chip variant='outlined' label={
                            <Tooltip title='Baths' arrow>
                                <Stack direction={'row'} gap={'.2rem'}>
                                    <Shower sx={{fontSize: '1.4rem'}} color='secondary' /> 
                                    <Typography variant='body2' color={'textPrimary'}>{bath || 0}</Typography>
                                </Stack>
                            </Tooltip>
                        } />
                        <Chip variant='outlined' label={
                            <Tooltip title='Lot size' arrow>
                                <Stack direction={'row'} gap={'.2rem'}>
                                    <SquareFoot sx={{fontSize: '1.4rem'}} color='secondary' /> 
                                    <Typography variant='body2' color={'textPrimary'}>{area || 0} Sqft</Typography>
                                </Stack>
                            </Tooltip>
                        } />
                        {
                            !year ? null :
                            <Chip variant='outlined' label={
                                <Tooltip title='Year built' arrow>
                                    <Stack direction={'row'} gap={'.2rem'}>
                                        <Foundation sx={{fontSize: '1.4rem'}} color='secondary' /> 
                                        <Typography variant='body2' color={'textPrimary'}>{year}</Typography>
                                    </Stack>
                                </Tooltip>
                            } />

                        }
                        
                        
                </Stack>
                <Box ml={'auto'} textAlign={'right'}>
                    <Typography variant='body2' noWrap color={'GrayText'} sx={{fontSize: '.8rem'}}>Last Market Sale</Typography>
                    <Typography variant='h5'  noWrap>${salePrice?.toLocaleString()}</Typography>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default InvestorItem