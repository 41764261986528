import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import HomeBG from '../../assets/homeimprove.png'
import Check1 from '../../assets/checklist1.png'
import Check2 from '../../assets/explore.png'
import Check3 from '../../assets/sellerguide.png'
import { scrollUp } from '../../constants'
import SEO from '../../components/SEO'

const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${HomeBG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const HomeImprovement = () => {
    const classes = useStyles()
    const navigate = useNavigate()


  return (
    <div>
            <SEO>
				<title>2023 Home Remodeling and Renovation Trends</title>
				<meta name='og:title' content="2023 Home Remodeling and Renovation Trends" />
				<meta
					name='description'
					content="Upgrade your home with the latest home remodeling and renovation trends for 2023. From spa-like bathrooms to enhanced outdoor spaces and concealed storage, find the perfect inspiration for your next home improvement project."
				/>
				<meta
					name='og:description'
					content="Upgrade your home with the latest home remodeling and renovation trends for 2023. From spa-like bathrooms to enhanced outdoor spaces and concealed storage, find the perfect inspiration for your next home improvement project."
				/>
			</SEO>
        <Box className={classes.banner}></Box>
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
            <Typography textAlign={'center'} gutterBottom variant='h5'>Home Improvement and Remodeling Trends for 2023</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor}} />
            <Typography textAlign={'center'}>5 Leading Trends </Typography>
        </Box>
        <Box padding={'4rem 0'} bgcolor={grey[100]}>
            <Container>
                <Typography>Home remodeling and renovation can be a terrific method to raise the value of your home and give it the appearance you want. To assist you to understand which trends in home remodeling and renovation may be significant to you, we studied the top trends for 2022.</Typography>
            </Container>
        </Box>

        <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                    <Typography className={classes.title} variant='h6'>Remodel vs. Renovation</Typography>
                    <Typography paragraph mb={5}>If you want to sell your home quickly, the best month to do so is March, while the best month to do so is July. Based on past market trends, BPO Homes advises advertising your house for sale in March and no later than Labor Day.</Typography>

                    <Typography className={classes.title} variant='h6'>Which Month is Best for listing houses?</Typography>
                    <Typography paragraph mb={5}>The terms "renovation" and "remodel" are frequently used interchangeably. However, they mean two completely distinct things. To obtain accurate estimates and the best rate/terms on your loan, it is crucial to have clear terms when speaking with a contractor or mortgage lender.Whether you're building something new determines whether it's a renovation or a remodel. A renovation is the process of modernizing or restoring space in a house. It will still have the same capabilities but have a new appearance. A redesign implies that in addition to changing the design of a space, you may also completely alter how it functions.Both choices alter the appearance and atmosphere of your house and may even raise its market price. There are numerous methods to pay for remodeling and renovations, but how much do they cost?
                    </Typography>

                    <Typography className={classes.title} variant='h6'>What Are the Prices for Remodeling and Renovations?</Typography>
                    <Typography paragraph mb={5}>Costs for home remodeling and renovation projects vary depending on your location, the size of the project, and your schedule. To determine the potential cost of your project and to get a sense of the average cost in your area, it is best to compare quotes from various businesses.Before you start remodeling or renovating your house, check about and compare costs from reputable local contractors. Make sure the contractor you select has experience with the type of improvements you require, can work within your budget, and can complete the project on schedule.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>5 Leading Home Remodeling And Renovation Trends For 2022</Typography>
                    <Typography paragraph mb={5}>Every year, new remodeling and home improvement trends emerge. While some trends last for some time, others come and go faster than you can say "remodel." Here are the top 8 remodeling and improvement projects for homes in 2022.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>1. Spa Restroom</Typography>
                    <Typography paragraph mb={5}>Your bathroom renovation gives you a tranquil space where you may unwind and relax after a hard day. Consider a bathroom with a big bathtub, a stand-up shower, dim lighting, and soothing colors, whether you're spending more time at home or just want a quiet place to think for a bit. For the bathroom to be truly relaxing, add some natural elements, and don't forget the scented candles. If space is at a premium in your bathroom, consider installing built-in cupboards or shelves to maintain a tidy and serene atmosphere. To create the peaceful environment you want, you can have all of your essentials while keeping them hidden.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Listing Your House in Summer</Typography>
                    <Typography paragraph mb={5}>Warm weather, school breaks, and simply having more daylight hours to visit open houses and take home tours are many of the same factors that influence buyers' decision-making when they browse for a home in the spring. The summer season is when most people take their vacations, so that may be the cause of the slight slowdown. Additionally, some regions of the country experience a decline in real estate activity during the summer since it is simply too hot to go shopping.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>2. Enhanced Outdoor Spaces</Typography>
                    <Typography paragraph mb={5}>Making your outdoor space look like your living room is currently popular. Consider how you might bring the conveniences of your living room outside. Consider spacious seating spaces with couches and tables, lovely carpets, sun-shading pergolas, and perhaps even a fireplace or TV for cozy outside evenings. When the weather is great, several houses even install large dining tables outside where they host gatherings. Even in inclement weather, a pergola can increase the usage of your outside space.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>3. Vibrant Wallpaper</Typography>
                    <Typography paragraph>Thought to be a thing of the 1970s, wallpaper has recently become popular again. Trendsetters concluded that vibrant wallpaper, at least on one wall, is the way to go after years of hard painting. Wallpaper is far simpler and quicker to install than painting, in addition to being more visually appealing.
                    </Typography>
                    <Typography paragraph mb={5}>Any area may benefit from wallpaper, which is a terrific way to decorate on a budget and display the personality or style you desire in the room. Of course, you might not want to cover the entire wall in wallpaper, but the current trend is to add some "fun" to one accent wall.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>4. Big Window Banks</Typography>
                    <Typography paragraph>There is nothing better than natural light in a house, and huge window banks are the greatest way to achieve it. Large windows have the power to completely change a room, whether you add windows to many walls in your house or create a floor-to-ceiling window wall that serves as the area's focal point.
                    </Typography>
                    <Typography paragraph mb={5}>Natural light not only enhances the beauty of your home but also lowers your utility costs. Therefore, in addition to saving money, you will also help the environment by reducing your carbon impact.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>5. Concealed Storage</Typography>
                    <Typography paragraph mb={5}>You will require a lot of storage, even if you lead a simple lifestyle. Built-in storage is currently popular since it allows you to conceal your essentials while maintaining a modern aesthetic for your home. Think pull-out shelves, cubbies in mudrooms, bathroom hooks and cubbies to finagle all the wires and accessories, and attractive open shelving for bedrooms and bathrooms for design and storage.Built-in storage can be seen or concealed when closed. You may use your walls to have the storage you need without taking up a lot of space, regardless of the style you choose for your home.
                    </Typography>
                    <Typography paragraph mb={5}>Home remodeling and renovation can help raise a property's value. While there is no assurance that the value of your property will rise, some home improvements and remodels can dramatically raise a home's value.
                    </Typography>
                    
                </Box>
            </Container>
        </Box>

        <Box padding={'5rem 0'} sx={{background: (theme) => theme.secondaryColor, color: '#fff'}}>
            <Container>
                <Typography variant='h6' textAlign={'center'}>Check out your refinancing alternatives if you need to raise funds to remodel or renovate your home to take advantage of the equity in your house.</Typography>

            </Container>
        </Box>

            <Box mt={4} mb={5}>
            <Container>
                    <Typography mb={3} mt={3}>To prepare for a sale, you should plan to set aside at least two months. This will give you time to investigate the local market, select a trustworthy agent, properly clean your house, and finish any little repairs.
                    </Typography>
                    <Typography variant='h6' mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/sellers-guide')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' mb={3.5} color={'textSecondary'}>Seller's Guide</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/explore')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' mb={3.5} color={'textSecondary'}>Explore My Options</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/bpo-guide')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Why Buyer Needs A Broker's price Opinion</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>
    </div>
  )
}

export default HomeImprovement