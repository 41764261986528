import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import '../agents.css'
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
export const TextComponent = ({ headerColor, alignment, subScript, mainScript, bodyColor, btnWidth, onClick }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const routeToPage = (e, link) => {
        e.stopPropagation();
        navigate(link);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : alignment || 'center', }}>
                <Typography style={{
                    color: headerColor || 'black',
                    textAlign: isMobile ? 'center' : alignment || 'center',
                    fontWeight: 700,
                    fontSize: isMobile ? '2rem' : '3rem',
                    marginBottom: '1rem'
                }}>
                    {mainScript}
                </Typography>
                <Typography textAlign={isMobile ? 'center' : alignment || 'center'} color={bodyColor || 'black'}>

                    {subScript}
                </Typography>
                <Box display={'flex'} my='2rem' gap={'2rem'} flexDirection={isMobile ? 'column' : 'row'} >
                    <TransparentBtn withIcon={false} onClick={(e) => routeToPage(e, '/join')} btnWidth={btnWidth} />
                    {!btnWidth && <TransparentBtn bgColor={'rgba(255, 255,255, 0.6)'} borderColor={'#E5e9ed'} variant={'outlined'} onClick={onClick} text={'Learn More'} btnColor={'black'} />}
                </Box>
            </Box>
        </div>
    )
}




export const TransparentBtn = (
    {
        btnColor,
        bgColor,
        btnWidth,
        withIcon = true,
        text, borderColor,
        variant, onClick
    }) => {

    return (
        <Button
            onClick={onClick}
            className='glassBtn'
            variant={variant || 'contained'}
            endIcon={withIcon && <OpenInNewIcon />}
            sx={{

                textTransform: 'none',
                color: btnColor || 'white',
                backgroundColor: bgColor || 'rgba(255,169,3,0.5)',

                border: 'solid',
                borderColor: borderColor || 'rgba(255,169,3,1)',
                borderRadius: 15,
                width: btnWidth || '15rem'

            }}>
            {text || 'Join Now'}
        </Button>)
}