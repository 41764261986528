import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IconButton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import checkIcon from "../../../assets/inverstors/icons/checkIcon.svg";
import { TransparentBtn } from "./TextComponent";
import { useNavigate } from "react-router-dom";
export default function TemporaryDrawer({
  open,
  setOpen,
  callerScript,
  anchor,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const routeToPage = (e, link) => {
    e.stopPropagation();
    navigate(link);
  };
  const DrawerList = (
    <Box
      sx={{
        width: isMobile ? "auto" : 500,
        position: "relative",
      }}
      role="presentation"
    >
      <IconButton
        onClick={toggleDrawer(false)}
        sx={{ position: "absolute", right: "5%" }}
      >
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center ",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography fontWeight={"700"} fontSize={"1.8rem"} mb={3}>
            {callerScript?.heading}
          </Typography>
          <Typography>
            {callerScript?.body?.map((k, index) => (
              <Typography
                sx={{ display: "flex", alignItems: "start", gap: 2, mb: 2 }}
                key={index}
              >
                <img src={checkIcon} alt="check Icon" />
                <Box>{k}</Box>
              </Typography>
            ))}
          </Typography>
        </Box>
        <TransparentBtn
          onClick={(e) => routeToPage(e, "/join")}
          btnWidth={"25rem"}
        />
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={open}
        anchor={isMobile ? "bottom" : anchor || "right"}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
