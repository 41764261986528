import { Box, Button, Divider, Grid, Typography, Container, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import React from 'react'
import PartnerBG from '../../assets/premierpartner.png'
import PPA from '../../assets/PPA.png'
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux'
import { buyerFee } from '../../constants/buyerFees'
import SEO from '../../components/SEO'
import { useNavigate } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
    banner: {
        textAlign: 'center',
        height: '70vh',
        backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 40%), rgba(0,0,0, 40%)), url(${PartnerBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    wrapLeft: {
        height: '50vh',
        backgroundImage: `url(${PPA})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
}))

const PartnerPremier = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const columns = [
        { field: 'Market', headerName: 'Market', flex: 1 },
        { field: 'Listing Fee', headerName: 'Listing Fee', flex: 1},
        { field: 'Minimum Commision', headerName: 'Minimum Commission', flex: 1},
    ]


  return (
    <div>
            <SEO>
				<title>BPO Homes Partner Agent Program | 1% Listing Fee and Closing Credits</title>
				<meta name='og:title' content='BPO Homes Partner Agent Program | 1% Listing Fee and Closing Credits' />
				<meta
					name='description'
					content='Join the BPO Homes Partner Agent Program and save with a 1% listing fee and closing cost credits. Keep your business competitive with city and state minimum commission requirements. Find an enrolled agent today.'
				/>
				<meta
					name='og:description'
					content='Join the BPO Homes Partner Agent Program and save with a 1% listing fee and closing cost credits. Keep your business competitive with city and state minimum commission requirements. Find an enrolled agent today.'
				/>
			</SEO>
            <Box className={classes.banner}>
                <Stack>
                    <Typography variant='h5' mb={5} sx={{fontWeight: 600}}>!! BUYER & SELLER CLOSING CREDITS !!</Typography>
                    <Typography variant='h1' sx={{fontWeight: 600}} mb={3}>1%</Typography>
                    <Typography variant='h4' sx={{fontWeight: 600}}>LISTING FEE</Typography>
                </Stack>
            </Box>
        <Box>
            <Container>
                <Box textAlign={'center'} padding={'3rem 0'}>
                    <Typography  mb={4} mt={4}>Partner Agent is a BPO Homes Platform branding Agents online to assist thier business growth. As a Real Estate professional you need to keep up with constant changes in order to keep your head above water. At BPO Homes, we understand what it takes to market Agents and keep them competitive.</Typography>
                    <Button disableElevation variant='contained' color='primary' sx={{width: '12rem', color: '#fff', textTransform: 'none'}} onClick={() => navigate('/partners/benefits')}>Learn More</Button>
                </Box>
            </Container>
        </Box>

        <Box padding={'3rem 0'} bgcolor={grey[100]}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item sm={6}>
                        <Typography>1.5%  listing fee with 0.5% credit payable by Agent at next closing if Seller makes a new purchase with Partner Premier Agent within the next 365 days from the previous close of escrow</Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography>0.00255% Buyer closing cost credit applies to Buyer Agent leads (Does not apply to buyers receiving 0.5% closing credit from previous listing sale)</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography textAlign='center' color={'secondary'} variant='body2'>See City by City & State By State minimum commission requirements below</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>

        <Box textAlign={'center'} padding='3rem 0'>
            <Container>
                <Typography sx={{width: '80%', margin: '2rem auto'}}>All Agents are not required to offer closing cost credits in order to potentially qualify for closing cost credit. To increase your chances for closing credit sign up</Typography>
                <Button disableElevation variant='outlined' color='secondary' sx={{width: '12rem', textTransform: 'none'}} onClick={()=> dispatch({type: 'AUTH_LOGIN'})}>Sign Up</Button>
            </Container>
        </Box>

        <Grid container>
            <Grid item sm={5} className={classes.wrapLeft}></Grid>
            <Grid item sm={7} sx={
                            {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    padding: '4rem',
                    paddingRight: '10rem',
                    background: '#EAF8FF',
                }
                }>
                <Typography paragraph>
                Agents enrolled with BPO Homes Partner Agent Program are required to offer closing cost credits only if such Buyers or Sellers have previously enrolled for the closing cost credit program, and have been introduced to a BPO Homes Agent or Partner Agent prior to any previous engagement between the parties.
                Ask your Agent if they are enrolled in our closing cost credit program or find an Agent on our Agent Finder page link below.
                </Typography>
                <Typography paragraph>Ask your Agent if they are enrolled in our closing cost credit program or find an agent on our Agent Finder page.</Typography>
                <Button disableElevation variant='contained' onClick={() => navigate('/agents')} color='secondary' sx={{width: '15rem', textTransform: 'none', marginTop: '2rem'}}>Find An Agent Now</Button>
            </Grid>
        </Grid>

        <Box padding={'4rem 0'}>
            <Container>
                <Typography mt={3} variant='h5' sx={{fontWeight: 600}} textAlign={'center'}>Commission Savings Disclaimers</Typography>
                <Divider sx={{
                        background: theme => theme.primaryColor,
                        height: '5px',
                        border: 'none',
                        width: '30%',
                        margin: '1rem auto 3rem auto'
                    }} />

                <Grid container spacing={4}>
                    <Grid item sm={4}>
                        <Typography sx={{fontWeight: 600}} variant='h6'>BPO Homes Realty Buyer's Agent Fee</Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <Typography>Buyer’s agent fee subject to change. Minimum buyer’s agent commission must exceed 1% of sale price or $6,500 (whichever is greater) before the deal is eligible for the BPO Homes Refund when available.</Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Typography sx={{fontWeight: 600}} variant='h6'>BPO Homes Realty Listing Fee</Typography>
                    </Grid>
                    <Grid item sm={8}>
                        <Typography paragraph>Listing fee subject to market-based minimums, as outlined below. Buyer’s agent fees are not included. For example, if the buyer’s agent fee is 2.5%, a seller who pays a 1.5% listing fee will pay a total fee of 4%. Listing fee to be decreased by 1% of sale price if buyer is unrepresented and BPO Homes Realty or Partner Agent acts Dual Agent/Broker. Listing fee and minimums subject to change.
                        </Typography>
                        <Typography paragraph>Sell for a 1% listing fee only if you also buy with BPO Homes within 365 days of closing on your BPO Homes or Partner Agents listing. If you sell first, BPO Homes Realty or Partner Agents will initially charge a 1.5% listing fee and receive a closing cost credit from Partner Agent of 0.5% difference after you buy your next home with BPO Homes Realty or your Partner Agent.</Typography>
                    </Grid>
                </Grid>

                <Box padding={'4rem 0'}>
                    <Typography textAlign={'center'} variant='h5' sx={{fontWeight: 600}} mb={4}>BPO Homes Realty Buyer’s Agent Fee</Typography>
                    <DataGrid rows={ buyerFee ? buyerFee : [] }
                        autoHeight
                        rowHeight={80}
                        rowBuffer={5}
                        headerHeight={60}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 20]}
                        onPageSizeChange={(newVal) => setRowsPerPage(newVal)}
                    />
                    <Typography mt={4} variant='body2' color='textSecondary'>Listing fee will be reduced by 0.5% for sellers who also buy with a BPO Home Realty Agent or Partner Premier Agent within 365 days of the closing on their sale of. If you sell first, BPO Homes Realty or Partner Agents will initially charge the listing fee listed below and then provide closing credit of 0.5% difference when you buy your next home with BPO Homes or Partner Agents payable in Escrow at closing . </Typography>
                </Box>
            </Container>
        </Box>

        {/* Disclaimer */}
        <Box bgcolor={'#F9F9FB'} padding='3rem 0'>
            <Container>
                <Typography variant='body2' color='textSecondary'><b style={{color:'#000'}}>DISCLAIMER:</b> BPO Homes Realty or Partner Agents Refund is subject to adjustment or cancellation if the commission received by BPO Homes from the cooperating broker is 1% or less of the property sale price or $6,500 (whichever is greater), if the commission amount provided to BPO Homes or Partner Agents changes after the signing of a buyer's agency agreement must be shared with additional parties, if the subject home is purchased as a short sale, or if  BPO Homes or Partner Agents  is prohibited from distributing the BPO Homes Refund. Lender approval may be required for commission refunds. In some circumstances buyer’s lender or other parties to the transaction may not allow buyer to receive a refund and BPO Homes or Partner Agents cannot guarantee that all lenders will allow 100% distribution of the BPO Homes or Partner Agents Refund. As a result, BPO Homes & Partner Agents strongly recommend that buyers discuss with their lenders and other parties to the transaction, in advance, the anticipated receipt of the BPO Homes or Partner Agent Refund. Unless agreed to in writing by BPO Homes Realty or Partner Agents, will only issue a refund to the person(s) and/or entity listed on the settlement statement at the closing of a transaction, regardless of whether such person(s) and/or entity are the buyer. Subject to these limitations and where allowed, the BPO Homes or Partner Agents  Refund can be provided at or after closing. For safety we strongly recommend requesting any refunds to be paid at closing in escrow. Buyers & Sellers hold BPO Homes harmless if Partner Agent does not provide closing cost credit outside of Escrow if and where legal and applicable.</Typography>
            </Container>
        </Box>



    </div>
  )
}

export default PartnerPremier