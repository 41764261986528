import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import SEO from '../components/SEO';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '60vh',
		padding: '4rem 8rem',
		scrollMarginTop: '7rem',
		[theme.breakpoints.down('md')]: {
			padding: '20px',
		},
	},
	content: {
		textAlign: 'justify',
		fontWeight: 300,
		scrollMarginTop: '7rem',
	},
}));

const headers = [
	{
		id: 'premier-agent-website',
		name: 'BPO Homes Premier Agent',
		subs: [
			{
				content:
					'BPO Homes Premier Agent is a subscription service & an exclusive platform for Real Estate Brokers & Agents to advertise Real Estate Services on BPOHomes.com. BPO Premier Agent is a web based service application.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc Data and all personally uploaded Data by BPO Homes Premier Agent subscribers may vary. BPO Homes does not guarantee the final outcome of any leads generated or the quality of the leads from such Data or marketing services.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc reserves the right to remove, Listings, photos and or video or descriptions uploaded by BPO Homes Premier Agent subscribers if deemed inappropriate by BPO Homes Inc or upon homeowners request if requested by Home owner to be removed from the BPO Homes website. BPO Homes Inc will inform BPO Premier Agent subscribers of such requests by Homeowners if and when applicable.',
				render: false,
			},
			{
				content:
					'The BPO Homes Premier Agent platform is strictly meant to assist Real Estate Agents and Brokers to generate business leads to obtain business from Buyers & Sellers.',
				render: false,
			},
			{
				content:
					'BPO Premier Agents will be advertised at BPO Homes Inc discretion, which any such advertisement may vary from time to time. BPO Homes does not guarantee any leads, types of leads, ads and or sales from any such advertisement on BPOHomes.com, or any other SocialMedia sites and or 3rd party affiliated sites.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc has developed a proprietary platform for BPO Homes Premier Agent to assist Agents and Brokers managing leads but in no way does BPO Homes Inc, BPOHomes.com orany of BPO Homes representatives manage the follow up of such leads provided by BPO Homes Inc. Follow up of leads are to be handled strictly by BPO Homes Premier Agent subscribers.',
				render: false,
			},
			{
				content:
					'All leads provided by BPO Homes Inc to BPO Homes Premier Agents may or not be exclusive leads provided from BPO Homes Inc. BPO Homes may distribute or redistribute leads as needed.',
				render: false,
			},
			{
				content:
					'BPO Homes Premier Agents are not intended to be the sole reason why consumers , Buyer and or Sellers make the consideration or final decision to Buy or sell their property. Buyers, Sellers and or consumers are fully responsible to make the final ultimate decision to Buy or Sell Real Estate or whom they choose to assist them with the acquisition or sale of their home.',
				render: false,
			},
			{
				content: 'BPO Homes Premier Agent subscribers will be advertised as Active Listing Report Agents,Listing Agents, Seller & Buyer Agents.',
				render: false,
			},
			{
				content:
					'BPO Homes Premier Agent subscribers will be requested from time to time to perform BPO Reports for BPOHomes.com. BPO Homes Premier Agent subscribers will be compensated with an account credit from BPO Homes in the amount of $100.00 per BPO Report created by BPO Premier Agent subscriber. BPO report compensation rates may vary from state to state and are subject to change at any time. BPO Homes Inc are to notify BPO Premier Agent of any rate changes via email notification if and when applicable.',
				render: false,
			},
			{
				content:
					'By subscribing to BPO Homes Premier Agent you agree to update and or upload the BPO Homes Website with all of your public Real Estate Listing information which will include any and all public Listing information that Realtor or Broker has previously published directly or indirectly to the web. Realtor and or Broker Listing information or data may be uploaded directly from Realtor and or Broker. BPO Homes Inc may directly upload Listing information if BPO Homes owns the Listing data directly published by BPO Homes Inc.Listing information found publicly will be the only information uploaded by BPOHomes.com unless otherwise privately provided from Realtor and or Broker directly. Listing info will include and not be limited to Broker and or Realtor property photos, property descriptions or any other Listing information available which may be found on various public 3rd party sites which Agent previously uploaded to directly or indirectly. This info may have been originally distributed through or from an MLS opt-in service, MLS API or IDX service and or any other feed or service. Broker & or Realtor by subscribing must notify BPO Homes Inc if there is any copyright infringement in regards to the Broker or Agent not having the sole right of ownership of Listing Info for distribution to BPOHomes.com prior to any Broker and or Realtor Listing info uploads to BPOHomes.com.',
				render: false,
			},
			{
				content:
					'Some Listing information posted on BPOHomes.com has been provided by 3rd public data companies or provided directly by Listing Brokers, Realtors, Agents directly to the BPOHomes.com website. As BPO Homes Premier Agents, by this agreement & by submitting the acceptance of this disclaimer to BPOHomes.com, BPO Homes Broker & Realtor subscribers have fully authorized BPO Homes Inc to post public their listing info and made claim that they have sole rights and ownership to the Listing info uploaded to BPO Homes Inc and on any other public 3rd party sites. Brokers & Realtor subscribers have made the claim that all photos, videos, and property descriptions pertaining to such listings have been designated by Listing Agent to not be copyright protected by any 3rd parties and Brokers & Realtors release any and all claims against BPO Homes Inc & BPO Homes.com for any posting of such Listing information. BPO Homes Premier Agents confirm that any and all 3rd party listing info providers also release all claims against BPO Homes Inc & BPO Homes.com for any posting of such Listing information.',
				render: false,
			},
			{
				content: 'BPO Homes Premier Agent will receive the exclusive leads to potential Buyer or Seller who requested the BPO reports.',
				render: false,
			},
			{
				content:
					'BPO Premier Agent has 6 hours from the time of request to respond to BPO Report requests from BPO Homes Inc and 24 hours to submit final BPO Report upon approval of such request.',
				render: false,
			},
			{
				content:
					'Upon no response within the allotted time to BPO report request. BPO Premier Agent will lose the opportunity to submit a report, earn compensation, or receive lead from that BPO Report request.',
				render: false,
			},
			{
				content:
					'BPO Homes Premier Agent will provide an independent login to BPOHomes.com Premier Agent Platform. BPO Premier Agent will be required to use the BPO Report Agent platform to create BPO Reports.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc will receive compensation directly from consumers for BPO Report orders completed by BPO Premier Agent and BPO Report Agent subscribers.',
				render: false,
			},
			{
				content:
					'BPO Homes Homes Premier Agent will provide an independent login to the BPOHomes.com BPO Homes Premier Agent Platform. BPO Homes Premier Agent will be required to use the BPO Homes Premier Agent & BPO Report Agent platforms to create BPO Reports.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc will receive compensation directly from consumers for BPO Report orders completed by BPO Homes Premier Agent subscribers.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc is required to issue a 1099 for payments made to BPO Homes Premier Agent of $600 or more for BPO Reports performed within the calendar year.',
				render: false,
			},
			{
				content:
					'BPO Premier Agents may request compensation in lieu of account credit. BPO Homes Inc is required to issue a 1099 for payments made to BPO Homes Premier Agent of $600 or more for BPO Reports performed within that calendar year. All BPO Premier Agent payments must be paid directly to the Broker Office of such Real Estate Agent. Realtor payments may be subject to commission split & split amounts may vary by Broker and any split amounts are strictly between BPO Premier Agent Realtors, Broker & Broker offices.',
				render: false,
			},
			{
				content:
					'BPO Homes Premier Agent subscribers are independent and operate independent of BPO Homes Inc & or BPOHomes.com not employees of the BPO Homes Inc or BPOHomes.com',
				render: false,
			},
			{
				content:
					'BPO Homes Premier Agent subscribers must hold an active Real Estate license in the state in which subscribers are performing such BPO Reports.',
				render: false,
			},
			{
				content:
					'BPO Homes may change Terms of this Disclaimer may change at any time and consumer, Broker & or Realtor subscribers may request the most updated Disclaimer upon their request.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc, BPOHomes.com, is not to be held liable by BPO Premier Agent subscribers for any consumer lead discrepancies, website, web page, or Ads Created for BPO Homes Premier Agent that are not to Agents liking.',
				render: false,
			},
			{
				content:
					'Electronic signatures acquired by users for policies, services, subscriptions and any other applicable documentation signed by the user for BPO Homes Inc the documents and all terms of use are fully valid and enforceable in this agreement.',
				render: false,
			},
		],
	},
	{
		name: 'BPO Tech Deal Analyzer Proforma',
		id: 'deal-analyzer',
		subs: [
			{
				content:
					'BPO Tech Deal Analyzer Proformas are created independently by Real Estate professionals & Consumers. The BPO Tech Deal Analyzer is a web based and non web based application that allows for the creation of a Proforma report that indicates the potential ROI (Return on Investment) of a property for the user or users clients. ',
				render: false,
			},
			{
				content:
					"Proformas are created with data provided from BPO Tech Inc and Data personally uploaded by users subscribed to the BPO Tech Deal Analyzer platform and or application. Users' results may vary and proformas do not guarantee the final outcome of an investment nor market stability as markets can change from day to day, month to month and year to year. ",
				render: false,
			},
			{
				content:
					"The BPO Tech Deal Analyzer is strictly meant to assist consumers, Investors Buyers, & Sellers to make sound decisions on Home Acquisitions, Home Evaluation, Home Improvement and or for the resale of property. BPO's in the Real Estate industry have been primarily used by Lenders or Banks in the Mortgage industry. There may be different Real Estate standards for the creation of BPO Reports. ",
				render: false,
			},
			{
				content:
					'BPO Tech Inc has developed a proprietary platform to assist Consumers, Buyers, Sellers, Realtors and or Brokers with Real Estate Data in order to create BPO Proformas but in no way does BPO Tech Inc or BPOHomes.com provide anyone with an opinion of value. The final proforma opinion of ROI ( return on investment ) is strictly based on the data entry of the subscriber or user.',
				render: false,
			},
			{
				content:
					'All Data provided by BPO Tech Inc to consumers to help with creation of BPO Tech Inc Proformas may or may not be deemed accurate. Data provided by BPO Tech Inc or BPOHomes.com to Realtors is provided from 3rd party sources.',
				render: false,
			},
			{
				content:
					'BPO Tech Proformas are not intended to be the sole reason why a consumer or Buyer makes a home purchase, improve or not improve, demolish a property. BPO Tech Proformas are not intended to be the sole reason why a consumer or Seller makes the consideration or final decision to sell their property. Buyers, Sellers and or consumers are fully responsible to make the final ultimate decision to Buy or Sell Real Estate.',
				render: false,
			},
			{
				content:
					'BPO Tech Inc, BPOHomes.com, Realtors, and Brokers are not to be held liable by Buyers, Sellers or Consumers for creation of BPO Tech Deal Analyzer Proformas, BPO Tech Proforma Data, BPO Tech Proforma Content, or by BPO Tech Proforma Values',
				render: false,
			},
		],
	},
	{
		name: 'BPO Tech Report',
		id: 'bpo-report',
		subs: [
			{
				content:
					"BPO Homes Inc Reports are created by 3rd party Licensed Real Estate professionals. BPO's and are representative of (Broker Price Opinions). ",
				render: false,
			},
			{
				content:
					"BPO's are Real Estate strictly professional opinions. Realtors and or Brokers use and are not limited to use Real Estate Data, Electronic Real Estate Data, Market Analysis, Neighborhood Analysis, Market Insight, Personal Intuitions, and Non Personal Intuitions to provide an opinion of value of Real Estate property and the desirability of subject property within a particular Real Estate market at a particular time. ",
				render: false,
			},
			{
				content:
					'BPO Reports are meant to assist consumers, Buyers, Sellers to make sound decisions on home acquisitions and or for the resale of property. ',
				render: false,
			},
			{
				content:
					"BPO's & BPO Reports in the Real Estate industry have been primarily used by Lenders or Banks in the Mortgage industry. There may be different Real Estate standards for the creation of such BPO Reports. BPO Homes Inc has its own standard form for delivery of BPO Reports. This form could change by BPO Homes Inc or BPO Tech at any given time. BPO Homes does not follow any government guidelines for BPO Report creation in which no such guidelines currently exist.",
				render: false,
			},
			{
				content:
					'BPO Homes Inc has developed a proprietary platform to assist Realtors and or Brokers with Real Estate Data in order to create BPO Reports but in no way does BPO Homes Inc or BPOHomes.com or BPO Tech nor BPOTech.io provide Realtors and or Brokers with an opinion of value. Realtors and or Brokers opinions of value are strictly provided only by Realtors and Brokers.',
				render: false,
			},
			{
				content:
					'All Data provided by BPO Homes Inc & BPO Tech to assist Realtors and Brokers for the creation of BPO Homes Inc Reports may or may not be deemed accurate. Data provided by BPO Homes Inc or BPOHomes.com to Realtors and Brokers is provided from 3rd party resources.',
				render: false,
			},
			{
				content:
					'BPO Homes Reports are not intended to be the sole reason why a consumer or Buyer makes a home purchase or Seller makes the consideration or final decision to sell their property. Buyers, Sellers and or consumers are fully responsible to make the ultimate final decision as to whether or not to Buy or Sell Real Estate.',
				render: false,
			},
			{
				content:
					'Electronic signatures acquired by users for policies, services, subscriptions and any other applicable documentation signed by the user for BPO Homes In.c The documents and all terms of use are fully valid and enforceable in this & other disclaimers and or agreements.',
				render: false,
			},
			{
				content:
					'BPO Homes Inc, BPOHomes.com, Realtors, and Brokers are not to be held liable by Buyers, Sellers or Consumers for creation of BPO Reports, BPO Report Data, BPO Report Content, BPO Report Values and agree or have agreed to all terms of this BPO Reports Disclaimer.',
				render: false,
			},
			{
				content:
					'The suggested market value & suggested list price indicated by such Agent who provided report is only an opinion of value at the date and time of the BPO Report and BPO Homes suggest you look at other valuations that may be provided in comparison such as but not limited to CMA’s, Appraisals, and others if necessary prior to making any Real Estate decisions.',
				render: false,
			},
			{
				content:
					'By requesting, performing & checking the box at checkout Seller, Buyers, Brokers and or Agent Agree to the Terms of this disclaimer.',
				render: false,
			},
		],
	},
	
];

function Disclaimer() {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<SEO>
				<title>BPO Homes Disclaimer</title>
				<meta name='og:title' content='BPO Homes Disclaimer' />
				<meta
					name='description'
					content='Read the official disclaimer for BPO Homes, covering all liabilities, warranties, and information provided on our website. Stay informed and protected.'
				/>
				<meta
					name='og:description'
					content='Read the official disclaimer for BPO Homes, covering all liabilities, warranties, and information provided on our website. Stay informed and protected.'
				/>
			</SEO>
			<Typography variant='h1' fontSize='35px' textAlign='center' fontWeight='700' mb='2rem'>
				BPO Homes Products Disclaimer
			</Typography>
			<Typography paragraph className={classes.content}></Typography>
			{headers.map((header, index) => (
				<div id={header.id} key={index} style={{ scrollMarginTop: '100px' }}>
					<Typography mt={3} variant='h2' sx={{ fontSize: '20px', fontWeight: 700, marginBottom: '1rem' }}>
						{index + 1}. {header.name}
					</Typography>
					{/* <Typography variant='h2' fontSize='20px' fontWeight='700' mb='1rem' mt='1rem'>
						{index + 1}. {header.name}
					</Typography> */}
					{header.subs.map((sub, index) =>
						sub.render ? (
							<Typography key={index} paragraph className={classes.content} dangerouslySetInnerHTML={{ __html: sub.content }}></Typography>
						) : (
							<Typography key={index} paragraph className={classes.content}>
								{sub.content}
							</Typography>
						)
					)}
				</div>
			))}
		</Box>
	);
}

export default Disclaimer;
