import React from 'react'
import { Snackbar, Alert, Slide } from '@mui/material'
import { useDispatch, connect } from 'react-redux'

const Transition = (props) => {
  return <Slide {...props} direction="left" />;
}

const Modal = (props) => {
    const { modal } = props
    const dispatch = useDispatch()
    const open = Boolean(modal)

    console.log(modal)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        dispatch({type: 'RESET_MODAL'})
      };


  return (
    <div>
        <Snackbar open={ open } autoHideDuration={6000} TransitionComponent={Transition} anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose}  severity={modal.status} sx={{ width: '100%', background: modal.status === 'success' ? '#1378A5' : null}}>
            { modal.message }
        </Alert>
      </Snackbar>
    </div>
  )
}

const mapStateToprops = (state) => {
    return state
}

export default connect(mapStateToprops, {})(Modal)