import React from 'react'
import SEO from '../../components/SEO'
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, styled } from '@mui/material'
import BannerImg from '../../assets/02-plumbing-fixtures.jpg'
import Double from '../../assets/Double.jpg'
import Floor from '../../assets/floor.jpg'
import Plumbing from '../../assets/01-plumbing-real-estate.jpg'
import { Circle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { scrollUp } from '../../constants'


const Banner = styled(Box)({
    height: '60vh',
    backgroundImage: `url(${BannerImg})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover'
})

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const PlumbingFixtures = () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const lists = [
        'Think about how much water and energy they will save over time when it comes to buying new plumbing fixtures. Look for low-flow toilet models as well as ENERGY STAR certified kitchen and bath products that meet or exceed EPA standards for water conservation and energy efficiency.',
        'Consider special features such as touchless faucet technology which allows you to turn on the tap without physically touching the handle. This not only reduces waste but also helps prevent germs from spreading throughout your home. In addition, check out brands like Delta Faucet Company who offer lifetime warranties on select products, helping ensure your investment pays off in the long run.',
        `Finally, once you've chosen the right product for your needs, be sure to factor in installation costs when deciding whether or not to go ahead with a purchase. If possible, try tackling the job yourself – many manufacturers provide detailed instructions online that make DIY projects easier than ever before! However if you're working with older pipes or have other structural concerns then it might be best to call in a professional plumber who can complete the job quickly and safely`
    ]

  return (
    <div>
        <SEO>
				<title>The Latest Plumbing Fixtures To Upgrade Your Home</title>
				<meta name='og:title' content="The Latest Plumbing Fixtures To Upgrade Your Home" />
				<meta
					name='description'
					content=""
				/>
				<meta
					name='og:description'
					content=""
				/>
			</SEO>
            <Banner />
            <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
                <Typography textAlign={'center'} gutterBottom variant='h4'>The Latest Plumbing Fixtures To Upgrade Your Home</Typography>
                <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', bgcolor: 'primary.main'}} />
            </Box>

            <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                    <Typography paragraph mb={5}>
                        Are you looking for ways to upgrade your home? Plumbing fixtures are a great place to start. From sleek, modern designs to highly efficient water-saving options, there’s something out there for every homeowner. In this article, we’ll take a closer look at the latest plumbing fixtures so you can make an informed decision on which ones best fit your needs and budget. Let's get started!
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Choosing The Right Fixtures For Your Home</Typography>
                    <Typography paragraph>
                        Upgrading your home with the latest plumbing fixtures is an easy way to bring both beauty and functionality into any living space. From water efficiency to energy savings, modern plumbing fixtures offer a wealth of advantages that make them an attractive option for homeowners looking for ways to update their interiors.
                    </Typography>
                    <Typography paragraph>
                        When choosing the right fixtures for your home, consider factors like aesthetics and practicality. Fixtures come in many different colors, shapes, sizes, and finishes so you’ll want something that looks good with your existing decor but also meets all the necessary requirements for efficient operation.
                    </Typography>
                    <Typography paragraph>
                        Water-saving features are especially important as they can help reduce water consumption while providing reliable performance over time. Additionally, look for products certified by organizations like Energy Star or WaterSense – these certifications indicate that the product has met certain standards of energy or water efficiency.
                    </Typography>
                    <Typography paragraph>
                        Modern fixtures can also add value to your property when it comes time to sell. With new technology offering improved performance and greater convenience than ever before, potential buyers may be willing to pay more for homes equipped with updated systems. Investing in high-quality products now could translate into higher resale values down the road!
                    </Typography>


                    <Typography className={classes.title} variant='h6'>Benefits Of Installing New Plumbing Fixtures</Typography>
                    <Typography paragraph>
                        Installing new plumbing fixtures can be a great way to upgrade your home, while also saving you money and energy in the long run.For starters, modern fixtures designed for energy efficiency can help lower your utility bills. Plus, the latest fixtures are designed to last longer, which means you can save money on costly repairs. And with so many new styles and designs, you can easily find something that fits your home's look!
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Energy Efficiency</Typography>
                    <Typography paragraph>
                        Upgrading your home with the latest plumbing fixtures isn't just about upgrading to modern style and convenience; it's also about energy efficiency.Installing new water-saving faucets, showerheads, toilets, and other fixtures can drastically reduce the amount of water used in a household. This not only saves you money on utilities but reduces your carbon footprint too!
                    </Typography>
                    <Typography paragraph>
                        With newer models boasting features like dual flush systems for toilets that use less than 1 gallon per flush – this is a great way to help conserve resources while also keeping costs down. You'll even find options that come equipped with sensors so they turn off when no one is using them - perfect for busy households or those looking to save more energy around the house. So don’t wait any longer - make sure you upgrade your home today with the latest plumbing fixtures.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Saving Cost</Typography>
                    <Typography paragraph>
                        Upgrading your home with new plumbing fixtures is not only about modern style and convenience - it's also a smart investment. Not only do you get the latest features, but you'll save on water bills as well!
                    </Typography>
                    <Typography paragraph>
                        Let’s talk about cost savings: Installing energy-efficient faucets, showerheads, toilets, and other fixtures can drastically reduce the amount of water used in a household. This means that you're making an energy efficiency investment for the future – one that will pay off in lower utility costs over time. Plus, when these items are equipped with sensors that turn them off automatically when no one is using them, then they become even more efficient. So don't hesitate to make this important upgrade today; it could be the best decision you ever make!
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Tips For Selecting And Installing Plumbing Fixtures</Typography>
                    <Typography paragraph>
                        Upgrading the plumbing fixtures in your home is an easy way to improve both its aesthetic and energy efficiency. From modern faucets to high-performance toilets, there are plenty of options available that can help reduce water consumption while still adding style to any room. But before you start shopping for new fixtures, it’s important to understand what’s at stake with this type of upgrade – from cost considerations to potential issues with installation.
                    </Typography>
                    <Typography paragraph>
                        Here's a look at some tips for selecting and installing plumbing fixtures so you can make sure your project goes smoothly.
                    </Typography>

                    <List>
                        {
                            lists?.map( (el, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemIcon sx={{minWidth: '35px'}}><Circle sx={{fontSize: '.7rem'}} color='secondary' /></ListItemIcon>
                                        <ListItemText>{el}</ListItemText>
                                     </ListItem>
                                )
                            })
                        }
                    </List>

                    <Typography paragraph>
                        Taking all these factors into account can help ensure that upgrading your home's plumbing system is an enjoyable experience from start to finish!
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Conclusion</Typography>
                    <Typography paragraph>
                        Upgrading your home's plumbing fixtures can have a big impact on the look and feel of your space. With more modern, stylish options available than ever before, it's easy to create an inviting atmosphere that you'll enjoy for years to come.
                    </Typography>
                    <Typography paragraph>
                         At BPO Homes, we encourage homeowners to research their options carefully and ask questions when selecting new plumbing fixtures. Once you've made your decision, proper installation is key—so make sure you work with a professional who has experience installing these types of products in homes like yours. You won't regret investing in quality plumbing fixtures that will last for decades!
                    </Typography>
                   
                </Box>
            </Container>
        </Box>

        {/* READ ALSO */}
        <Box mt={4} mb={5}>
                <Container>
                    
                    <Typography variant='h6' mt={2} mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/plumbing-real-estate')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Plumbing})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>The Importance Of Plumbing In Real Estate: A Guide For Homebuyers</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Plumbing plays an essential role in the real estate world...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/floor-options')
                                scrollUp()
                              }}>
                                <CardHeader style={{backgroundImage: `url(${Floor})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Flooring Options For Every Room In Your Home</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Are you looking for the perfect flooring option to complete your home...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/kitchen-remodeling')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Double})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>The Benefits Of Upgrading To Double Pane Windows</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Are you ready to upgrade your windows? If so, double pane windows... </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>

    </div>
  )
}

export default PlumbingFixtures