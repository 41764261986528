import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Navigate } from "react-router-dom";
import base from "../api/apis";
import FINISH from "../assets/finish.png";

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const type = searchParams.get("type");
  const session_id = searchParams.get("session_id");
  const accessToken = localStorage.getItem("uta");
  const refreshToken = localStorage.getItem("utr");

  useEffect(() => {
    if (type) {
      if (
        type === "subscription1" ||
        type === "subscription2" ||
        type === "subscription3"
      ) {
        dispatch({
          type: "SUCCESS",
          payload: "Successfully subcribed to a BPO Realty Package",
        });
      }
      const getSubscription = async () => {
        try {
          const res = await base.get(`/register/subscriptions/`, {
            params: {
              user_id: currentUser.id,
            },
          });
          localStorage.setItem(
            "usub",
            JSON.stringify({ ...res.data[0], type })
          );
          setTimeout(() => {
            window.location.assign(
              `${
                process.env.REACT_APP_BPOHOMESTECH_REDIRECT
              }?uta=${accessToken}&utr=${refreshToken}&link=${"/my-subscriptions"}`
            );
          }, 2000);
        } catch (error) {
          // dispatch({type:'ERROR', payload:'Could not get subscriptions.' });
        }
      };

      getSubscription();
    }
  }, [dispatch, type, currentUser, accessToken, refreshToken]);

  const renderPage = () => {
    if (!type || !session_id) {
      return <Navigate to={"/"} />;
    }

    return (
      <Box
        height={"90vh"}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          "& img": {
            width: { xs: "60%", sm: "50%", md: "40%", lg: "30%" },
          },
        }}
      >
        <img src={FINISH} alt="finish" />
        <Typography variant="h5">Congratulations</Typography>
        <Typography variant="body1" mb={3}>
          Your BPO Realty subscription is successful
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          sx={{ textTransform: "none" }}
        >
          Redirecting. Please wait...
        </Button>
      </Box>
    );
  };

  return <>{renderPage()}</>;
};

export default PaymentSuccess;
export const PaymentSuccessIntern = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const type = searchParams.get("type");
  const session_id = searchParams.get("session_id");
  const accessToken = localStorage.getItem("uta");
  const refreshToken = localStorage.getItem("utr");

  useEffect(() => {
    if (type) {
      if (
        type === "subscription1" ||
        type === "subscription2" ||
        type === "subscription3"
      ) {
        dispatch({
          type: "SUCCESS",
          payload: "Successfully subcribed to a BPO Realty Package",
        });
      }
      const getSubscription = async () => {
        try {
          await base.post("/register/confirm-internship-payment/", {
            session_id,
          });
        } catch (error) {}
        try {
          const res = await base.get(`/register/subscriptions/`, {
            params: {
              user_id: currentUser.id,
            },
          });
          localStorage.setItem(
            "usub",
            JSON.stringify({ ...res.data[0], type })
          );
          setTimeout(() => {
            window.location.assign(
              `${
                process.env.REACT_APP_BPOHOMESTECH_REDIRECT
              }?uta=${accessToken}&utr=${refreshToken}&link=${"/my-subscriptions"}`
            );
          }, 2000);
        } catch (error) {
          // dispatch({type:'ERROR', payload:'Could not get subscriptions.' });
        }
      };

      getSubscription();
    }
  }, [dispatch, type, currentUser, accessToken, refreshToken, session_id]);

  const renderPage = () => {
    if (!type || !session_id) {
      return <Navigate to={"/"} />;
    }

    return (
      <Box
        height={"90vh"}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          "& img": {
            width: { xs: "60%", sm: "50%", md: "40%", lg: "30%" },
          },
        }}
      >
        <img src={FINISH} alt="finish" />
        <Typography variant="h5">Congratulations</Typography>
        <Typography variant="body1" mb={3}>
          Your BPO Internship subscription is successful
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          sx={{ textTransform: "none" }}
        >
          Redirecting. Please wait...
        </Button>
      </Box>
    );
  };

  return <>{renderPage()}</>;
};
