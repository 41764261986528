import NEWYORK from '../assets/newyork.jpg'
import BOSTON from '../assets/boston.jpg'
import FLORIDA from '../assets/florida.jpg'
import CALIFORNIA from '../assets/california.jpg'
import VIRGINIA from '../assets/virginia.jpg'
import BEVERLY from '../assets/beverly.jpg'
import JOSE from '../assets/jose.jpg'
import OAK from '../assets/oak.jpg'

export const hoods = [
	{ city: 'Fremont', img: NEWYORK },
	{ city: 'Ridgemark', img: BOSTON },
	{ city: 'Merced', img: FLORIDA },
	{ city: 'San Francisco', img: CALIFORNIA },
	{ city: 'Monterey', img: VIRGINIA },
	{ city: 'Menlo Park', img: BEVERLY },
	{ city: 'San Jose', img: JOSE },
	{ city: 'Oakland', img: OAK },
	{ city: 'Redwood City', img: CALIFORNIA },
	{ city: 'Palo Alto', img: FLORIDA }
]

export const cities = ['palo alto', 'san jose', 'menlo park', 'san diego', 'san francisco', 'santa clara', 'san mateo', 'san marco', 'santa monica', 'lake forest', 'san ramon', 'redwood city', 'los altos', 'los palmes']

export const URL = process.env.REACT_APP_URL;

export const timeData = ['7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM']

export const resources = {
	headerTitle: 'Resources',
	route: '',
	option: [
		{
			text: "Buyer's checklist",
			route: '',
		},
		{
			text: 'Resource center',
			route: '',
		},
		{
			text: 'First timer',
			route: '',
		},
		{
			text: 'Housing market',
			route: '',
		},
	],
};

export const propertyList = {
	roof: 'Tile',
	cooling: null,
	style: 'Traditional',
	area: 1043,
	bathsFull: 5,
	bathsHalf: 6,
	stories: 3,
	fireplaces: 1,
	flooring: null,
	heating: 'Radiant Heat',
	bathrooms: null,
	foundation: 'Slab',
	laundryFeatures: 'Area,Electric Dryer Hookup,Individual Room,Washer Hookup',
	occupantName: null,
	ownerName: null,
	lotDescription: 'Private Backyard',
	pool: 'Association,Private,In Ground',
	subType: null,
	bedrooms: 2,
	interiorFeatures: 'Drapes/Curtains/Window Cover, Fire/Smoke Alarm, High Ceiling, Island Kitchen',
	lotSize: '127X146',
	areaSource: 'Floorplans',
	maintenanceExpense: null,
	additionalRooms: 'Recreation,Master Bedroom,See Remarks',
	exteriorFeatures: 'Back Yard Fenced, Covered Patio/Deck, Patio/Deck',
	water: null,
	view: 'Golf Course',
	lotSizeArea: null,
	subdivision: 'Waterstone Springs',
	construction: 'In Kitchen,Stackable,Washer Included',
	lotSizeAreaUnits: null,
	type: 'RES',
	garageSpaces: 8.74556425245327,
	bathsThreeQuarter: null,
	accessibility: 'Manned Gate',
	acres: null,
	occupantType: null,
	subTypeText: null,
	yearBuilt: 1998,
};

export const quesLists = [
	[
		{
			title: 'When do you plan on selling?',
			subTitle: 'Please tell us your ideal timeline for closing the sale.',
			options: ['ASAP', '6 - 12 months', '1 - 3 months', '12+ months', '3 - 6 months'],
			formID: 'sellingPlan',
		},
	],
	[
		{
			title: 'What is the current condition of your home?',
			subTitle: 'Please select an answer',
			options: ['Needs Significant work', 'Needs a little work', 'Tear down'],
			formID: 'currentCondition',
		},
	],
	[
		{
			title: 'Why are you selling your home?',
			subTitle: 'Please select an answer',
			options: ['Upgrading my home', 'Downsizing my home', 'Selling secondary home', 'Retiring', 'Relocating', 'Other'],
			formID: 'whySell',
		},
	],
	[
		{
			title: 'Are you currently working with a real estate agent? ',
			subTitle: 'Please select an answer',
			options: ['Yes', 'No'],
			formID: 'haveAnAgent',
		},
	],
	[
		{
			title: 'Are you also looking to buy a home?',
			subTitle: 'Please select an answer',
			options: ['Yes', 'No'],
			formID: 'wantToBuyAHome',
		},
	],
	[
		{
			title: 'Have you made any improvements to your home?',
			subTitle: 'Please provide any improvement made',
			options: 'improvementForm',
		},
	],
	[
		{
			title: 'We’ll like to know you more:',
			subTitle: '',
			options: 'personalDetailsForm',
		},
	],
];

export const interiorDetails = [
	{
		key: 'Total Bedrooms',
		value: 'bedrooms'
	},
	{
		key: '# of Baths (Full)',
		value: 'bathsFull'
	},
	{
		key: '# of Baths (Half)',
		value: 'bathsHalf'
	},
	{
		key: 'Fireplace Min',
		value: 'fireplaces'
	},
	{
		key: 'Flooring',
		value: 'flooring'
	},
	{
		key: 'Heating',
		value: 'heating'
	},
	{
		key: 'Stories',
		value: 'stories'
	},
	{
		key: 'Cooling',
		value: 'cooling'
	}
]

export const exteriorDetails = [
	{
		key: 'Style',
		value: 'style'
	},
	{
		key: 'Roof',
		value: 'roof'
	},
	{
		key: 'Foundation',
		value: 'foundation'
	},
	{
		key: 'Pool',
		value: 'pool'
	},
	{
		key: 'Construction',
		value: 'flooring'
	},

]

export const garageDetails = [
	{
		key: 'Garage Spaces',
		value: 'garageSpaces'
	},
	{
		key: 'Parking Spaces',
		value: 'spaces'
	},
	{
		key: 'Parking Features',
		value: "description"
	},
	{
		key: 'Garage Min',
		value: 'garageSpaces'
	},
	{
		key: 'Garage Max',
		value: 'garageSpaces'
	},

]

export const infoboxTemplate = (src, price, address) => {
	//img
	let img = document.createElement("img");
	img.style.height = '10rem'
	img.src = src
	//price
	let head5 = document.createElement("h5");
	let text = document.createTextNode(price);
	head5.appendChild(text);
	// address
	let p = document.createElement("p");
	let addr = document.createTextNode(address);
	p.appendChild(addr);

	var box = document.querySelector("InfoboxCustom");
	box.appendChild(img, head5, p);
}

export const option1 = [
	"Premier Agent Advertising",
	"NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited",
	"Divorce Leads Unlimited",
	"Probate Leads Unlimited",
	"FSBO (For Sale By Owner) Leads Unlimited",
	"Expired Leads Unlimited",
	"Canceled Leads Unlimited",
	"Withdrawn Leads Unlimited",
	"Deal Analyzer Access Unlimited",
	"BPO Tech Training Center Access",
	"BPO Report Ordering Page",
	"Luxury Investor Website",
	"Investor Log (Investor List Available for Purchase)",
	"MDA (Million Dollar Agent) Business Program",
	"20% Referral Fee Required from BPO Homes",
	"Pre-Qualified Leads"
]

export const option2 = [
	"Premier Agent Advertising",
	"Marketing to 2000 Sellers",
	"Seller Calls Performed Quarterly",
	"Seller Texts Performed Quarterly",
	"Seller Emails Performed Monthly (Marketing Designs Included)",
	"Realty Agent Advertising",
	"NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited",
	"Divorce Leads Unlimited",
	"Probate Leads Unlimited",
	"FSBO (For Sale By Owner) Leads Unlimited",
	"Expired Leads Unlimited",
	"Canceled Leads Unlimited",
	"Withdrawn Leads Unlimited",
	"Deal Analyzer Access",
	"Unlimited BPO Tech Training Center Access",
	"BPO Report Ordering Page",
	"Luxury Investor Website",
	"Investor Log (Investor List Available for Purchase)",
	"MDA (Million Dollar Agent) Business Program"
]

export const option3 = [
	"Premier Agent Advertising",
	"Marketing to 3000 Sellers",
	"Seller Calls Performed Quarterly",
	"Seller Texts Performed Quarterly",
	"Seller Emails Performed Monthly (Marketing Designs Included)",
	"Realty Agent Advertising",
	"NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited",
	"Divorce Leads Unlimited",
	"Probate Leads Unlimited",
	"FSBO (For Sale By Owner) Leads Unlimited",
	"Expired Leads Unlimited",
	"Canceled Leads Unlimited",
	"Withdrawn Leads Unlimited",
	"Deal Analyzer Access",
	"Unlimited BPO Tech Training Center Access",
	"BPO Report Ordering Page",
	"Luxury Investor Website",
	"Investor Log (Investor List Available for Purchase)"
]
export const option4 = [
	"Premier Agent Advertising",
	"Marketing to 4000 Sellers",
	"Seller Calls Performed Quarterly",
	"Seller Texts Performed Quarterly",
	"Seller Emails Performed Monthly (Marketing Designs Included)",
	"Realty Agent Advertising",
	"NOD (Notice of Default / Pre-Foreclosure) Leads Unlimited",
	"Divorce Leads Unlimited",
	"Probate Leads Unlimited",
	"FSBO (For Sale By Owner) Leads Unlimited",
	"Expired Leads Unlimited",
	"Canceled Leads Unlimited",
	"Withdrawn Leads Unlimited",
	"Deal Analyzer Access",
	"Unlimited BPO Tech Training Center Access",
	"BPO Report Ordering Page",
	"Luxury Investor Website",
	"Investor Log (Investor List Available for Purchase)",
	"MDA (Million Dollar Agent) Business Program",
	"20% Referral Fee Required from BPO Homes Pre-Qualified Leads"
]



export const maxPrice = 10000000;
export const minPrice = 0;
export const token = { username: 'opera_1k43ox5s', password: '69m461h165333265' }

export const scrollUp = () => {
	const element = document.getElementById('scrollRef');
	element.scrollIntoView();
};

export const specialties = ['', 'Buyer Agent', 'Listing Agent', 'Relocation', 'Short Sale', 'Foreclosure', 'Consulting', 'Other'];
export const languages = [
	'',
	'English',
	'Spanish',
	'French',
	'German',
	'Mandarin',
	'Cantonese',
	'Arabic',
	'Hindi',
	'Polish',
	'Italian',
	'Thai',
	'Korean',
	'Greek',
];

