
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Kitchen4 from '../../assets/kitchen4.jpg'
import Check1 from '../../assets/checklist1.png'
import Check2 from '../../assets/explore.png'
import Check3 from '../../assets/homeimprove.png'
import { scrollUp } from '../../constants'
import SEO from '../../components/SEO'

const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${Kitchen4})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const KitchenRemodel = () => {
    const classes = useStyles()
    const navigate = useNavigate()

  return (
    <div>
            <SEO>
				<title>Kitchen Remodeling Ideas to Increase Your Home's Value</title>
				<meta name='og:title' content="Kitchen Remodeling Ideas to Increase Your Home's Value" />
				<meta
					name='description'
					content="Looking to increase your home's value? Check out these top kitchen remodeling ideas for inspiration and tips. Create a stylish, functional space that will boost your home's appeal and resale value."
				/>
				<meta
					name='og:description'
					content="Looking to increase your home's value? Check out these top kitchen remodeling ideas for inspiration and tips. Create a stylish, functional space that will boost your home's appeal and resale value."
				/>
			</SEO>
        <Box className={classes.banner}></Box>
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
            <Typography component={'h1'} textAlign={'center'} gutterBottom variant='h4'>Kitchen Remodeling Ideas to Increase Your Home's Value</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor}} />
        </Box>
        
        <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                
                    <Typography paragraph mt={5}>
                        Welcome to BPO Homes, a real estate website dedicated to helping home buyers find their dream home. When it comes to home value, the kitchen is one of the most important rooms in a house. In this article, we'll discuss several kitchen remodeling ideas that can help increase your home's value and make it a more enjoyable place to live.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Update Your Cabinets</Typography>
                    <Typography paragraph>
                        Kitchen cabinets are an important aspect of any kitchen remodel. By updating your cabinets, you can give your kitchen a fresh and modern look that is sure to impress homebuyers. You can either reface or paint the cabinets for a quick and inexpensive update, or you can replace them entirely for a more significant change. BPO Homes can help you find homes with updated cabinets or give you an idea of how to update your cabinets on your own.
                    </Typography>
                    <Typography className={classes.title} variant='h6'>Kitchen Island</Typography>
                    <Typography paragraph>
                        A kitchen island is a versatile addition to any kitchen. It can provide extra counter space, storage, and serve as a focal point in the room. BPO Homes can help you find homes with a kitchen island already in place or with enough space to add one. If you have a smaller kitchen, consider a smaller island or a movable one that can be tucked away when not in use.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Upgrade Your Appliances</Typography>
                    <Typography paragraph>
                        Modern and efficient appliances are a must-have in any modern kitchen. By upgrading your appliances, you not only increase your home's value but also improve its functionality. Invest in energy-efficient appliances that will not only save you money in the long run but also appeal to potential homebuyers.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Invest in Countertops</Typography>
                    <Typography paragraph>
                        Countertops are an important part of any kitchen remodel. By upgrading your countertops, you can create a more stylish and functional space. Consider options like granite or quartz for a modern and sleek look. 
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Lighting Matters</Typography>
                    <Typography paragraph>
                        Lighting is an often overlooked aspect of a kitchen remodel, but it can have a significant impact on the overall look and feel of the space. By adding or updating lighting fixtures, you can improve the ambiance and functionality of your kitchen. Consider installing under-cabinet lighting or pendant lights for a more modern and inviting atmosphere.
                    </Typography>
                    <Typography className={classes.title} variant='h6'>Flooring Upgrades</Typography>
                    <Typography paragraph>
                        The right flooring can make a big difference in the overall look and feel of a kitchen. By upgrading to more modern or durable flooring, you can add value to your home. Consider options like hardwood or tile for a more upscale and long-lasting look. 
                    </Typography>
                    <Typography className={classes.title} variant='h6'>Consider Open Concept</Typography>
                    <Typography paragraph>
                        Open concept kitchens are increasingly popular, and for good reason. They create a more spacious and welcoming atmosphere, and can add significant value to your home. Consider removing walls or opening up doorways to create a more open and inviting space. BPO Homes can help you find homes with open concept kitchens or give you an idea of how to create an open concept kitchen on your own.
 
                    </Typography>
                    <Typography className={classes.title} variant='h6'>Conclusion</Typography>
                    <Typography paragraph>
                    A kitchen remodel can add significant value to your home. By updating your cabinets, adding a kitchen island, upgrading your appliances, countertops, lighting fixtures, flooring, and considering an open concept kitchen, you can create a more modern and functional space that will appeal to homebuyers. Remember, BPO Homes can help you find homes with these features or give you an idea of how to create them on your own. With a little effort and creativity, you can have the kitchen of your dreams and add value to your home at the same time.
 
                    </Typography>

                   
                    
                </Box>
            </Container>
        </Box>


            <Box mt={4} mb={5}>
            <Container>
                    <Typography  mt={3}>To prepare for a sale, you should plan to set aside at least two months. This will give you time to investigate the local market, select a trustworthy agent, properly clean your house, and finish any little repairs.
                    </Typography>
                    <Typography variant='h6' mt={2} mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/bpo-guide')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check1})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Why Buyers Need A Broker's Price Opinion</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/explore')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check2})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' mb={3.5} color={'textSecondary'}>Explore My Options</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/home-improvement')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Check3})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Getting Started</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Home Improvement and Remodeling Trends in 2022</Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>
    </div>
  )
}

export default KitchenRemodel