import { combineReducers } from "redux";
import { formatPhoneNumber } from "../utils/functions";


// Auth Reducer
export const authReducer = (auth = null, action) => {
    switch (action.type) {
        case 'AUTH_LOGIN':
            return true
        case 'AUTH_CLOSE':
            return null
        default:
            return auth;
    }
}

export const signupState = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    zip: '',
    isAgent: false,
    isBroker: false,
}

export const loginState = {
    email: '',
    password: '',
}

export const signupReducer = (state, action) => {
    switch (action.type) {
        case "PASSWORD":
            return {...state, password: action.payload}
        case "CONFIRM_PASSWORD":
            return {...state, confirmPassword: action.payload}
        case "EMAIL":
            return {...state, email: action.payload.toLowerCase().trim()}
        case "FIRSTNAME":
            return {...state, firstname: action.payload}
        case "LASTNAME":
            return {...state, lastname: action.payload}
        case "PHONE":
            return {...state, phone: formatPhoneNumber(action.payload)}
        case "ZIP":
            return {...state, zip: action.payload }
        case "AGENT":
            return {...state, isAgent: action.payload }
        case "AGENT_BROKER":
            return {...state, isBroker: action.payload }
        case "RESET":
            return signupState
        default:
            return state;
    }
}

export const loginReducer = (state, action) => {
    switch (action.type) {
        case "EMAIL":
            return {...state, email: action.payload}
        case "PASSWORD":
            return {...state, password: action.payload}
        case "RESET":
            return loginState
        default:
            return state;
    }
}

export const saleState = {
    search: [],
    type: 'residential',
    bed: '',
    bath: '',
    price: [0, 10000000],
    property: '',
    yearBuilt: {min: '', max:''},
    squareFt: {min: '', max: ''}
}

export const filterReducer = ( filter = saleState, action ) => {
    switch (action.type) {
        case 'SEARCH_TERM':
            if(filter.search.includes(action.payload)){
                return { ...filter, search: [...filter.search] }
            }else{
                return { ...filter, search: [...filter.search, action.payload] }
            }
        case 'DELETE_TERM':
            const res = filter.search.filter(el => el !== action.payload)
            return { ...filter, search: [...res] }
        case 'TYPE':
            return {...filter, type: action.payload}
        case 'PRICE':
            return {...filter, price: action.payload}
        case 'PRICE_MIN':
            const minPrice = filter.price.map((el, index) => {
                if(index === 0){
                    return action.payload
                }else{
                    return el
                }
            })
            return {...filter, price: minPrice}
        case 'PRICE_MAX':
            const maxPrice = filter.price.map((el, index) => {
                if(index === 1){
                    return action.payload
                }else{
                    return el
                }
            })
            return {...filter, price: maxPrice}
        case 'BED':
            return {...filter, bed: action.payload}
        case 'BATH':
            return {...filter, bath: action.payload}
        case 'MIN_FT':
            return {...filter, squareFt: {...filter.squareFt, min :action.payload} }
        case 'MAX_FT':
            return {...filter, squareFt: {...filter.squareFt, max :action.payload} }
        case 'MIN_YEAR':
            return {...filter, yearBuilt: {...filter.yearBuilt, min :action.payload} }
        case 'MAX_YEAR':
            return {...filter, yearBuilt: {...filter.yearBuilt, max :action.payload} }
        case 'PROP':
            return {...filter, property: action.payload.toLowerCase()}
        case 'RESET_PROP':
            return {...filter, property: saleState.property}
        case 'RESET_PRICE':
            return {...filter, price: saleState.price}
        case 'RESET_FILTER':
            return saleState
        default:
            return filter;
    }
}




export const loaderReducer = ( loader = false, action) => {
    switch (action.type) {
        case "LOADING":
            return true
        case "EXIT_LOADING":
            return false
        default:
            return loader;
    }
}

export const userReducer = (user = null, action) => {
    switch (action.type) {
        case "LOGIN_USER":
            return action.payload
        case "LOGOUT_USER":
            return user
        case "NO_USER":
            return user
        default:
           return user
    }
}

export const modalReducer = (modal = null, action) => {
    switch (action.type) {
        case "SUCCESS":
            return { message : action.payload, status: 'success' }
        case "ERROR":
            return { message : action.payload, status: 'error' }
        case "RESET_MODAL":
            return null
        default:
           return modal
    }
}



export default combineReducers({
    auth: authReducer,
    currentUser: userReducer,
    loader: loaderReducer,
    modal: modalReducer,
    propertyFilter: filterReducer
})