import axios from 'axios';
import { URL } from '../constants';
//import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'

export const token = {username: process.env.REACT_APP_LISTING_API_KEY, password: process.env.REACT_APP_LISTING_API_SECRET}
export const sampleToken = { username: 'simplyrets', password: 'simplyrets' }

export const mlsAPI = axios.create({
	baseURL: 'https://api.simplyrets.com/properties',
	auth: sampleToken,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
		'Accept-Language': 'en-US,en;q=0.9',
	},
});


const base = axios.create({
	baseURL: URL,
	headers: {
		'Content-Type': 'application/json',
		'Accept-Language': 'en-US,en;q=0.9',
	},
});

export const listingURL = axios.create({
	baseURL: `${URL}bpo_listings/`,
	headers: {
		'Content-Type': 'application/json',
		'Accept-Language': 'en-US,en;q=0.9',
	},
})

base.interceptors.request.use((config) => {
	// console.log(config);
	const token = localStorage.getItem('uta');
	if (token) {
		config.headers.Authorization = `JWT ${token}`;
	}
	return config;
});

base.interceptors.response.use(
	(config) => {
		return config;
	},
	(error) => {
		if ('message' in error && error.message === 'canceled') return;
		const { status } = error?.response;
		if (status === 401) {
			const dispatch = useDispatch()
			localStorage.setItem('nextRoute', window.location.pathname);
			dispatch({type: 'LOGOUT_USER'});
			localStorage.clear();
			dispatch({type: 'ERROR', payload: 'Session expired. Please login again.'});
			window.location.href = '/';
		}

		return Promise.reject(error);
	}
);

//create axios instance for file upload
export const file = axios.create({
	baseURL: URL,
	headers: {
		'Content-Type': 'multipart/form-data',
		'Accept-Language': 'en-US,en;q=0.9',
	},
});

export default base;

