import { Box, Container, Divider, Grid, InputAdornment, styled, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import Home1 from '../assets/home1.jpg'
import Floor from '../assets/floor.jpg'
import Double from '../assets/Double.jpg'
import Junior from '../assets/Junior.jpg'
import Perfect from '../assets/perfect.jpg'
import BuyerCheck from '../assets/buycheck.png'
import BuyerFirst from '../assets/buyerfirst.png'
import BuyerScore from '../assets/buyerscore.png'
import BPOGuide from '../assets/bpoguide.png'
import HomeBG from '../assets/home1.jpg'
import SellerBG from '../assets/sellerguide.png'
import HomeImprove from '../assets/homeimprove.png'
import ChooseHome from '../assets/lawn.jpg'
import NewRoof from '../assets/home3.jpg'
import Kitchen from '../assets/kitchen4.jpg'
import Maximize from '../assets/bedroom5.jpg'
import Explore from '../assets/explore.png'
import PlumbingEstate from '../assets/01-plumbing-real-estate.jpg'
import PlumbingFixtures from '../assets/02-plumbing-fixtures.jpg'
import Faucets from '../assets/03-faucets.jpg'
import QualityFaucets from '../assets/04-quality-faucets.jpg'
import Investors from '../assets/05-investors.jpg'
import BlogItem from '../components/BlogItem'
import SEO from '../components/SEO'
import { Search } from '@mui/icons-material'

const Banner = styled(Box)(({theme})=> ({
        height: '40vh',
        backgroundImage: `url(${HomeBG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
}))

const Blog = () => {

    const posts = [
        {
            title: '3 Must-Know Home Facts For Homebuyers And Investors',
            desc: "Homebuyers and investors often feel overwhelmed by the process of buying or investing in a home. With so many details to consider, it can be hard to know where to start!",
            image: Investors,
            link: 'investors'
        },
        {
            title: 'The Benefits Of Investing In High-Quality Faucets',
            desc: "Investing in a high-quality faucet can be an incredibly smart move for any homeowner. Not only do they look great and provide added convenience",
            image: QualityFaucets,
            link: 'quality-faucets'
        },
        {
            title: 'A Guide To Choosing The Right Faucet For Your Home',
            desc: "Choosing the right faucet for your home can be a daunting task. With so many options to choose from, it's difficult to know which one will work best with your existing fixtures and decor",
            image: Faucets,
            link: 'faucets'
        },
        {
            title: 'The Latest Plumbing Fixtures To Upgrade Your Home',
            desc: "Are you looking for ways to upgrade your home? Plumbing fixtures are a great place to start. From sleek, modern designs to highly efficient",
            image: PlumbingFixtures,
            link: 'plumbing-fixtures'
        },
        {
            title: 'The Importance Of Plumbing In Real Estate: A Guide For Homebuyers',
            desc: "Plumbing plays an essential role in the real estate world. Every homebuyer should understand its importance to ensure they get the best property for their money.",
            image: PlumbingEstate,
            link: 'plumbing-real-estate'
        },
        {
            title: 'Flooring Options For Every Room In Your Home',
            desc: "Are you looking for the perfect flooring option to complete your home? No matter what room in your house, there’s a type of flooring that will fit perfectly.",
            image: Floor,
            link: 'floor-options'
        },
        {
            title: 'The Benefits Of Upgrading To Double Pane Windows',
            desc: "Are you ready to upgrade your windows? If so, double pane windows are an excellent choice. Not only will they make your home look more attractive and modern",
            image: Double,
            link: 'double-pane-windows'
        },
        {
            title: 'Junior Master Bedrooms: A Great Option For Growing Families',
            desc: "Are you a growing family, looking for extra space without the hassle of having to move? Junior master bedrooms might be just what you need! These added rooms provide extra living and storage space for larger families.",
            image: Junior,
            link: 'junior-master-bedrooms'
        },
        {
            title: 'Creating The Perfect Master Suite: Design Ideas And Inspiration',
            desc: "Have you ever dreamed of having a luxurious master suite that’s the envy of all your friends? Well, it’s time to make those dreams a reality!",
            image: Perfect,
            link: 'perfect-design'
        },
        {
            title: 'Your Best Checklist for Buying a Home',
            desc: "Learn the best steps for buying a home with this comprehensive checklist. From calculating your monthly housing budget to arranging for a house inspection, this guide has you covered.",
            image: BuyerCheck,
            link: 'buyer-checklist'
        },
        {
            title: 'Buying a Home in 2023?',
            desc: "We know how challenging the entire process can be. You can navigate your first experience and make it as painless as you can by following the advice provided here.",
            image: BuyerFirst,
            link: 'buyer-firsttimer'
        },
        {
            title: 'How to Raise Your Credit Score to Get Your First Home Mortgage',
            desc: "Do you want to raise your credit score? Yes, it's simple to become enamored with the concept of home ownership.",
            image: BuyerScore,
            link: 'buyer-score'
        },
        {
            title: 'Why Buyers & Sellers Need A Broker’s Price Opinion',
            desc: "A broker price opinion (BPO) is an estimated value of a property made by a real estate broker or professional.",
            image: BPOGuide,
            link: 'bpo-guide'
        },
        {
            title: "Seller's Guide",
            desc: "Discover the best month and day to list your home, how seasonality influences home sales, and what to consider before you sell.",
            image: SellerBG,
            link: 'sellers-guide'
        },
        {
            title: "Home Improvement and Remodeling Trends for 2023",
            desc: "Home remodeling and renovation can be a terrific method to raise the value of your home and give it the appearance you want.",
            image: HomeImprove,
            link: 'home-improvement'
        },
        {
            title: 'The Importance of a Home Inspection Before Buying a House.',
            desc: "Looking to buy a house? Make sure you don't skip the crucial step of getting a home inspection. Learn about the importance of a thorough inspection and what to expect in this guide.",
            image: Home1,
            link: 'home-inspection'
        },
        {
            title: 'What to look for when Choosing the Right Home Inspector',
            desc: "Learn how to choose the right home inspector with our comprehensive guide. Discover what factors to consider and avoid costly mistakes in your home-buying process.",
            image: ChooseHome,
            link: 'choosing-the-right-home'
        },
        {
            title: 'The Benefits of a New Roof for Your Home',
            desc: "Improve your home and protect your family with a new roof. Discover the benefits of installing a new roof with our expert guide. Get started today!",
            image: NewRoof,
            link: 'benefits-of-a-new-roof'
        },
        {
            title: "Kitchen Remodeling Ideas to Increase Your Home's Value",
            desc: "Looking to increase your home's value? Check out these top kitchen remodeling ideas for inspiration and tips. Create a stylish, functional space that will boost your home's appeal and resale value.",
            image: Kitchen,
            link: 'kitchen-remodeling'
        },
        {
            title: "Maximizing Space in Small Bedrooms: Tips and Tricks",
            desc: "Transform your small bedroom into a functional and stylish oasis with these tips and tricks for maximizing space.",
            image: Maximize,
            link: 'maximizing-space'
        },
        {
            title: "Explore. Sell Your Home Easily with a Participating BPO Home Premier Agent",
            desc: "Get local market knowledge, lower listing costs, and expert support with negotiating and evaluating offers when you list your property with a participating BPO Home Premier Agent.",
            image: Explore,
            link: 'explore'
        }
    ]
    const [search, setSearch] = useState(posts)

    const onSearch = (e) => {
        if(e.target.value === ''){
            setSearch(posts)
            return
        }
        const filtered = posts?.filter(el => el.title.toLowerCase().includes(e.target.value.toLowerCase()) ) 
        setSearch(filtered)
    }

  return (
    <div>
        <SEO>
				<title>Our Blogs</title>
				<meta name='og:title' content="Our Blogs" />
				<meta
					name='description'
					content="Looking to buy a house? Make sure you don't skip the crucial step of getting a home inspection. Learn about the importance of a thorough inspection and what to expect in this guide."
				/>
				<meta
					name='og:description'
					content="Looking to buy a house? Make sure you don't skip the crucial step of getting a home inspection. Learn about the importance of a thorough inspection and what to expect in this guide."
				/>
			</SEO>
        <Banner />
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
            <Typography textAlign={'center'} gutterBottom variant='h4'>Our Blogs</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor}} />
        </Box>
        <Container maxWidth='xl'>
        <Box my={'4rem'}>
            <TextField type={'search'} onChange={(e)=> onSearch(e)} sx={{mb: '2rem'}} variant='outlined' fullWidth placeholder='Search for blogs' InputProps={{
                endAdornment: <InputAdornment position='start'><Search fontSize='small' color='#eee' /></InputAdornment>
            }} />
            <Grid container spacing={3}>
                {
                    search?.map(el => {
                        return (
                            <Grid key={el.title} item xs={12} sm={6} md={4} lg={3}>
                                <BlogItem 
                                image={el.image}  
                                title={el.title}
                                desc={el.desc}
                                link={el.link}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>

        </Box>
        </Container>
    </div>
  )
}

export default Blog