import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import SEO from '../components/SEO'

const About = () => {
  return (
    <>
      <SEO>
				<title>BPO Homes | Real Estate Property Analysis & Investing Solutions</title>
				<meta name='og:title' content='BPO Homes | Real Estate Property Analysis & Investing Solutions' />
				<meta
					name='description'
					content='Get detailed real estate property analysis through BPO Homes unique BPO Reporting process. Accessible to consumers for the first time in the industry, BPO Homes provides essential tools for buying, selling and investing in real estate. Join the BPO Homes team today.'
				/>
				<meta
					name='og:description'
					content='Get detailed real estate property analysis through BPO Homes unique BPO Reporting process. Accessible to consumers for the first time in the industry, BPO Homes provides essential tools for buying, selling and investing in real estate. Join the BPO Homes team today.'
				/>
			</SEO>
    <Box >

        <Container>
            <Typography mt={8} variant='h5' sx={{fontWeight: 600}} textAlign='center' mb={6}>About Us</Typography>
            <Typography paragraph textAlign={'justify'} >
            BPO Homes was established in 2022 based in the Silicon Valley of San Jose Ca. BPO Homes is a Real Estate Company with a focus on providing consumers with detailed Real Estate property analysis through our unique BPO Reporting process. We give our consumers the opportunity to hear and see what a Licensed unbiased professional has to say in regards to the nuances of Real Estate acquisition or sale of a property. The BPO Reports show disadvantages or attributes of a property which prove to be vital information when attempting to make a sound financial decision in regards to whether or not you should buy or sell for a certain price in any given Real Estate market.
            <br/>
            </Typography>
            <Typography paragraph textAlign={'justify'}>
            Knowing that Banks/Lenders have used BPO Reports for decades in comparison with Appraisals to determine whether or not or the amount they should lend on a property. At BPOHomes we believe if lenders use BPO Reports to decide if they are making a sound Real Estate decision so should Buyers & Sellers.
            </Typography>

            <Typography paragraph textAlign={'justify'}>
            For the 1st time in the Real Estate industry BPO Homes has made BPO’s accessible to the everyday consumer!!

            Now more than ever in our ever changing Real Estate market you need to keep up with advancements in Real Estate Tech and here at BPO Homes we are here for you to make surethat you have the necessary tools to accomplish that task.

            We help Agents brand themselves and build their business whether they are BPO Homes Realty Agents or not. We have Tremendous Platforms for our subscribing Agent Business growth at a very reasonable low cost for such subscribing agents.
            </Typography>

            <Typography paragraph textAlign={'justify'}>
            We believe everyone deserves a chance to grow their business and we are able to help everyone in some type of way regardless of marketing budget. With our Platforms such as BPOFarm Agent,  BPO Report Agent, & as a Partner Premier Agent you can start building your business with us.

            Last but not least check out our Deal Analyzer Platform which makes Real Estate investing a breeze for our investing consumers and for our subscribing agents to evaluate Real Estate investment deals for their clients.Thanks for visiting our site!The BPO Homes Team
            </Typography>
        </Container>
    </Box>
    </>
  )
}

export default About