import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export const ResponsiveDesign = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    textSizes: isMobile ? "2.2rem" : "4rem",
    imageHeight: isMobile ? "50vh" : "90vh",
    bodyText: isMobile ? "body1" : "h6",
    iconsSize: isMobile ? 15 : 30,
    generalStyle: {
      ml: isMobile ? "1rem" : "",
    },
    imageHeightForSmallImages: {
      height: isMobile ? "40vh" : "90vh",
      mt: isMobile ? "4rem" : "",
      bgColor: "pink",
    },
  };
};
