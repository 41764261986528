
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Divider, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'


const BlogItem = ({title, desc, location, image, price, closed, link}) => {
    const navigate = useNavigate()

  return (
    <Card variant='outlined' elevation={0} onClick={() => navigate(`/blog/${link}`)} sx={{
                borderRadius: '10px', cursor: 'pointer', transition: 'all .2s ease-in',
                '&:hover': {
                    borderColor: 'secondary.main'
                },
                '& .MuiCardMedia-root': {
                height: '180px'
            }}
        }>
        <CardMedia 
            alt="foods"
            image={image}
         >
            <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '15px'}}>
                { closed && <Chip size='small' color='secondary' label={<Typography variant='body2'>Closed</Typography>} /> }
                <Chip size='small' color='primary' label={<Typography variant='body2' sx={{fontSize: '.8rem'}}>{2023}</Typography>} />
            </Box>
         </CardMedia>
        <CardContent>
            <Typography variant='h6' noWrap sx={{fontWeight: 500, fontSize: '1.2rem'}}>{title}</Typography>
            <Typography variant='body1' noWrap mb={1} color={'GrayText'}>{location}</Typography>
            <Typography  variant='body2' sx={{
                'display': '-webkit-box',
                '-webkit-line-clamp': '3',
               ' -webkit-box-orient': 'vertical',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis',
            }} mb={1} color={'GrayText'}>{desc}</Typography>
        </CardContent>
        <Divider />
        <CardActions sx={{padding: '1rem', display: 'flex', justifyContent: 'space-between'}}>
            <Button size="small" variant='text' color='secondary' onClick={() => navigate(`/blog/${link}`)} disableElevation sx={{borderRadius: '5px'}}>Read More</Button>
            {/* <Typography variant='h6' sx={{fontWeight: 700, fontSize: '1rem'}}>GHc{price}</Typography> */}
        </CardActions>
    </Card>
  )
}

export default BlogItem