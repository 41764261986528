import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Checkbox, Divider, Drawer, FormControlLabel, FormLabel, Grid, Hidden, IconButton, InputAdornment, MenuItem, Stack, styled, TextField, Toolbar, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SingleHouse from '../assets/singlehome.png'
import TownHouse from '../assets/townhouse.png'
import Condo from '../assets/condo.png'
import { useLocation } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { addSearchOptions } from '../actions'

const drawerWidth = 500
// const maxPrice = 10000000;
// const minPrice = 0;


const WrapBox = styled(Box)(({ theme }) => ({
    padding: '2rem 3rem'
}))

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.3rem',
    fontWeight: 600
}))

const PriceField = styled(TextField)(({ theme }) => ({
    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none'
    },
    'input[type=number]': {
        // '-moz-appearance': 'textfield'
    }
}))



const FilterDrawer = ({ open, closeDrawer, reset, onSearch, load, type, bed, bath, setBath, setBed, property, setProperty, price, setPrice }) => {
    const dispatch = useDispatch()
    const path = useLocation().pathname.split('/')[1]
    const [area, setArea] = useState({ min: '', max: '' })
    const [year, setYear] = useState({ min: '', max: '' })
    const [lotSize, setLotSize] = useState({ min: '', max: '' })
    const [pool, setPool] = useState('')
    const [garage, setGarage] = useState()
    const [priceType, setPriceType] = useState('list price')
    const [customize, setCustomize] = useState(false)
    const [loanType, setLoanType] = useState('30')
    const [interest, setInterest] = useState(7.197 )
    const [downPayment, setDownPayment] = useState(0)
    const [homeInsurance, setHomeInsurance] = useState(0.32)


    //interest = 7.19%
    //downpayment = 2000
    //loanType = 30

    useEffect(() => {
        if (path === 'homes-for-sale') {
            dispatch({ type: 'TYPE', payload: 'residential' })
        } if (path === 'homes-for-rent') {
            dispatch({ type: 'TYPE', payload: 'rental' })
        }
    }, [dispatch, path])

    const propertyBtn = [
        { name: 'SingleFamily', img: SingleHouse },
        { name: 'Townhouse', img: TownHouse },
        { name: 'Condo', img: Condo },
        { name: 'Duplex', img: Condo },
        { name: 'Land', img: Condo },
        { name: 'MultiFamily', img: Condo }
    ]

    const lotSizes = [
        '2000 sqft', '4500 sqft', '6000 sqft',
        '1 Acre', '2 Acres', '3 Acres', '4 Acres', '5 Acres',
        '10 Acres', '20 Acres', '30 Acres', '40 Acres', '50 Acres'
    ]

    const features = [
        'Air Conditioning',
        'Basements', 'WalkinCloset',
        'Wetbar', 'Fireplace', 'OpenBeam Ceiling', 'Skylights',
        'Guest House', 'Vaulted Ceiling', 'High Ceiling', 'Security Gate',
        'Elevator', 'Parking', 'Has a View', 'Pets Allowed'
    ]


    const handleClose = () => {
        closeDrawer()
    }
    // const handleSliderChange = (event, newValue) => {
    // 	setPrice( { min: newValue[0], max: newValue[1]} )
    // 	addSearchOptions('minPrice', newValue[0])
    // 	addSearchOptions('maxPrice', newValue[1])
    //   };

    const propertySearch = () => {
        onSearch()
        closeDrawer()
    }

    return (
        <Drawer anchor='right' sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }} open={open} onClose={handleClose} classes={{ paper: drawerWidth }} >
            <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', position: 'sticky', top: 0, background: '#fff', zIndex: 99 }}>
                <IconButton onClick={handleClose}><Close fontSize='small' /></IconButton>
            </Toolbar>
            <Hidden mdUp>
                <WrapBox>
                    <ButtonGroup size='large' variant='outlined' color='secondary' fullWidth >
                        <Button disableRipple sx={{ textTransform: 'none' }} onClick={() => window.location.assign('/homes-for-sale')}
                            variant={type === 'residential' ? 'contained' : 'outlined'}
                            disableElevation >Residential</Button>
                        <Button disableRipple sx={{ textTransform: 'none' }} onClick={() => window.location.assign('/homes-for-rent')}
                            variant={type === 'rental' ? 'contained' : 'outlined'}
                            disableElevation>Rental</Button>
                    </ButtonGroup>
                </WrapBox>
                <Divider sx={{ background: grey[100] }} />
            </Hidden>
            <WrapBox>
                <Title variant='h6'>Price</Title>
                <Stack direction={'row'} gap={2} mt={3} mb={2}>
                    <Button onClick={()=>setPriceType('list price')} disableElevation
                        variant={ priceType === 'list price' ? 'contained' : 'text'} 
                        color='secondary' sx={{ textTransform: 'capitalize' }}>List Price</Button>
                    <Button onClick={()=>setPriceType('monthly payment')} 
                        variant={ priceType === 'monthly payment' ? 'contained' : 'text'}  color='secondary' disableElevation
                        sx={{ textTransform: 'capitalize' }}>Monthly Payment</Button>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item lg={6} md={6} xs={6}>
                        <PriceField type={'number'} inputProps={{ min: 0 }} size="small" fullWidth variant='outlined'
                            value={price.min}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value)
                                setPrice((prev) => {
                                    return { ...prev, min: newValue }
                                })
                                addSearchOptions('minPrice', newValue)
                            }}
                            placeholder='Enter min' InputProps={{
                                endAdornment: <InputAdornment position='end'>$</InputAdornment>
                            }} />
                    </Grid>
                    <Grid item lg={6} md={6} xs={6}>
                        <PriceField type={'number'} inputProps={{ min: 0 }} size="small" fullWidth variant='outlined'
                            value={price.max}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value)
                                setPrice((prev) => {
                                    return { ...prev, max: newValue }
                                })
                                addSearchOptions('maxPrice', newValue)
                            }}
                            placeholder='Enter max' InputProps={{
                                endAdornment: <InputAdornment position='end'>$</InputAdornment>
                            }} />
                    </Grid>
                </Grid>
                {
                    priceType === 'monthly payment' && 
                    <>
                        <Typography mt={2} variant='body2' color={'GrayText'}>
                            Estimated monthly payment includes HOA fees, property taxes, a 0.32% homeowners insurance rate, and mortgage payments based on a 7.197% interest rate with a 30-year fixed loan.
                        </Typography>
                        <Button variant='text' disableRipple onClick={()=>setCustomize(!customize)}
                            sx={{textTransform: 'capitalize', color: 'secondary.main', ml: -1}}>Customize { customize ? <KeyboardArrowUp fontSize='small' /> : <KeyboardArrowDown fontSize='small' /> }</Button>
                    </>
                }
                {
                    customize && 
                    <Grid container spacing={3} mt={-1}>
                        <Grid item lg={6} md={6} xs={6}>
                            <Typography mb={1}>Loan Type</Typography>
                            <TextField variant='outlined' select size='small' value={loanType} 
                                onChange={(e)=>setLoanType(e?.target?.value)} placeholder='Loan Type' fullWidth >
                               <MenuItem value='30'>30-year fixed</MenuItem>
                               <MenuItem value='15'>15-year fixed</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}>
                        <Typography mb={1}>Interest Rate</Typography>
                            <TextField variant='outlined' size='small' type={'number'} inputProps={{min: 0, step: 0.1}}
                            value={interest} placeholder='Interest Rate' fullWidth onChange={e=> setInterest(e?.target?.value)}  
                            InputProps={{
                                endAdornment: <InputAdornment>%</InputAdornment>
                            }}/>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}>
                            <Typography mb={1}>Down Payment</Typography>
                            <TextField variant='outlined' size='small' type={'number'} inputProps={{min: 0}}
                                InputProps={{
                                    endAdornment: <InputAdornment>$</InputAdornment>
                                }}
                                onChange={e => setDownPayment(e?.target?.value)}
                                value={downPayment} placeholder='Down Payment' fullWidth />
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}>
                            <Typography mb={1}>Homeowners Insurance</Typography>
                            <TextField variant='outlined' size='small' onChange={(e)=> setHomeInsurance(e?.target?.value)}
                                value={homeInsurance} type={'number'} inputProps={{min: 0, step: 0.1}}
                                InputProps={{
                                    endAdornment: <InputAdornment>%</InputAdornment>
                                }}
                                placeholder='Homeowners Insurance' fullWidth />
                        </Grid>
                    </Grid>
                }
            </WrapBox>
            <Divider sx={{ background: grey[100] }} />
            <WrapBox>
                <Box mb={'1.5rem'}>
                    <Title mb={1}>Beds</Title>
                    <ButtonGroup color='secondary' disableElevation variant='outlined' fullWidth>
                        {
                            [1, 2, 3, 4, 5].map((el, index) => {
                                return <Button disableFocusRipple disableRipple key={index} variant={bed === el ? 'contained' : 'outlined'}
                                    onClick={() => {
                                        setBed(el)
                                        addSearchOptions('bed', el)
                                    }}
                                    disableElevation sx={{ textTransform: 'none' }}>{path === 'open-houses' ? el + '+' : el}</Button>
                            })
                        }
                    </ButtonGroup>
                </Box>
                <Box>
                    <Title mb={1}>Baths</Title>
                    <ButtonGroup color='secondary' disableElevation variant='outlined' fullWidth>
                        {
                            [1, 2, 3, 4, 5].map((el, index) => {
                                return <Button disableFocusRipple disableRipple key={index}
                                    onClick={() => {
                                        setBath(el)
                                        addSearchOptions('bath', el)
                                    }}
                                    variant={bath === el ? 'contained' : 'outlined'}
                                    disableElevation sx={{ textTransform: 'none' }}>{path === 'open-houses' ? el + '+' : el}</Button>
                            })
                        }
                    </ButtonGroup>
                </Box>
            </WrapBox>
            <Divider sx={{ background: grey[100] }} />
            <WrapBox>
                <Title variant='h6' mb={'.8rem'}>Property Types</Title>
                <Grid container spacing={2}>
                    {
                        propertyBtn.map((el, index) => {
                            return (
                                <Grid key={index} item xs={6} sm={6} md={6} lg={4}>
                                    <Button disableRipple disableFocusRipple fullWidth color='secondary'
                                        onClick={() => {
                                            setProperty(el.name === 'SingleFamily' ? 'singlefamilyresidence' : el.name === 'Condo' ? 'condominium' : el.name === 'Duplex' ? 'duplex' : el.name === 'Townhouse' ? 'townhouse' : el.name === 'MultiFamily' ? 'multifamily' : el.name === 'Land' ? 'land' : '')
                                            addSearchOptions('property', el.name === 'SingleFamily' ? 'singlefamilyresidence' : el.name === 'Condo' ? 'condominium' : el.name === 'Duplex' ? 'duplex' : el.name === 'Townhouse' ? 'townhouse' : el.name === 'MultiFamily' ? 'multifamily' : el.name === 'Land' ? 'land' : '')
                                        }}
                                        variant={property.startsWith(el.name.toLowerCase()) ? 'contained' : 'outlined'}
                                        sx={{ textTransform: 'none', padding: '1rem 0', fontSize: '.8rem', display: 'flex', flexDirection: 'column' }} disableElevation key={index}>
                                        <img width={'15%'} style={{ marginBottom: '-1.1rem' }} src={el.img} alt='' /><br />
                                        {el.name}
                                    </Button>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </WrapBox>
            <Divider sx={{ background: grey[100] }} />
            <WrapBox>
                <Title variant='h6' mb={'.8rem'}>Home Features</Title>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <Typography mb={1}>Gararage Spots</Typography>
                        <ButtonGroup fullWidth variant='outlined' color='secondary'>
                            {
                                ['1', '2', '3', '4', '5+']?.map((el, i) => (
                                    <Button
                                        variant={garage === el ? 'contained' : 'outlined'}
                                        onClick={() => {
                                            setGarage(el)
                                            addSearchOptions('garages', el)
                                        }}
                                    >{el}</Button>
                                ))
                            }
                        </ButtonGroup>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography mb={1}>Pool Type</Typography>
                        <TextField select fullWidth size='small' value={pool} onChange={(e) => {
                            setPool(e.target.value)
                            addSearchOptions('pool', e.target.value?.split(' ')?.join(''))
                        }}>
                            {
                                ['Spa HotTub', 'In Ground Pool', 'Private Pool', 'Community Pool']?.map((el, i) => {
                                    return (<MenuItem key={i} value={el}>{el}</MenuItem>)
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item sm={6}>
                        {
                            features?.slice(0, 8)?.map((el, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        label={el}
                                        control={<Checkbox color='secondary' />}
                                        onChange={() => {
                                            addSearchOptions('interior', el?.split(' ')?.join(''))
                                        }}
                                    />
                                )
                            })
                        }
                    </Grid>
                    <Grid item sm={6}>
                        {
                            features?.slice(8, features?.length)?.map((el, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        label={el}
                                        control={<Checkbox color='secondary' />}
                                        onChange={() => {
                                            addSearchOptions('interior', el?.split(' ')?.join(''))
                                        }}
                                    />
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </WrapBox>
            <Divider sx={{ background: grey[100] }} />
            {/* <WrapBox>
            <Title variant='h6' mb={'.8rem'}>Finance & Cost</Title>
            
        </WrapBox> */}
            {
                path === 'open-houses' ? null :
                    <>
                        <Divider sx={{ background: grey[100] }} />
                        <WrapBox sx={{ mb: '3rem' }}>
                            <Title variant='h6' mb={'.8rem'}>Property Details</Title>
                            <Box my={'1rem'}>
                                <FormLabel sx={{ marginBottom: '5px', display: 'block' }}>Square feet</FormLabel>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6}>
                                        <TextField size='small' select defaultValue={''} variant='outlined' value={area.min}
                                            fullWidth placeholder='Enter' onChange={(e) => {
                                                setArea((prev) => {
                                                    return { ...prev, min: e.target.value }
                                                })
                                                addSearchOptions('minArea', e.target.value)
                                            }} >
                                            {
                                                [750, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700].map((el, index) => {
                                                    return (
                                                        <MenuItem key={index}
                                                            value={el} >{el}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField size='small' select defaultValue={''} value={area.max}
                                            variant='outlined' fullWidth onChange={(e) => {
                                                setArea((prev) => {
                                                    return { ...prev, max: e.target.value }
                                                })
                                                addSearchOptions('maxArea', e.target.value)
                                            }} >
                                            {
                                                [750, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700].map((el, index) => {
                                                    return (
                                                        <MenuItem key={index}
                                                            value={el} >{el}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box my={'1rem'}>
                                <FormLabel sx={{ marginBottom: '5px', display: 'block' }}>Year Built</FormLabel>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6}>
                                        <TextField sx={{ mb: 2 }} size='small' value={year.min} select defaultValue={''}
                                            variant='outlined' fullWidth onChange={(e) => {
                                                setYear((prev) => {
                                                    return { ...prev, min: e.target.value }
                                                })
                                                addSearchOptions('minYear', e.target.value)
                                            }} >
                                            {
                                                [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022].map((el, index) => {
                                                    return (
                                                        <MenuItem key={index} value={el} >{el}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField size='small' value={year.max} select defaultValue={''}
                                            variant='outlined' fullWidth onChange={(e) => {
                                                setYear((prev) => {
                                                    return { ...prev, max: e.target.value }
                                                })
                                                addSearchOptions('maxYear', e.target.value)
                                            }} >
                                            {
                                                [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022].map((el, index) => {
                                                    return (
                                                        <MenuItem key={index} value={el} >{el}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mb={'1rem'} mt={'-1rem'}>
                                <FormLabel sx={{ marginBottom: '5px', display: 'block' }}>Lot Size</FormLabel>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6}>
                                        <TextField sx={{ mb: 2 }} size='small' value={lotSize.min} select defaultValue={''}
                                            variant='outlined' fullWidth onChange={(e) => {
                                                setLotSize((prev) => {
                                                    return { ...prev, min: e.target.value }
                                                })
                                                const val = e?.target?.value?.split(' ')
                                                const inAcres = val[1]?.includes('Acre')
                                                addSearchOptions('minLotSize', inAcres ? (+val[0] * 43560) : +val[0])
                                            }} >
                                            {
                                                lotSizes?.map((el, index) => {
                                                    return (
                                                        <MenuItem key={index} value={el} >{el}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField size='small' value={lotSize.max} select defaultValue={''}
                                            variant='outlined' fullWidth onChange={(e) => {
                                                setLotSize((prev) => {
                                                    return { ...prev, max: e.target.value }
                                                })
                                                const val = e?.target?.value?.split(' ')
                                                const inAcres = val[1]?.includes('Acre')
                                                addSearchOptions('maxLotSize', inAcres ? (+val[0] * 43560) : +val[0])
                                            }} >
                                            {
                                                lotSizes?.map((el, index) => {
                                                    return (
                                                        <MenuItem key={index} value={el} >{el}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Box>
                        </WrapBox>

                    </>
            }

            <Toolbar sx={{
                display: 'flex', justifyContent: 'space-between',
                position: 'fixed', bottom: 0, background: '#fff',
                borderTop: `1px solid ${grey[300]}`,
                zIndex: 99, width: drawerWidth
            }}>
                <Button variant='outlined' color='secondary' sx={{ textTransform: 'none' }} onClick={reset}>Reset</Button>
                <LoadingButton loading={load} variant='contained' color='secondary'
                    disableElevation sx={{ textTransform: 'none', marginRight: '3rem', width: '6rem' }}
                    onClick={propertySearch} >Apply</LoadingButton>
            </Toolbar>
        </Drawer>
    )
}

export default FilterDrawer