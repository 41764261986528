import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'

const Loader = () => {
  return (
    <Box textAlign={'center'} height='100vh' display={'flex'} justifyContent='center' alignItems={'center'}>
        <Stack>
            <LinearProgress />
            <Typography mt={1}>Loading. Please wait...</Typography>
        </Stack>
    </Box>
  )
}

export default Loader