import { alpha, Backdrop, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const AuthLoader = ({open}) => {
  return (
    <Backdrop open={open} sx={{bgcolor: alpha('#000', 0.6), zIndex: 99}}>
            <CircularProgress />
            <Typography variant='h6' color={'paper.main'} ml={2}>Loading. Please wait...</Typography>
    </Backdrop>
  )
}

export default AuthLoader