import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import { authRequest } from '../actions'
import { connect } from 'react-redux'

const PublicRoute = (props) => {
  const { authRequest } = props

  useEffect(()=> {
    authRequest()
  },[authRequest])

  return (
    <>
        <NavBar/>
          <Outlet />
       { props.footer ? <Footer /> : null }
    </>
  )
}

const mapStateToprops = (state) => {
  return state
}

export default connect(mapStateToprops, {authRequest})(PublicRoute)