import React, { useState, useEffect, useReducer, useCallback } from 'react';
import NoList from '../assets/noListing.png';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	Divider,
	FormLabel,
	Grid,
	Rating,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import base from '../api/apis';
import Loader from '../components/Loader';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import SEO from '../components/SEO';
import { Circle } from '@mui/icons-material';

const listWidth = 100

const useStyles = makeStyles((theme) => ({
	wrap: {
		padding: '3rem 0',
	},
	profilePic: {
		height: theme.spacing(21),
		width: theme.spacing(21),
	},
	field: {
		marginTop: '.5rem',
		marginBottom: '1rem',
	},
	listImg: {
		height: theme.spacing(11),
		width: theme.spacing(16),
		borderRadius: '10px',
	},
	flexGrid: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}));

const SingleAgentPage = () => {
	const classes = useStyles();
	const modalDispatch = useDispatch()
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [load, setLoad] = useState(false);
	const [isBioShort, setIsBioShort] = useState(true);
	const [isServiceShort, setIsServiceShort] = useState(true);
	const [reviews, setReviews] = useState(0)

	

	useEffect(()=> {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	},[])

	useEffect(() => {
		const fetchAgent = async() => {
			const { data: agentData } = await base.get(`register/agents/${id}`)
			const { data: listing } = await base.get(`premiersite/listings?agent_id=${id}`)
			const agent = {
				...agentData,
				listing,
			};	
			// console.log(agent)
			setData(agent)
			setLoading(false)
		};

		fetchAgent();
	}, [id]);

	const getReviews = useCallback(
		(page) => {
			// setReviews([]);
			// setIsReviewLoading(true);
			base
				.get(`register/agents/${id}/reviews/?page=${page || 1}`)
				.then(({ data }) => {
					// setReviews(data?.results);
					setReviews(data?.count);
				})
				.catch((err) => {
					modalDispatch({type: 'ERROR', payload: 'Sorry, could not get reviews'})
				})
		},
		[id, modalDispatch]
	);

	useEffect(() => {
		getReviews();
	}, [getReviews]);

	const formState = {
		name: '',
		email: '',
		phone: '',
		message: '',
	};

	const formReducer = (state, action) => {
		switch (action.type) {
			case 'NAME':
				return { ...state, name: action.payload };
			case 'PHONE':
				return { ...state, phone: action.payload.trim() };
			case 'EMAIL':
				return { ...state, email: action.payload.trim() };
			case 'MSG':
				return { ...state, message: action.payload };
			case 'RESET':
				return formState;
			default:
				return state;
		}
	};

	const [formInput, dispatch] = useReducer(formReducer, formState);

	const onFormSubmit = () => {
		//if checks
		if (formInput.name === '' || !formInput.email.includes('@') || formInput.phone === '' || formInput.message === '') {
			return;
		}
		setLoad(true);
		const body = {
			client_name: formInput.name,
			client_email: formInput.email,
			client_phone: formInput.phone,
			client_message: formInput.message,
			agent_id: id,
		};
		setTimeout(async () => {
			try {
				const res = await base.post('register/contact_agent/', body);
				if (res) {
					//console.log(res.data)
					dispatch({ type: 'RESET' });
					setLoad(false);
					modalDispatch({type: 'SUCCESS', payload: res.data})
				}
			} catch (error) {
				//console.log(error)
				modalDispatch({type: 'ERROR', payload: 'Sorry, something went wrong'})
				setLoad(false);
			}
		});
	};

	return (
		<div className={classes.wrap}>
		{!loading && (
				<SEO>
					<title>
						{`${_.startCase(data?.first_name || '')} ${_.startCase(data?.last_name || '')}: Real Estate ${data?.agent_status || ''} in ${
							data?.city || ''
						}, ${data?.state || ''}, ${data?.zip_code || ''}`}
					</title>
				</SEO>
			)}
		{
			loading ? <Loader /> : 
			<Container>
				<Grid container spacing={5}>
					<Grid item lg={7}>
						<Box display={'flex'} gap='1.5rem' justifyContent={'flex-start'} alignItems='flex-start'>
							<Avatar sx={{width: '9rem', height: '9rem', '& > img': { objectPosition: 'top center' }}} src={data?.profile_image} alt='profile_pic' />
							<Stack>
								<Typography variant='h5'>{(data?.user?.first_name || '') + ' ' + (data?.user?.last_name || '')}</Typography>
								<Typography color={'textPrimary'} variant='body1'>
									{data?.brokerage_name || 'BPO Homes'}
								</Typography>
								<Typography color={'textSecondary'} variant='body1'>
									{`BPO ${data.subscription ? 'Premier' : 'Partner'} Agent`}
								</Typography>
								<span style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
									<Typography variant='body2' color='textSecondary'>
										5.0
									</Typography>
									<Rating size='small' value={5.0} precision={0.5} />
									<Typography variant='body2' color='textSecondary'>
										{reviews} Reviews
									</Typography>
								</span>
								<Box mt={'10px'} sx={{display: 'flex', gap: '.8rem'}}>
									<Button
										disableElevation
										variant='contained'
										color='secondary'
										onClick={ ()=> data?.web_link ? window.open(`${data?.web_link}`, '_blank') : window.open(`https://bpotech.io/order-bpo-report/${id}`, '_blank')}
										sx={{ textTransform: 'none' }}
									>
										Visit Website
									</Button>
									{/* <Button disableElevation
										variant='contained' 
										onClick={()=> window.open(`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}order-bpo-report/${id}`, '_blank')}
										color='primary' sx={{ textTransform: 'none', color: '#fff' }}
									>Order BPO Report</Button> */}
								</Box>
							</Stack>
						</Box>

						{/* About */}
						<Box margin={'3rem 0'}>
							<Typography variant='h5' gutterBottom>About Me</Typography>
							<Divider sx={{my: '10px'}} />
							{ data?.specialties?.length > 0 && (
								<Typography variant='h6' color={'secondary'} gutterBottom>
									Specialities:{' '}
									<Typography variant='body1' sx={{ color: 'black', display: 'inline-block', fontWeight: '300' }}>
										{data?.specialties?.map((spec) => spec.title)?.join(', ') || 'Not Provided'}
									</Typography>
								</Typography>
							)}

							{ data?.bio && (
								<>
									<Typography variant='body1'>
										{isBioShort ? (data?.bio || '')?.slice(0, 200) + (data?.bio?.length > 200 ? '...' : '') : data?.bio}
									</Typography>
									{data?.bio?.length > 200 && (
										<Button
											sx={{ textTransform: 'capitalize', marginTop: 1, textDecoration: 'underline' }}
											color='secondary'
											variant='text'
											onClick={() => setIsBioShort((v) => !v)}
										>
											{isBioShort ? 'Show more' : 'Show Less'}
										</Button>
									)}
								</>
							)}
						</Box>

						{/* Agents Listing */}
						<Box margin={'3rem 0'} sx={{border: `1px solid ${grey[300]}`, padding: '2rem', borderRadius: '8px'}}>
							<Typography variant='h5' mb={2}>
								Agent Listings
							</Typography>
							<Grid container>
								<Grid item lg={9}>
									<Typography color='secondary' textAlign={'left'}>Address</Typography>
								</Grid>
								<Grid item lg={3}>
									<Typography color='secondary' >Listing Price</Typography>
								</Grid>
								
							</Grid>
								<Divider style={{ margin: '1rem 0' }} />

							{/* populate listings */}
							<Box height={'36rem'} sx={{overflowY: 'scroll'}}>
								{
									data?.listing && data.listing.length > 0 ? (
									data.listing.map((list) => {
										return (
											<Card variant='outlined' key={list.id} onClick={() => window.open(`${process.env.REACT_APP_BPOHOMESTECH_REDIRECT}view-listing/${id}/${list.id}#home`, '_blank')}
											 style={{ border: 'none', borderBottom: `1px solid ${grey[400]}`, borderRadius: 0, cursor: 'pointer' }}>
												<CardContent>

													<Grid container sx={{ position: 'relative' }}>
														<Tooltip title={list.agent_role || 'Seller Agent'}>
															<Circle
																fontSize='2px'
																color={list?.agent_role === 'Seller Agent' ? 'primary' : 'secondary'}
																sx={{ position: 'absolute', right: '-7px', top: '5px' }}
															/>
														</Tooltip>
														<Grid item lg={9} style={{ display: 'flex',gap:'1rem', justifyContent: 'space-between',  }}>
															<Avatar sx={{width: listWidth, height: '5rem', borderRadius: '10px',}} 
																src={list.photos.length > 0 ? list.photos[0].image : null} alt='listing_pic' 
															/>
															<Stack sx={{width: `calc(100% - ${listWidth}px)`}}>
																<Typography noWrap sx={{width: '80%', fontWeight: 400}}>{_.startCase(list?.address.toLowerCase())}</Typography>
																<Typography noWrap>{list?.state}</Typography>
																<Typography variant='body2'>{_.startCase(list?.city.toLowerCase())}</Typography>
															</Stack>
														</Grid>
														
														<Grid item lg={3} sx={{textAlign: 'center'}}>
															<Typography fontWeight={500}>$ {list?.[!list.date_sold ? 'list_price' : 'sold_price']?.toLocaleString()}</Typography>
															<Typography fontWeight={300} fontSize={'13px'}>
																{list?.sold_price && list.date_sold ? 'Sold' : 'Active'}
															</Typography>
														</Grid>
													</Grid>
												</CardContent>
											</Card>
										);
									})
								) : (
									<Box height={'100%'} bgcolor={grey[200]} padding={'4rem'} textAlign='center' borderRadius={'10px'}>
										<img src={NoList} alt='no-listing' width={'30%'} style={{ marginBottom: '10px' }} />
										<Typography paragraph>Sorry, no listing available</Typography>
									</Box>
								)}

							</Box>

						</Box>

					</Grid>

					<Grid item lg={5}>
						{/* Info Box */}
						<Box
							padding={'2rem'}
							marginBottom={'2rem'}
							borderRadius='10px'
							color={'#fff'}
							style={{ backgroundImage: 'linear-gradient(310deg, #0d5384, #014ab19c)' }}
						>
							<Typography variant='h6' gutterBottom>
								Work as a team on BPO Homes
							</Typography>
							<Typography variant='body2'>You can contact this agent and request a BPO report, alongside other things</Typography>
						</Box>

						<Card variant='outlined' sx={{ marginBottom: '2rem' }}>
							<Typography sx={{ margin: '1rem 2rem' }} variant='h6'>
								Professional Details
							</Typography>
							<Divider sx={{ margin: '1rem 0', background: grey[200] }} />
							<CardContent style={{ padding: ' 1rem 2rem' }}>
								<Grid container rowSpacing={1}>
									<Grid item xs={12} sm={6}>
										<Typography>Broker address:</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{ `${data?.brokerage_address ? data.brokerage_address : ''}  ${data.state ? data.state : ''} ${data.city ? data.city : ''} ${data.zip_code ? data.zip_code : '' }` }
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography>Phone number:</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.phone}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography>Estate Licenses:</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{ data?.licenses?.find((license) => license.category === 'real_estate_agent')?.number || 'Not Provided' }
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography>Service Areas:</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
									
									{
										(data?.service_areas && data?.service_areas.length > 5) ?
										<>
											<Typography variant='body2' color={'textSecondary'}>
												{ isServiceShort ? 
													data?.service_areas?.slice(0, 5).map((el) => el.location).join(', ') + '...' 
													: 
													data?.service_areas?.map((el) => el.location).join(', ')
												}
											</Typography>
											<Button size='small' disableFocusRipple
												sx={{ textTransform: 'capitalize', marginTop: '0', textDecoration: 'underline' }}
												color='secondary'
												variant='text'
												onClick={() => setIsServiceShort((v) => !v)}
											>
												{ isServiceShort ? 'Show more' : 'Show Less'}
											</Button>
										</>
										:
										<Typography variant='body2' color={'textSecondary'}>
											{ 
												data?.service_areas?.map((el) => el.location).join(', ')
											}
										</Typography>

									}

									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography>Screen Name:</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.screen_name}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography>Languages:</Typography>
									</Grid>
									<Grid item xs={12} sm={6} sx={{display: 'flex', gap: '5px'}}>
										<Typography variant='body2' color={'textSecondary'}>
											{data?.languages?.map((lang) => lang.name)?.join(', ') || 'Not Provided'}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>

						{/* Form */}
						<Card elevation={4} sx={{borderRadius: '10px'}}>
							<CardHeader title='Contact Agent' sx={{ textAlign: 'center', padding: '1.5rem', fontSize: '1rem' }} />
							<Divider />
							<CardContent style={{ padding: '2.5rem' }}>
								<Stack>
									<FormLabel sx={{mb: 1}}>Name</FormLabel>
									<TextField
										size='small'
										sx={{mb: '1rem'}}
										fullWidth
										variant='outlined'
										placeholder='Enter Fullname'
										value={formInput.name}
										onChange={(e) => dispatch({ type: 'NAME', payload: e.target.value })}
									/>
								</Stack>
								<Stack>
									<FormLabel sx={{mb: 1}}>Phone</FormLabel>
									<TextField
										size='small'
										sx={{mb: '1rem'}}
										type={'number'}
										fullWidth
										variant='outlined'
										placeholder='Enter Phone Number'
										value={formInput.phone}
										onChange={(e) => dispatch({ type: 'PHONE', payload: e.target.value })}
									/>
								</Stack>
								<Stack>
									<FormLabel sx={{mb: 1}}>Email</FormLabel>
									<TextField
										size='small'
										sx={{mb: '1rem'}}
										type={'email'}
										fullWidth
										variant='outlined'
										placeholder='Enter Email Address'
										value={formInput.email}
										onChange={(e) => dispatch({ type: 'EMAIL', payload: e.target.value })}
									/>
								</Stack>
								<Stack>
									<FormLabel sx={{mb: 1}}>Message</FormLabel>
									<TextField
										size='small'
										sx={{mb: '1rem'}}
										fullWidth
										multiline
										rows={4}
										variant='outlined'
										placeholder='How can we help?'
										value={formInput.message}
										onChange={(e) => dispatch({ type: 'MSG', payload: e.target.value })}
									/>
								</Stack>
								<LoadingButton loading={load}
									onClick={onFormSubmit}
									disableElevation
									variant='contained'
									fullWidth
									color='secondary'
									sx={{ textTransform: 'none', marginTop: '10px', height: '3rem' }}
								>
									SUBMIT
								</LoadingButton>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				

			</Container>
		}
		</div>
	);
};

export default SingleAgentPage;
