//api calls will be made here

import axios from 'axios';
import base, { listingURL } from '../api/apis';
import { URL, cities } from '../constants';
import { brokerageBaseURL } from '../components/Brokerage/Apis';

export const filterListingsWithoutPhotos = (listings) => {
	const filtered = listings?.filter((el) => el.photos?.length > 0);
	return filtered;
};

export const filterOpenListingsWithoutPhotos = (listings) => {
	const filtered = listings?.filter((el) => el?.photos?.length > 0);
	return filtered;
};

export const filterActiveListings = (data) => {
	const filtered = data?.filter((el) => el?.mls?.status?.startsWith('Active') || el?.mls?.status === 'Pending');
	return filtered;
};

export const filterSoldListings = (data) => {
	const filtered = data?.filter((el) => el?.mls?.status?.toLowerCase() === 'sold' || el?.mls?.status?.toLowerCase() === 'closed');
	return filtered;
};

export const filterOpenHouseDuplicates = (listings) => {
	let add = listings;
	for (let index = 0; index < add.length; index++) {
		const current = add[index];
		const res = add?.filter(
			(el) => el?.listing?.listPrice !== current?.listing?.listPrice && el?.listing?.address.full !== current?.listing?.address.full
		);
		add = [...res, add[index]];
	}
	return add;
};

export const getCurrentUser = (dispatch) => {
	const details = localStorage.getItem('udt');
	const favs = localStorage.getItem('ufv');
	if (details && favs) {
		dispatch({ type: 'LOGIN_USER', payload: { ...JSON.parse(details), favs: JSON.parse(favs) } });
	}
};

export const getSearchData = () => {
	const searchOptions = localStorage.getItem('search');
	return JSON.parse(searchOptions);
};

export const getBoundaryData = () => {
	const boundaries = localStorage.getItem('boundary');
	return JSON.parse(boundaries);
};

export const addSearchOptions = (t, val) => {
	const searchOptions = JSON.parse(localStorage.getItem('search'));

	if (t === 'type') {
		searchOptions.type = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'bed') {
		searchOptions.bed = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'bath') {
		searchOptions.bath = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'property') {
		searchOptions.property = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'minPrice') {
		searchOptions.price.min = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'maxPrice') {
		searchOptions.price.max = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'minArea') {
		searchOptions.area.min = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'maxArea') {
		searchOptions.area.max = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'minYear') {
		searchOptions.year.min = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'maxYear') {
		searchOptions.year.max = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'minLotSize') {
		searchOptions.lotsize.min = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'maxLotSize') {
		searchOptions.lotsize.max = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'pool') {
		searchOptions.pool = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'garages') {
		searchOptions.garages = val;
		localStorage.setItem('search', JSON.stringify(searchOptions));
		return;
	}
	if (t === 'interior') {
		searchOptions.interior = [...searchOptions.interior, val];
		localStorage.setItem('interior', JSON.stringify(searchOptions));
		return;
	}
};
export const getBrokerageToken = async () => {
	try {
		const {
			data: { data },
		} = await brokerageBaseURL.post('/accounts/login/', {
			email: process.env.REACT_APP_LEAD_AUTH_EMAIL,
			password: '#$3809?23-2',
		});
		localStorage.setItem('brokerageToken', data?.token?.access);
	} catch (error) {}
};

export const getListingData = async ({
	house,
	type,
	value,
	term,
	price,
	bed,
	bath,
	property,
	year,
	area,
	lotsize,
	garages,
	pool,
	interior,
	page_num,
}) => {
	if (term.length > 0) {
		let query = [];
		for (let index = 0; index < term.length; index++) {
			query.push(
				cities?.some((el) => el === term[index]) ? `q=${term[index]?.replace(/\s+/g, '').toLowerCase()}` : `q=${term[index]?.toLowerCase()}`
			);
		}

		let interiorList = [];
		if (interior?.length > 0) {
			for (let index = 0; index < interior.length; index++) {
				interiorList.push(`q=${interior[index]?.toLowerCase()}`);
			}
		}

		try {
			if (house === 'openhouses') {
				const { data: res } = await listingURL.get(
					`openhouse/merge_listings/?${query.join('&')}
										&limit=40&property_type=${type}` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&min_area=${area.min}`}` +
						`${area.max && `&max_area=${area.max}`}` +
						`${year.min && `&min_year=${year.min}`}` +
						`${year.max && `&max_year=${year.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize.max}`}` +
						`${interior && `&interior_features=${interior}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				const results = filterOpenListingsWithoutPhotos(res?.data);
				return { results, count: res?.count };
			}
			if (house === 'soldhomes') {
				const { data: res } = await listingURL.get(
					`residential/merge_listings/sold?${query.join('&')}
										&limit=40&property_type=${type}` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&min_area=${area.min}`}` +
						`${area.max && `&max_area=${area.max}`}` +
						`${year.min && `&min_year=${year.min}`}` +
						`${year.max && `&max_year=${year.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize.max}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				const results = filterOpenListingsWithoutPhotos(res?.data);
				return { results, count: res?.count };
			}
			if (house === 'rental') {
				const { data: res } = await listingURL.get(
					`rental/merge_listings?${query.join('&')}
										&limit=40&property_type=${type}` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&min_area=${area.min}`}` +
						`${area.max && `&max_area=${area.max}`}` +
						`${year.min && `&min_year=${year.min}`}` +
						`${year.max && `&max_year=${year.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize.max}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				const results = filterOpenListingsWithoutPhotos(res?.data);
				return { results, count: res?.count };
			} else {
				const { data: res } = await listingURL.get(
					`${type}/merge_listings/?${query.join('&')}
										&limit=40` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&min_area=${area.min}`}` +
						`${area.max && `&max_area=${area.max}`}` +
						`${year.min && `&min_year=${year.min}`}` +
						`${year.max && `&max_year=${year.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize.max}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				// const results = filterOpenListingsWithoutPhotos(res?.data)
				return { results: res?.data, count: res?.count };
			}
		} catch (error) {
			// console.log(error)
		}
	} else {
		let interiorList = [];
		if (interior?.length > 0) {
			for (let index = 0; index < interior.length; index++) {
				interiorList.push(`q=${interior[index]?.toLowerCase()}`);
			}
		}
		try {
			if (house === 'openhouses') {
				const { data: res } = await listingURL.get(
					`openhouse/merge_listings/?
										${
											(value === '' || value.typeOf === undefined) && house === 'properties'
												? 'q=Sanjose'
												: (value === '' || value.typeOf === undefined) && house === 'openhouses'
												? 'q=Sanjose'
												: 'q=' + value
										}
										&limit=40&property_type=${type}
										&min_price=${price.min}&max_price=${price.max}` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&min_area=${area.min}`}` +
						`${area.max && `&max_area=${area.max}`}` +
						`${year.min && `&min_year=${year.min}`}` +
						`${year.max && `&max_year=${year.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize.max}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				// console.log(res)
				const results = filterOpenListingsWithoutPhotos(res?.data);
				return { results, count: res?.count };
			}
			if (house === 'soldhomes') {
				const { data: res } = await listingURL.get(
					`residential/merge_listings/sold?
										${
											(value === '' || value.typeOf === undefined) && house === 'properties'
												? 'q=Sanjose'
												: (value === '' || value.typeOf === undefined) && house === 'openhouses'
												? 'q=Sanjose'
												: 'q=' + value
										}
										&limit=40&property_type=${type}
										&min_price=${price.min}&max_price=${price.max}` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&min_area=${area.min}`}` +
						`${area.max && `&max_area=${area.max}`}` +
						`${year.min && `&min_year=${year.min}`}` +
						`${year.max && `&max_year=${year.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize.max}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				// console.log(res)
				const results = filterOpenListingsWithoutPhotos(res?.data);
				return { results, count: res?.count };
			}
			if (house === 'rental') {
				const { data: res } = await listingURL.get(
					`rental/merge_listings?
										${
											(value === '' || value.typeOf === undefined) && house === 'properties'
												? 'q=Sanjose'
												: (value === '' || value.typeOf === undefined) && house === 'openhouses'
												? 'q=Sanjose'
												: 'q=' + value
										}
										&limit=40&property_type=${type}
										&min_price=${price?.min || 0}&max_price=${price?.max}` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&min_area=${area?.min}`}` +
						`${area.max && `&max_area=${area?.max}`}` +
						`${year.min && `&min_year=${year?.min}`}` +
						`${year.max && `&max_year=${year?.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize?.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize?.max}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				// console.log(res)
				const results = filterOpenListingsWithoutPhotos(res?.data);
				return { results, count: res?.count };
			} else {
				const { data: res } = await listingURL.get(
					`${type}/merge_listings/?
										${
											(value === '' || value.typeOf === undefined) && house === 'properties'
												? 'q=Sanjose'
												: (value === '' || value.typeOf === undefined) && house === 'openhouses'
												? 'q=Sanjose'
												: 'q=' + value
										}
										&limit=40&min_price=${price.min}&max_price=${price.max}` +
						`${page_num && `&page_num=${page_num}`}` +
						`${bed && `&beds=${bed}`}` +
						`${bath && `&baths=${bath}`}` +
						`${garages && `&garages=${garages}`}` +
						`${pool && `&pool=${pool}`}` +
						`${area.min && `&minarea=${area.min}`}` +
						`${area.max && `&maxarea=${area.max}`}` +
						`${year.min && `&minyear=${year.min}`}` +
						`${year.max && `&maxyear=${year.max}`}` +
						`${lotsize.min && `&min_lotsize=${lotsize.min}`}` +
						`${lotsize.max && `&max_lotsize=${lotsize.max}`}` +
						// `${interior?.length > 0 && `&interior_features=${interiorList?.join('&')}`}` +
						`${property && `&property_sub_type=${property}`}`
				);
				const results = res?.data;
				return { results, count: res?.count };
			}
		} catch (error) {
			//    console.log(error)
		}
	}
};

export const googleOneTap = (token) => {
	return async (dispatch) => {
		try {
			dispatch({ type: 'LOADING' });
			const res = await base.post('core/one-tap-login/', { id_token: token });
			console.log(res.data.access);
			localStorage.setItem('uta', res.data.access);
			localStorage.setItem('utr', res.data.refresh);
			const details = await base.get('/auth/users/me', {
				headers: {
					Authorization: `JWT ${res.data.access}`,
				},
			});
			const favs = await base.get(`favorite/properties/${details.data.id}`);
			localStorage.setItem('udt', JSON.stringify(details.data));
			localStorage.setItem('ufv', JSON.stringify(favs.data.favorites));
			dispatch({
				type: 'LOGIN_USER',
				payload: { ...details.data, refreshToken: res.data.refresh, accessToken: res.data.access, favs: favs.data.favorites },
			});
			dispatch({ type: 'EXIT_LOADING' });
			dispatch({ type: 'SUCCESS', payload: 'Login successful.' });
		} catch (error) {
			dispatch({ type: 'EXIT_LOADING' });
			if (error.response.data?.messages) {
				dispatch({ type: 'ERROR', payload: error.response.data?.messages[0].message });
			} else {
				dispatch({ type: 'ERROR', payload: 'Sorry, something went wrong' });
			}
		}
	};
};

export const googleOAuth = (code, link) => {
	return async (dispatch) => {
		try {
			window.history.pushState({ id: 100 }, '', `${link}`);
			dispatch({ type: 'LOADING' });
			const res = await base.post('core/dj-rest-auth/google/', { code: code, redirect_uri: `${window.location.origin}/` });
			//console.log(res)
			localStorage.setItem('uta', res.data.access_token);
			localStorage.setItem('utr', res.data.refresh_token);
			const details = await base.get('/auth/users/me', {
				headers: {
					Authorization: `JWT ${res.data.access_token}`,
				},
			});
			const favs = await base.get(`favorite/properties/${details.data.id}`);
			localStorage.setItem('udt', JSON.stringify(details.data));
			localStorage.setItem('ufv', JSON.stringify(favs.data.favorites));
			dispatch({
				type: 'LOGIN_USER',
				payload: { ...details.data, refreshToken: res.data.refresh, accessToken: res.data.access, favs: favs.data.favorites },
			});
			dispatch({ type: 'EXIT_LOADING' });
			dispatch({ type: 'SUCCESS', payload: 'Login successful' });
			// window.location.assign(`${link}`)
		} catch (error) {
			dispatch({ type: 'EXIT_LOADING' });
			if (error.response.data?.non_field_errors) {
				dispatch({ type: 'ERROR', payload: error.response.data?.non_field_error[0] });
			} else {
				dispatch({ type: 'ERROR', payload: 'Sorry, something went wrong' });
			}
		}
	};
};

export const facebookOAuth = (code, link) => {
	return async (dispatch) => {
		try {
			window.history.pushState({ id: 100 }, '', `${link}`);
			dispatch({ type: 'LOADING' });

			const res = await base.post('core/dj-rest-auth/facebook/', { redirect_uri: `${window.location.origin}/`, code: code });
			localStorage.setItem('uta', res.data.access_token);
			localStorage.setItem('utr', res.data.refresh_token);
			const details = await base.get('/auth/users/me', {
				headers: {
					Authorization: `JWT ${res.data.access_token}`,
				},
			});
			const favs = await base.get(`favorite/properties/${details.data.id}`);
			localStorage.setItem('udt', JSON.stringify(details.data));
			localStorage.setItem('ufv', JSON.stringify(favs.data.favorites));
			dispatch({
				type: 'LOGIN_USER',
				payload: { ...details.data, refreshToken: res.data.refresh, accessToken: res.data.access, favs: favs.data.favorites },
			});
			dispatch({ type: 'EXIT_LOADING' });
			dispatch({ type: 'SUCCESS', payload: 'Login successful' });
		} catch (error) {
			dispatch({ type: 'EXIT_LOADING' });
			if (error.response.data?.non_field_errors) {
				dispatch({ type: 'ERROR', payload: error.response.data?.non_field_error[0] });
			} else {
				dispatch({ type: 'ERROR', payload: 'Sorry, something went wrong' });
			}
		}
	};
};

export const addToFavorite = (data) => {
	return async (dispatch) => {
		const userFavs = localStorage.getItem('ufv');
		const userDetails = localStorage.getItem('udt');
		try {
			const res = await base.post('favorite/properties/', data);
			//console.log(res.data)
			if (userFavs) {
				const newFavs = JSON.parse(userFavs);
				newFavs.push({ property_id: res.data.property_id });
				localStorage.setItem('ufv', JSON.stringify(newFavs));
				dispatch({ type: 'LOGIN_USER', payload: { ...JSON.parse(userDetails), favs: newFavs } });
			} else {
				localStorage.setItem('ufv', [JSON.stringify(res.data)]);
				dispatch({ type: 'LOGIN_USER', payload: { ...JSON.parse(userDetails), favs: res.data } });
			}
			return res.status;
		} catch (error) {
			//console.log(error.response)
			return error.response.status;
		}
	};
};

export const removeFavorite = (id) => {
	return async (dispatch) => {
		const userFavs = localStorage.getItem('ufv');
		const userDetails = localStorage.getItem('udt');
		try {
			const res = await base.delete(`favorite/properties/${id}/`);
			if (userFavs) {
				const newFavs = JSON.parse(userFavs).filter((el) => el.property_id !== parseInt(id));
				localStorage.setItem('ufv', JSON.stringify(newFavs));
				dispatch({ type: 'LOGIN_USER', payload: { ...JSON.parse(userDetails), favs: newFavs } });
			}
			return res.status;
		} catch (error) {
			console.log(error);
			return error.response.status;
		}
	};
};

export const authRequest = () => {
	return async (dispatch) => {
		const accessToken = localStorage.getItem('uta');
		const refreshToken = localStorage.getItem('utr');
		if (accessToken && refreshToken) {
			const decodedJwt = JSON.parse(atob(accessToken?.split('.')[1]));
			//console.log(decodedJwt)
			if (decodedJwt.exp * 1000 < Date.now()) {
				dispatch({ type: 'ERROR', payload: 'Session expired. Please login again.' });
				dispatch(signoutUser());
				window.location.assign('/');
			} else {
				getCurrentUser(dispatch);
			}
		} else {
			dispatch({ type: 'NO_USER' });
		}
	};
};

export const getUserData = async () => {
	const token = localStorage.getItem('uta');
	const details = await base.get('/auth/users/me', {
		headers: {
			Authorization: `JWT ${token}`,
		},
	});
	const subs = await base.get(`/register/subscriptions/`, {
		params: {
			user_id: details.data.id,
		},
	});
	if (details.data.role === 'realty_agent' || details.data.role === 'client') {
		const favs = await base.get(`favorite/properties/${details.data.id}`);
		//console.log(details)
		localStorage.setItem('udt', JSON.stringify(details.data));
		localStorage.setItem('ufv', JSON.stringify(favs.data.favorites));
		localStorage.setItem('usub', subs?.data.length > 0 ? JSON.stringify(subs?.data[0]) : '');
		return { ...details.data, favs: favs.data.favorites };
	}
};

export const userLogin = (data) => {
	const { email, password } = data;
	return async (dispatch) => {
		try {
			const token = await axios.post(URL + 'auth/jwt/create/', { email, password }); //request tokens
			const details = await base.get('/auth/users/me', {
				headers: {
					Authorization: `JWT ${token.data.access}`,
				},
			});
			const subs = await base.get(`/register/subscriptions/`, {
				params: {
					user_id: details.data.id,
				},
			});
			if (details.data.role === 'realty_agent' || details.data.role === 'client') {
				localStorage.setItem('uta', token.data.access);
				localStorage.setItem('utr', token.data.refresh);
				const favs = await base.get(`favorite/properties/${details.data.id}`);
				//console.log(details)
				localStorage.setItem('udt', JSON.stringify(details.data));
				localStorage.setItem('ufv', JSON.stringify(favs.data.favorites));
				localStorage.setItem('usub', subs?.data.length > 0 ? JSON.stringify(subs?.data[0]) : '');

				dispatch({
					type: 'LOGIN_USER',
					payload: { ...details.data, refreshToken: token.data.refresh, accessToken: token.data.access, favs: favs.data.favorites },
				});
				dispatch({ type: 'SUCCESS', payload: 'Login successful' });
				return details.status;
			} else {
				dispatch({ type: 'ERROR', payload: 'Sorry, invalid credentials.' });
				return 401;
			}
		} catch (err) {
			//console.log(err.response)
			if (err.response.data?.detail) {
				dispatch({ type: 'ERROR', payload: 'Wrong email or password' });
				// dispatch({type: 'ERROR', payload: err.response.data.detail})
			}
		}
	};
};

export const userSignUp = (data, isAgent) => {
	const { email, password } = data.user;
	return async (dispatch) => {
		try {
			await axios.post(URL + `register/${isAgent ? 'agents' : 'clients'}/`, data);
			const token = await axios.post(URL + 'auth/jwt/create/', { email, password });
			localStorage.setItem('uta', token.data.access);
			localStorage.setItem('utr', token.data.refresh);
			const details = await base.get('/auth/users/me', {
				headers: {
					Authorization: `JWT ${token.data.access}`,
				},
			});
			const favs = await base.get(`favorite/properties/${details.data.id}`);
			localStorage.setItem('udt', JSON.stringify(details.data));
			localStorage.setItem('ufv', JSON.stringify(favs.data.favorites));
			localStorage.setItem('usub', '');
			dispatch({
				type: 'LOGIN_USER',
				payload: { ...details.data, refreshToken: token.data.refresh, accessToken: token.data.access, favs: favs.data.favorites },
			});
			dispatch({ type: 'SUCCESS', payload: 'Account creation successful' });
			return details.status;
		} catch (err) {
			if (err.response.data?.user) {
				for (const item in err.response.data.user) {
					dispatch({ type: 'ERROR', payload: err.response.data.user[item].toString() });
				}
			}
		}
	};
};

export const resetUserPassword = (id, password, token) => {
	return async (dispatch) => {
		axios
			.post(URL + 'auth/users/reset_password_confirm/', {
				uid: id,
				new_password: password,
				token,
			})
			.then(() => {
				dispatch({ type: 'SUCCESS', payload: 'Password reset successfully. Please wait..' });
				setTimeout(() => {
					window.location.assign('/');
				}, 2000);
			})
			.catch((err) => {
				if ('new_password' in err.response?.data || 'uid' in err.response?.data) {
					err.response?.data['uid']?.forEach((item) => {
						dispatch({ type: 'ERROR', payload: item });
						return;
					});
					err.response?.data['new_password']?.forEach((item) => {
						dispatch({ type: 'ERROR', payload: item });
					});
				} else {
					dispatch({ type: 'ERROR', payload: 'Sorry, something went wrong' });
					window.location.assign('/');
				}
			});
	};
};

export const signoutUser = () => {
	return (dispatch) => {
		localStorage.clear();
		dispatch({ type: 'LOGOUT_USER' });
		if (window.location.pathname === '/') {
			window.location.reload();
		} else {
			window.location.assign('/');
		}
	};
};
