import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import AgentPic from "../assets/agentAv.png";
import { formatPhoneNumber } from "../constants/functions";
import _ from "lodash";

const RealtyCard = ({
  name,
  image,
  email,
  phone,
  onClick,
  broker,
  license,
  referral,
  intern,
}) => {
  return (
    <Box sx={{ height: "100%" }}>
      <Card
        elevation={0}
        variant="outlined"
        onClick={onClick}
        sx={{
          cursor: "pointer",
          height: "100%",
          borderRadius: "10px",
          position: "relative",
          border: (theme) =>
            broker ? `2px solid ${theme.palette.primary.main}` : null,
          "&:hover": {
            border: broker ? null : `1px solid #1378A5`,
          },
        }}
      >
        <CardMedia
          component="img"
          height="270"
          image={image ? image : AgentPic}
          alt="realty-agent"
        />
        {broker && (
          <Chip
            size="small"
            sx={{
              position: "absolute",
              top: "3%",
              left: "5%",
              bgcolor: "primary.main",
              fontSize: ".8rem",
              fontWeight: 400,
            }}
            label={"BPO Homes Broker"}
          />
        )}
        {intern && (
          <Chip
            size="small"
            sx={{
              position: "absolute",
              top: "3%",
              left: "5%",
              bgcolor: "primary.main",
              fontSize: ".8rem",
              fontWeight: 400,
            }}
            label={"BPO Homes Intern"}
          />
        )}
        <CardContent>
          <Typography
            variant="h6"
            sx={{ fontWeight: 400, fontSize: "1.3rem" }}
            noWrap
          >
            {_.startCase(name)}
          </Typography>
          <Typography>DRE# {license}</Typography>
          <Typography noWrap sx={{ fontSize: "1rem" }}>
            {email}
          </Typography>
          <Stack direction={"row"} justifyContent={"space-between"} mt={1}>
            <Typography variant="body2" color="GrayText">
              M: {formatPhoneNumber(phone)}
            </Typography>
            {referral && (
              <Chip
                size="small"
                color="primary"
                label={
                  <Typography variant="body2" sx={{ fontSize: ".8rem" }}>
                    Referral Agent
                  </Typography>
                }
              />
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RealtyCard;
