import {
  KeyboardArrowDown,
  Place,
  Search,
  Tune,
  ViewModule,
  List,
  Map,
  Business,
  Close,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Stack,
  InputAdornment,
  TextField,
  styled,
  Typography,
  ButtonGroup,
  Pagination,
  Skeleton,
  Hidden,
  Card,
  Popover,
  CardContent,
  CardActions,
  Divider,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ListingCard from "../components/ListingCard";
import LoadingPropertyItem from "../components/LoadingPropertyItem";
import _, { isArray } from "lodash";
import { grey } from "@mui/material/colors";
import FilterDrawer from "../components/FilterDrawer";
import { convertCurrencySystem } from "../constants/functions";
import { LoadingButton } from "@mui/lab";
import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import { minPrice, maxPrice, cities } from "../constants";
import SingleHouse from "../assets/singlehome.png";
import TownHouse from "../assets/townhouse.png";
import Condo from "../assets/condo.png";
import HomeIMG from "../assets/home.png";
import MarkerIcon from "../assets/mapAsserts/blueCheckedIcon.svg";
import MarkedIcon from "../assets/mapAsserts/redCheckedIcon.svg";
import { isMobile } from "react-device-detect";
import MobileListingPreview from "../components/MobileListingPreview";
import { addSearchOptions, getListingData, getSearchData } from "../actions";
import { listingURL } from "../api/apis";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PriceField = styled(TextField)(({ theme }) => ({
  "input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
  {
    "-webkit-appearance": "none",
  },
  "input[type=number]": {
    "-moz-appearance": "textfield",
  },
}));
const FilterBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "100%",
  borderRadius: "5px",
  color: theme.secondaryColor,
}));
const SearchBtn = styled(LoadingButton)(({ theme }) => ({
  textTransform: "none",
  height: "2.6rem",
  marginRight: "-.9rem",
  background: theme.secondaryColor,
  ":hover": {
    background: theme.secondaryColor,
  },
}));
const MapFrame = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 110px)",
  [theme.breakpoints.down("md")]: {
    height: "calc(100vh - 150px)",
    position: "sticky",
    width: "100%",
  },
  background: "lightgrey",
  position: "sticky !important",
  top: "110px",
}));
const SearchBox = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${grey[300]}`,
  display: "block",
}));

const Rent = (props) => {
  const { hide, show } = props;
  const search = getSearchData();
  const navigate = useNavigate();
  const [view, setView] = useState("map");
  const [boundaries, setBoundaries] = useState();
  const [loading, setLoading] = useState(true);
  const [searchLoad, setSearchLoad] = useState(true);
  const [searchFocus, setSearchFocus] = useState(true);
  const [data, setData] = useState();
  const [filter, setFilter] = useState();
  const [bed, setBed] = useState();
  const [bath, setBath] = useState();
  const [property, setProperty] = useState("");
  const [price, setPrice] = useState({ min: minPrice, max: maxPrice });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openRes, setOpenRes] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [pageCount, setPageCount] = useState(null);
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [preview, setPreview] = useState(false);
  const [listing, setListing] = useState();
  const [center, setCenter] = useState({ lat: 37.33548, lng: -121.893028 });
  const [zoom, setZoom] = useState(12);
  const [listingID, setListingID] = useState();
  const [searchType, setSearchType] = useState();
  const [saleParams, setSaleParams] = useState({ active: true, pending: true });
  const [filteredData, setFilteredData] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showSold, setShowSold] = useState(false);
  const [soldParams, setSoldParams] = useState({
    label: "Last 1 Month",
    value: "30",
  });
  const [filterLabel, setFilterLabel] = useState("For Rent");
  // const [days, setDays] = useState('730')

  const soldFilters = [
    { label: "Last Week", value: "7" },
    { label: "Last 1 Month", value: "30" },
    { label: "Last 3 Months", value: "90" },
    { label: "Last 6 Months", value: "180" },
    { label: "Last 1 Year", value: "365" },
    { label: "Last 2 Years", value: "730" },
  ];

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries: ["places"],
  });

  const propertyBtn = [
    { name: "SingleFamily", img: SingleHouse },
    { name: "Townhouse", img: TownHouse },
    { name: "Condo", img: Condo },
    { name: "Duplex", img: Condo },
  ];

  const polygonStyleOptions = {
    strokeColor: "#1378A5",
    strokeOpacity: 1,
    strokeWeight: 1.3,
    fillColor: "#FFAA01",
    fillOpacity: 0.3,
  };

  const getBoundary = async (value) => {
    let query = "";
    if (isArray(value)) {
      query = value[0]?.split(" ").join("+").toLowerCase();
    } else {
      query = value?.split(" ").join("+").toLowerCase();
    }
    try {
      const res =
        await axios.get(`https://nominatim.openstreetmap.org/search.php?
				city=${query}
				&countrycodes=us
				&polygon_geojson=1
				&format=jsonv2`);

      if (res?.data[0]?.geojson?.type === "Polygon") {
        const boundaries = res?.data[0]?.geojson.coordinates.map((item) =>
          item.map((cor) => ({ lng: +cor[0], lat: +cor[1] }))
        );
        setBoundaries(boundaries);
        return;
      }
      if (res?.data[2]?.geojson?.type === "Polygon") {
        const boundaries = res?.data[0]?.geojson.coordinates[0].map((item) =>
          item.map((cor) => ({ lng: +cor[0], lat: +cor[1] }))
        );
        setBoundaries(boundaries);
        return;
      }
      if (res?.data[0]?.geojson?.type === "MultiPolygon") {
        const boundaries = res?.data[0]?.geojson.coordinates.map((item) =>
          item.flat().map((i) => ({ lng: +i[0], lat: +i[1] }))
        );
        setBoundaries(boundaries);
        return;
      }
    } catch (error) {
      // console.log(error.response)
    }
  };

  // Get latitude & longitude from address.
  const getLocation = async () => {
    const search = getSearchData();
    if (search?.term?.length === 0) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: "sanjose" }, (results, status) => {
        if (status === "OK") {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          setCenter({ lat, lng });
          setZoom(11.3);
          getBoundary("San Jose");
        }
      });
    } else {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: search?.term[search?.term?.length - 1] },
        (results, status) => {
          if (status === "OK") {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            setCenter({ lat, lng });
            setZoom(11.3);
            getBoundary(search?.term?.slice(-1));
          }
        }
      );
    }
  };

  useEffect(() => {
    const searchOptions = {
      term: [],
      type: "rental",
      price: { min: minPrice, max: maxPrice },
      bed: "",
      bath: "",
      property: "",
      pool: "",
      garages: "",
      interior: [],
      area: { min: "", max: "" },
      year: { min: "", max: "" },
      lotsize: { min: "", max: "" },
    };
    localStorage.setItem("search", JSON.stringify(searchOptions));
  }, []);

  useEffect(() => {
    if (isMobile && view === "map") {
      hide();
    }
    if (isMobile && view === "list") {
      show();
    }
  }, [view, hide, show]);

  const fetchData = async (key, days) => {
    const search = getSearchData();
    setData([]);
    setFilteredData([]);
    let query = [];
    if (search?.term?.length > 0) {
      for (let index = 0; index < search?.term.length; index++) {
        query.push(`q=${search?.term[index]?.toLowerCase()}`);
      }
    }
    try {
      setFilteredData([]);
      setLoading(true);
      setSaleParams({ active: true, pending: true });
      const { data: res } = await listingURL.get(
        key === "sale"
          ? `residential/merge_listings/?${search?.term?.length > 0 ? query.join("&") : "q=sanjose"
          }&limit=40`
          : key === "rent"
            ? `rental/merge_listings/?${search?.term?.length > 0 ? query.join("&") : "q=sanjose"
            }&limit=40`
            : `residential/merge_listings/sold/?${search?.term?.length > 0 ? query.join("&") : "q=sanjose"
            }&limit=40${days && `&days=${days}`}`
      );
      // const results = filterListingsWithoutPhotos(res?.data)
      setData(res?.data);
      setFilteredData(res?.data);
      setLoading(false);
      setSearchLoad(false);
      getLocation();
      setPageCount(res?.count);
      handleClose();
    } catch (error) {
      //console.log(error.response)
      setLoading(false);
      setData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    fetchData("rent");
    // eslint-disable-next-line
  }, []);

  const toggleView = (e, val) => {
    setView(val);
  };

  const onSearchClick = async (e) => {
    e.stopPropagation();
    if (value === "") return;
    if (searchResult?.length > 1) {
      setOpenRes(true);
      setSearchFocus(false);
    } else {
      if (searchType === "address") {
        navigate(
          `/listing/${searchResult[0]?.mlsId}/details/${searchResult[0]?.listing === "mls" ? "mls" : "crmls"
          }`
        );
      }
      if (searchType === "city") {
        navigate(
          `/homes-for-sale/${_.startCase(
            searchResult[0]?.address?.city
          )?.toLowerCase()}`
        );
      }
    }
  };

  const searchFilter = async () => {
    window.scrollTo({ top: 0 });
    if (value !== "") {
      search.term = [...search.term, value];
      localStorage.setItem("search", JSON.stringify(search));

      setLoading(true);
      setSearchLoad(true);
      setValue("");
      const { results, count } = await getListingData({
        type: "rental",
        house: "rental",
        value: value,
        term: search.term,
        price: search.price,
        bed: search.bed,
        bath: search.bath,
        property: search.property,
        area: search.area,
        year: search.year,
        page_num: 1,
      });

      getLocation();
      setData(results);
      setPageCount(count);
      setLoading(false);
      setSearchLoad(false);
      handleClose();
      setSearchFocus(false);
    } else {
      return;
    }
  };

  const handleClick = (e, val) => {
    setAnchorEl(e.currentTarget);
    setFilter(val);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilter = (val) => {
    // do something
    if (val === "price") {
      setPrice({ min: minPrice, max: maxPrice });
      search.price = { min: minPrice, max: maxPrice };
      localStorage.setItem("search", JSON.stringify(search));
      return;
    }
    if (val === "bedbath") {
      setBath();
      setBed();
      search.bed = "";
      search.bath = "";
      localStorage.setItem("search", JSON.stringify(search));
      return;
    }
    if (val === "property") {
      setProperty("");
      search.property = "";
      localStorage.setItem("search", JSON.stringify(search));
      return;
    }
    if (val === "all") {
      setPrice({ min: minPrice, max: maxPrice });
      setBath();
      setBed();
      setProperty("");
      const searchOptions = {
        term: [],
        type: "rental",
        price: { min: minPrice, max: maxPrice },
        bed: "",
        bath: "",
        property: "",
        area: { min: "", max: "" },
        year: { min: "", max: "" },
      };
      localStorage.setItem("search", JSON.stringify(searchOptions));
      return;
    }
  };

  const propertyFilter = async (val) => {
    setLoading(true);
    const { results, count } = await getListingData({
      type: "rental",
      house: "rental",
      value: value,
      term: search.term,
      price: search.price,
      bed: search.bed,
      bath: search.bath,
      property: search.property,
      area: search.area,
      year: search.year,
      page_num: 1,
    });
    window.scrollTo(0, 0);
    getLocation();
    setData(results);
    setPageCount(count);
    setLoading(false);
    setSearchLoad(false);
    handleClose();
    setSearchFocus(false);
  };

  const onSearchHandler = (e) => {
    let params = [];
    const search = getSearchData();
    if (e.target.value !== "") {
      setValue(e.target.value);

      // clear timer
      clearTimeout(timer);

      const newTimer = setTimeout(async () => {
        // check for previous search terms
        if (search.term.length > 1) {
          for (let index = 0; index < search.term.length; index++) {
            params.push(`q=${search.term[index]}`);
          }

          setSearchLoad(true);
          const { data: res } = await listingURL.get(
            `rental/merge_listings?${params.join("&")}&limit=5`
          );
          // console.log(res)
          setSearchResult(res?.data);
          setSearchType(res?.name);
          setSearchLoad(false);
          setSearchFocus(true);
        } else {
          const isIncluded = cities.some(
            (el) => el === e.target.value?.toLowerCase()
          );
          const query = isIncluded
            ? e.target.value?.replace(/\s+/g, "")
            : e.target.value; // e.target.value.includes(' ') ? e.target.value?.replace(/\s+/g, '') :
          setSearchLoad(true);
          const { data: res } = await listingURL.get(
            `rental/merge_listings?q=${query.toLowerCase()}&limit=5`
          );
          // console.log(res)
          setSearchResult(res?.data);
          setSearchType(res?.name);
          setSearchLoad(false);
          setSearchFocus(true);
        }
      }, 200);

      // save timerID
      setTimer(newTimer);
    } else {
      setValue("");
      setSearchResult();
    }
  };

  const clickAway = (e) => {
    window.addEventListener("click", function (e) {
      if (document.getElementById("searchbox").contains(e.target)) {
        setSearchFocus(true);
      } else {
        setSearchFocus(false);
      }
    });
  };

  const filterDrawSearch = async () => {
    setLoading(true);
    const { results, count } = await getListingData({
      type: "rental",
      house: "rental",
      value: value,
      term: search.term,
      price: search.price,
      bed: search.bed,
      bath: search.bath,
      property: search.property,
      area: search.area,
      year: search.year,
      page_num: 1,
    });
    window.scrollTo(0, 0);
    getLocation();
    setData(results);
    setPageCount(count);
    setLoading(false);
    setSearchFocus(false);
    return;
  };

  const handleChange = async (e, page) => {
    if (page > 1) {
      setLoading(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      const { results, count } = await getListingData({
        house: "rental",
        type: "rental",
        value: value,
        term: search.term,
        price: search.price,
        bed: search.bed,
        bath: search.bath,
        property: search.property,
        area: search.area,
        year: search.year,
        page_num: page,
        garages: search?.garages,
        pool: search?.pool,
        interior: search?.interior,
        lotsize: search?.lotsize,
      });
      getLocation();
      setData(results);
      setPageCount(count);
      setLoading(false);
      setSearchLoad(false);
      handleClose();
      setSearchFocus(false);
    } else {
      setLoading(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      const { results, count } = await getListingData({
        house: "rental",
        type: "rental",
        value: value,
        term: search.term,
        price: search.price,
        bed: search.bed,
        bath: search.bath,
        property: search.property,
        area: search.area,
        year: search.year,
        page_num: 1,
        garages: search?.garages,
        pool: search?.pool,
        interior: search?.interior,
        lotsize: search?.lotsize,
      });
      getLocation();
      setData(results);
      setPageCount(count);
      setLoading(false);
      setSearchLoad(false);
      handleClose();
      setSearchFocus(false);
    }
  };

  const searchTermDelete = async (val) => {
    //remove search term from
    window.scrollTo({ top: 0 });
    const res = search?.term.filter((el) => el !== val);
    search.term = [...res];
    localStorage.setItem("search", JSON.stringify(search));
    // make query again
    setLoading(true);
    setData([]);
    setSearchLoad(true);
    const { results, count } = await getListingData({
      type: "rental",
      house: "rental",
      value: value,
      term: search.term,
      price: search.price,
      bed: search.bed,
      bath: search.bath,
      property: search.property,
      area: search.area,
      year: search.year,
      page_num: 1,
    });
    setData(results);
    setPageCount(count);
    setLoading(false);
    setSearchLoad(false);
    getLocation();
    handleClose();
    setSearchFocus(false);
  };

  const previewListing = (data) => {
    setListing(data);
    setPreview(true);
  };

  const markerToView = (id) => {
    const element = document.getElementById(`${id}`);
    element.scrollIntoView({
      behavior: "smooth",
      alignToTop: true,
      block: "center",
    });
  };

  const filterBySalesParams = (key, isActive) => {
    if (
      (key === "active" && isActive && saleParams?.pending) ||
      (key === "pending" && isActive && saleParams?.active) ||
      (key === "active" && !isActive && !saleParams?.pending) ||
      (key === "pending" && !isActive && !saleParams?.active)
    ) {
      setFilteredData(data);
      return;
    }
    if (
      (key === "active" && isActive && !saleParams?.pending) ||
      (key === "pending" && !isActive && saleParams?.active)
    ) {
      const filtered = data?.filter(
        (el) => el?.mls?.status?.toLowerCase() === "active"
      );
      setFilteredData(filtered);
      return;
    }
    if (
      (key === "pending" && isActive && !saleParams?.active) ||
      (key === "active" && !isActive && saleParams?.pending)
    ) {
      const filtered = data?.filter(
        (el) => el?.mls?.status?.toLowerCase() !== "active"
      );
      setFilteredData(filtered);
      return;
    }
  };

  return (
    <Box mb={"-6rem"}>
      {/* Filter */}
      <Box
        bgcolor={"#fff"}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 99,
          borderBottom: `1px solid ${grey[200]}`,
        }}
      >
        <Container maxWidth="xl">
          <Box px={0} sx={{ py: { xs: 2, sm: 2, md: 3, lg: 4 } }}>
            <Grid container columnSpacing={3}>
              <Grid
                item
                xs={10}
                sm={10}
                md={9}
                lg={6}
                sx={{ position: "relative" }}
              >
                <Box id="searchbox">
                  <TextField
                    size="small"
                    type={"search"}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        background: "#fff",
                        outline: "none",
                        overflow: "hidden",
                        "&:focus": {
                          borderColor: "red",
                        },
                      },
                    }}
                    fullWidth
                    variant="outlined"
                    onBlur={clickAway}
                    onChange={onSearchHandler}
                    onFocus={() => setSearchFocus(true)}
                    value={value}
                    placeholder="Enter an address, neighborhood, city or zipcode"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {search?.term.length > 0 &&
                            search?.term.map((el, index) => {
                              return (
                                <Chip
                                  key={index}
                                  sx={{ marginRight: "5px" }}
                                  label={_.startCase(el)}
                                  onDelete={() => searchTermDelete(el)}
                                />
                              );
                            })}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchBtn
                            loading={searchLoad}
                            onClick={onSearchClick}
                            fullWidth
                            variant="contained"
                            disableElevation
                          >
                            <Search sx={{ color: "#fff" }} fontSize="small" />
                          </SearchBtn>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {searchResult?.length > 0 && value && searchFocus ? (
                    <Box
                      width={"88%"}
                      bgcolor={"#fff"}
                      sx={{
                        overflowY: "scroll",
                        position: "absolute",
                        zIndex: 99,
                        height: "fit-content",
                        borderRadius: "0 0 8px 8px",
                        boxShadow: "0 2px 20px rgba(0,0,0,15%)",
                      }}
                    >
                      {searchType === "city" ? (
                        <SearchBox>
                          <Typography
                            variant="body2"
                            textAlign={"left"}
                            sx={{
                              ml: "1rem",
                              mt: "1rem",
                              fontWeight: 500,
                              color: grey[600],
                              fontSize: ".8rem",
                            }}
                          >
                            PLACES
                          </Typography>
                          {searchResult?.slice(0, 4)?.map((res, index) => {
                            return (
                              <ListItem
                                onClick={() => {
                                  setValue((prev) => {
                                    const newVal = res?.address?.city;
                                    return newVal;
                                  });
                                  searchFilter(_.startCase(res?.address?.city));
                                }}
                                button
                                key={res.mlsId}
                                sx={{
                                  padding: ".3rem 1.5rem",
                                  alignItems: "flex-start",
                                }}
                              >
                                <ListItemIcon
                                  sx={{ minWidth: "25px", pt: ".6rem" }}
                                >
                                  {
                                    <Place
                                      color="secondary"
                                      sx={{ fontSize: "1rem" }}
                                    />
                                  }
                                </ListItemIcon>
                                <ListItemText>
                                  {index === 0 ? (
                                    <>
                                      <Typography>
                                        {_.startCase(res?.address?.city) +
                                          ", " +
                                          res?.address?.state}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color={"GrayText"}
                                      >
                                        {_.startCase(res?.address?.streetName) +
                                          ", " +
                                          _.startCase(res?.address?.city) +
                                          ", " +
                                          res?.address?.postalCode}
                                      </Typography>
                                    </>
                                  ) : (
                                    <>
                                      <Typography>
                                        {_.startCase(res?.address?.city)}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color={"GrayText"}
                                      >
                                        {_.startCase(res?.address?.streetName) +
                                          ", " +
                                          _.startCase(res?.address?.city) +
                                          ", " +
                                          res?.address?.postalCode}
                                      </Typography>
                                    </>
                                  )}
                                </ListItemText>
                              </ListItem>
                            );
                          })}
                        </SearchBox>
                      ) : (
                        <SearchBox>
                          <Typography
                            variant="body2"
                            textAlign={"left"}
                            sx={{
                              ml: "1rem",
                              mt: "1rem",
                              fontWeight: 500,
                              color: grey[600],
                              fontSize: ".8rem",
                            }}
                          >
                            ADDRESSES
                          </Typography>
                          {searchResult?.slice(0, 4)?.map((res, index) => {
                            return (
                              <ListItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(
                                    `/rental/${res?.mlsId}/details/${res?.listing === "mls" ? "mls" : "crmls"
                                    }`
                                  );
                                }}
                                button
                                key={index}
                                sx={{
                                  padding: ".5rem 1.5rem",
                                  alignItems: "flex-start",
                                }}
                              >
                                <ListItemIcon
                                  sx={{ minWidth: "25px", pt: ".6rem" }}
                                >
                                  {
                                    <Business
                                      color="secondary"
                                      sx={{ fontSize: "1rem" }}
                                    />
                                  }
                                </ListItemIcon>
                                <ListItemText>
                                  <Typography>
                                    {_.startCase(res?.address?.full)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: ".9rem" }}
                                    color={"GrayText"}
                                  >
                                    {_.startCase(res?.address?.city) +
                                      ", " +
                                      _.startCase(res?.address?.state) +
                                      " " +
                                      res?.address?.postalCode}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            );
                          })}
                        </SearchBox>
                      )}
                    </Box>
                  ) : searchResult?.length === 0 && searchFocus ? (
                    <Box
                      mt={"0px"}
                      bgcolor={"#fff"}
                      sx={{
                        overflowY: "scroll",
                        position: "absolute",
                        zIndex: 99,
                        height: "auto",
                        borderRadius: "0 0 8px 8px",
                        boxShadow: "0 2px 20px rgba(0,0,0,15%)",
                        width: { xs: "76%", sm: "86%", md: "86%", lg: "86%" },
                      }}
                    >
                      <SearchBox sx={{ padding: "1rem" }}>
                        <Typography textAlign={"center"}>
                          No results found
                        </Typography>
                      </SearchBox>
                    </Box>
                  ) : null}
                </Box>
              </Grid>
              {/* Filter Buttons */}
              <Grid
                item
                xs={2}
                sm={2}
                md={3}
                lg={6}
                sx={{
                  display: "flex",
                  gap: ".8rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Hidden lgDown>
                  <FilterBtn
                    disableRipple
                    fullWidth
                    endIcon={<KeyboardArrowDown fontSize="small" />}
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => handleClick(e, "type")}
                  >
                    Rental
                  </FilterBtn>
                </Hidden>
                <Hidden mdDown>
                  <FilterBtn
                    disableRipple
                    fullWidth
                    endIcon={<KeyboardArrowDown fontSize="small" />}
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => handleClick(e, "price")}
                  >
                    Price
                  </FilterBtn>
                </Hidden>
                <Hidden lgDown>
                  <FilterBtn
                    disableRipple
                    fullWidth
                    endIcon={<KeyboardArrowDown fontSize="small" />}
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => handleClick(e, "bedbath")}
                  >
                    Beds/Baths
                  </FilterBtn>
                </Hidden>
                <Hidden mdDown>
                  <FilterBtn
                    disableRipple
                    fullWidth
                    endIcon={<KeyboardArrowDown fontSize="small" />}
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => handleClick(e, "property")}
                  >
                    Property
                  </FilterBtn>
                </Hidden>
                <FilterBtn
                  disableRipple
                  fullWidth
                  startIcon={<Tune fontSize="small" />}
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpenDrawer(true)}
                >
                  <Hidden smDown>More</Hidden>
                </FilterBtn>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Listings */}
      <Box sx={{ position: "relative" }}>
        <Hidden mdUp>
          <Button
            disableRipple
            variant="contained"
            color="paper"
            onClick={() => {
              if (view === "map") {
                setView("list");
              }
              if (view === "list") {
                setView("map");
              }
            }}
            sx={{
              position: "fixed",
              bottom: "5%",
              left: "50%",
              "-webkit-transform": "translateX(-50%)",
              transform: "translateX(-50%)",
              zIndex: 20,
              bgcolor: "#fff",
              fontWeight: 400,
            }}
            startIcon={
              view === "list" ? (
                <Map color="secondary" />
              ) : (
                <List color="secondary" />
              )
            }
          >
            {view === "map" ? "List" : "Map"}
          </Button>
        </Hidden>

        <Grid container>
          {/* MOBILE VIEW */}
          <Hidden mdUp>
            {/* Map View */}
            {view === "map" && (
              <Grid item xs={12} sm={12} sx={{ width: "100%" }}>
                <MapFrame id="myMap">
                  {isLoaded && (
                    <GoogleMap
                      mapTypeId="roadmap"
                      mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                      }}
                      center={center}
                      zoom={zoom}
                      clickableIcons={false}
                    >
                      {filteredData?.map((val, index) => {
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: +val?.geo?.lat,
                              lng: +val?.geo?.lng,
                            }}
                            label={{
                              text: convertCurrencySystem(val?.listPrice),
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                            icon={
                              listingID === `${val?.mlsId}`
                                ? MarkedIcon
                                : MarkerIcon
                            }
                            visible={true}
                            onClick={() => previewListing(val)}
                          />
                        );
                      })}
                    </GoogleMap>
                  )}
                </MapFrame>
              </Grid>
            )}

            {/* Listing View */}
            {view === "list" && (
              <Grid item xs={12} sm={12}>
                {/* Property Listing */}
                <Box
                  sx={{
                    padding: {
                      xs: "1rem 1.5rem",
                      sm: "2rem",
                      md: "2rem",
                      lg: "2rem",
                    },
                    overflowY: "scroll",
                    height: "100%",
                    "::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  {loading ? (
                    <Box sx={{ mb: "-2.5rem" }}>
                      <Skeleton height={"3rem"} width={"50%"} />
                      <Skeleton height={"1rem"} width={"30%"} />
                    </Box>
                  ) : (
                    <Box sx={{ mb: "1.5rem", }}>
                      <Typography fontWeight={400} color={'gray'}>Showing ({data?.length}) results</Typography>
                    </Box>
                  )}
                  <Grid container spacing={3}>
                    {loading ? (
                      [
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                      ].map((ld) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={view === "list" ? 6 : 6}
                            md={view === "list" ? 4 : 12}
                            lg={view === "list" ? 3 : 6}
                            key={ld}
                          >
                            <LoadingPropertyItem />
                          </Grid>
                        );
                      })
                    ) : filteredData?.length > 0 ? (
                      filteredData?.map((item) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={view === "list" ? 6 : 6}
                            md={view === "list" ? 4 : 12}
                            lg={view === "list" ? 3 : 6}
                            key={item?.mlsId}
                          >
                            <ListingCard
                              listDate={item?.listDate}
                              mlsId={item?.mlsId}
                              status={"rental"}
                              address={_.startCase(
                                item.address.full +
                                ", " +
                                item.address.city +
                                ", " +
                                item.address.state +
                                item.address.postalCode
                              )}
                              price={item?.listPrice.toLocaleString()}
                              image={item?.photos[0]}
                              bed={item?.property.bedrooms}
                              bath={item?.property.bathsFull}
                              area={item?.property.lotSize}
                              vendor={item?.listing}
                              active={item?.mls?.status}
                            />
                          </Grid>
                        );
                      })
                    ) : filteredData?.length === 0 ? (
                      <Box
                        mt={3}
                        sx={{
                          width: "100%",
                          padding: "3rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <img
                          src={HomeIMG}
                          width="30%"
                          style={{ marginBottom: "1rem" }}
                          alt="home"
                        />
                        <Typography color="GrayText" textAlign={"center"}>
                          Sorry, no listing found for this location
                        </Typography>
                        <Button></Button>
                      </Box>
                    ) : null}
                  </Grid>

                  {/* Pagination */}
                  {filteredData?.length === 0 ? null : (
                    <Box
                      mt={4}
                      mb={4}
                      display="flex"
                      justifyContent={"flex-end"}
                    >
                      <Pagination
                        sx={{
                          "& ul": {
                            marginLeft: "auto",
                          },
                        }}
                        color="secondary"
                        count={Math.ceil(pageCount / 50)}
                        onChange={handleChange}
                        variant="text"
                        shape="rounded"
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          </Hidden>

          {/* SM/MD/LG VIEWS */}
          <Hidden mdDown>
            {/* Map View */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={7}
              sx={{ display: view === "list" && "none", width: "100%" }}
            >
              <MapFrame id="myMap">
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={{
                      height: "100%",
                      width: "100%",
                    }}
                    center={center}
                    zoom={zoom}
                  >
                    {boundaries?.map((bound, index) => {
                      return (
                        <Polygon
                          paths={bound}
                          visible={true}
                          options={polygonStyleOptions}
                          key={index}
                        />
                      );
                    })}

                    {filteredData?.map((val, index) => {
                      return (
                        <Marker
                          key={index}
                          position={{
                            lat: +val?.geo?.lat,
                            lng: +val?.geo?.lng,
                          }}
                          label={{
                            text: convertCurrencySystem(val?.listPrice),
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "900"
                          }}
                          icon={
                            listingID === `${val?.mlsId}`
                              ? MarkedIcon
                              : MarkerIcon
                          }
                          visible={true}
                          onClick={() => {
                            setListingID(`${val?.mlsId}`);
                            markerToView(val?.mlsId);
                          }}
                        />
                      );
                    })}
                  </GoogleMap>
                )}
              </MapFrame>
            </Grid>

            {/* Listing View */}
            <Grid
              item
              xs={12}
              sm={12}
              md={view === "list" ? 12 : 6}
              lg={view === "list" ? 12 : 5}
            >
              {/* Property Listing */}
              <Box
                sx={{
                  padding: {
                    xs: "1rem 1.5rem",
                    sm: "2rem",
                    md: "2rem",
                    lg: "2rem",
                  },
                  overflowY: "scroll",
                  height: "100%",
                  "::-webkit-scrollbar": { display: "none" },
                }}
              >
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: "-2.5rem",
                    }}
                  >
                    <Skeleton height={"3rem"} width={"30%"} />
                    <Skeleton height={"3rem"} width={"30%"} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: "1.5rem",
                    }}
                  >
                    <Typography fontWeight={400} color={'gray'}>
                      Showing ({data?.length}) <Hidden smDown>results</Hidden>
                    </Typography>
                    <ButtonGroup
                      size="small"
                      variant="outlined"
                      color="secondary"
                    >
                      <Button
                        onClick={(e) => toggleView(e, "map")}
                        variant={view === "map" ? "contained" : "outlined"}
                        color="secondary"
                        disableElevation
                        startIcon={<Place fontSize="small" />}
                      >
                        Map
                      </Button>
                      <Button
                        onClick={(e) => toggleView(e, "list")}
                        variant={view === "list" ? "contained" : "outlined"}
                        color="secondary"
                        disableElevation
                        startIcon={<ViewModule fontSize="small" />}
                      >
                        List
                      </Button>
                    </ButtonGroup>
                  </Box>
                )}
                <Grid container spacing={3}>
                  {loading ? (
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                      (ld) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={view === "list" ? 6 : 6}
                            md={view === "list" ? 4 : 12}
                            lg={view === "list" ? 3 : 6}
                            key={ld}
                          >
                            <LoadingPropertyItem />
                          </Grid>
                        );
                      }
                    )
                  ) : filteredData?.length > 0 ? (
                    filteredData?.map((item) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={view === "list" ? 6 : 6}
                          md={view === "list" ? 4 : 12}
                          lg={view === "list" ? 3 : 6}
                          key={item.listingId}
                        >
                          <ListingCard
                            inView={
                              listingID === `${item?.mlsId}` ? true : false
                            }
                            id={`${item?.mlsId}`}
                            listDate={item.listDate}
                            mlsId={item.mlsId}
                            status={"rental"}
                            address={_.startCase(
                              item.address.full +
                              ", " +
                              item.address.city +
                              ", " +
                              item.address.state +
                              item.address.postalCode
                            )}
                            price={item.listPrice.toLocaleString()}
                            image={item.photos[0]}
                            bed={item.property.bedrooms}
                            bath={item.property.bathsFull}
                            area={item.property.lotSize}
                            vendor={item?.listing}
                            active={item?.mls?.status}
                          />
                        </Grid>
                      );
                    })
                  ) : filteredData?.length === 0 ? (
                    <Box
                      mt={3}
                      sx={{
                        width: "100%",
                        padding: "3rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <img
                        src={HomeIMG}
                        width="30%"
                        style={{ marginBottom: "1rem" }}
                        alt="home"
                      />
                      <Typography color="GrayText" textAlign={"center"}>
                        Sorry, no listing found for this location
                      </Typography>
                      <Button></Button>
                    </Box>
                  ) : null}
                </Grid>

                {/* Pagination */}
                {filteredData?.length === 0 ? null : (
                  <Box mt={4} mb={4} display="flex" justifyContent={"flex-end"}>
                    <Pagination
                      sx={{
                        "& ul": {
                          marginLeft: "auto",
                        },
                      }}
                      color="secondary"
                      count={Math.ceil(pageCount / 50)}
                      onChange={handleChange}
                      variant="text"
                      shape="rounded"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Box>

      {/* Popovers for Filters */}
      <Popover
        sx={{ marginTop: ".5rem" }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Card elevation={0}>
          {filter === "type" && (
            <CardContent sx={{ width: "18rem", padding: "1.5rem" }}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterLabel === "For Sale"}
                      disableRipple
                      color="secondary"
                    />
                  }
                  label={"For Sale"}
                  onChange={async () => {
                    setFilterLabel("For Sale");
                    await fetchData("sale");
                    setSoldParams({ label: "Last 1 Month", value: "30" });
                  }}
                />
                <IconButton
                  sx={{ padding: ".5rem" }}
                  onClick={() => setShowMenu(!showMenu)}
                >
                  {showMenu ? (
                    <KeyboardArrowUp fontSize="small" />
                  ) : (
                    <KeyboardArrowDown fontSize="small" />
                  )}
                </IconButton>
              </Stack>
              <Box
                sx={{
                  pl: "2rem",
                  display: !showMenu ? "flex" : "none",
                  flexDirection: "column",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={filterLabel !== "For Sale"}
                      checked={saleParams?.active}
                      sx={{ opacity: 0.6 }}
                      disableRipple
                      color="secondary"
                    />
                  }
                  label={"Active"}
                  onChange={(e) => {
                    setSaleParams((prev) => ({
                      ...prev,
                      active: e?.target?.checked,
                    }));
                    // filter listings using param
                    filterBySalesParams("active", e?.target?.checked);
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={filterLabel !== "For Sale"}
                      checked={saleParams?.pending}
                      sx={{ opacity: 0.6 }}
                      disableRipple
                      color="secondary"
                    />
                  }
                  label={"Pending"}
                  onChange={(e) => {
                    setSaleParams((prev) => ({
                      ...prev,
                      pending: e?.target?.checked,
                    }));
                    // filter listings using param
                    filterBySalesParams("pending", e?.target?.checked);
                  }}
                />
              </Box>
              <Divider light sx={{ my: ".6rem" }} />
              <FormControlLabel
                control={
                  <Radio
                    checked={filterLabel === "For Rent"}
                    disableRipple
                    color="secondary"
                  />
                }
                label={"For Rent"}
                onChange={async () => {
                  setFilterLabel("For Rent");
                  await fetchData("rent");
                }}
              />
              <Divider light sx={{ my: ".6rem" }} />
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterLabel === "Sold"}
                      disableRipple
                      color="secondary"
                    />
                  }
                  label={"Sold"}
                  onChange={async () => {
                    setFilterLabel("Sold");
                    await fetchData("sold", "30");
                  }}
                />
                <IconButton
                  sx={{ padding: ".5rem" }}
                  onClick={() => setShowSold(!showSold)}
                >
                  {showSold ? (
                    <KeyboardArrowUp fontSize="small" />
                  ) : (
                    <KeyboardArrowDown fontSize="small" />
                  )}
                </IconButton>
              </Stack>
              <Box
                sx={{
                  pl: "2rem",
                  display: showSold ? "flex" : "none",
                  flexDirection: "column",
                }}
              >
                {soldFilters?.map((el, i) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={filterLabel !== "Sold"}
                          checked={soldParams?.label === el?.label}
                          sx={{ opacity: 0.6 }}
                          disableRipple
                          color="secondary"
                        />
                      }
                      label={el?.label}
                      onChange={async (e) => {
                        setSoldParams((prev) => ({
                          label: el?.label,
                          value: el?.value,
                        }));
                        // setDays(el?.value)
                        await fetchData("sold", el?.value);
                      }}
                    />
                  );
                })}
              </Box>
            </CardContent>
          )}
          {filter === "price" && (
            <CardContent sx={{ width: "25rem", padding: "2rem" }}>
              {/* <Slider marks color='secondary' disableSwap valueLabelDisplay='auto' step={25000} sx={{mb: '1rem'}} value={[price.min, price.max]} min={minPrice} max={maxPrice} onChange={handleSliderChange} /> */}
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} xs={6}>
                  <PriceField
                    type={"number"}
                    inputProps={{ min: 0 }}
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={price.min}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value);
                      setPrice((prev) => {
                        return { ...prev, min: newValue };
                      });
                      addSearchOptions("minPrice", newValue);
                    }}
                    placeholder="Enter min"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <PriceField
                    type={"number"}
                    inputProps={{ min: 0 }}
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={price.max}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value);
                      setPrice((prev) => {
                        return { ...prev, max: newValue };
                      });
                      addSearchOptions("maxPrice", newValue);
                    }}
                    placeholder="Enter max"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}
          {filter === "bedbath" && (
            <CardContent sx={{ width: "28rem", padding: "2rem" }}>
              <Box mb={"2rem"}>
                <Typography mb={1} sx={{ fontSize: "1rem" }}>
                  Beds
                </Typography>
                <ButtonGroup
                  color="secondary"
                  disableElevation
                  variant="outlined"
                  fullWidth
                >
                  {[1, 2, 3, 4, 5].map((el, index) => {
                    return (
                      <Button
                        disableRipple
                        key={index}
                        onClick={() => {
                          setBed(el);
                          addSearchOptions("bed", el);
                        }}
                        variant={bed === el ? "contained" : "outlined"}
                        disableElevation
                        sx={{ textTransform: "none" }}
                      >
                        {el}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Box>
              <Box>
                <Typography mb={1} sx={{ fontSize: "1rem" }}>
                  Baths
                </Typography>
                <ButtonGroup
                  color="secondary"
                  disableElevation
                  variant="outlined"
                  fullWidth
                >
                  {[1, 2, 3, 4, 5].map((el, index) => {
                    return (
                      <Button
                        disableRipple
                        disableFocusRipple
                        key={index}
                        onClick={() => {
                          setBath(el);
                          addSearchOptions("bath", el);
                        }}
                        variant={bath === el ? "contained" : "outlined"}
                        disableElevation
                        sx={{ textTransform: "none" }}
                      >
                        {el}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Box>
            </CardContent>
          )}
          {filter === "property" && (
            <CardContent sx={{ width: "25rem", padding: "2rem" }}>
              <Grid container spacing={1}>
                {propertyBtn.map((el, index) => {
                  return (
                    <Grid key={index} item lg={3}>
                      <Button
                        disableRipple
                        disableFocusRipple
                        fullWidth
                        color="secondary"
                        onClick={() => {
                          setProperty(
                            el.name === "SingleFamily"
                              ? "singlefamilyresidence"
                              : el.name === "Condo"
                                ? "condominium"
                                : el.name === "Duplex"
                                  ? "duplex"
                                  : el.name === "Townhouse"
                                    ? "townhouse"
                                    : ""
                          );
                          addSearchOptions(
                            "property",
                            el.name === "SingleFamily"
                              ? "singlefamilyresidence"
                              : el.name === "Condo"
                                ? "condominium"
                                : el.name === "Duplex"
                                  ? "duplex"
                                  : el.name === "Townhouse"
                                    ? "townhouse"
                                    : ""
                          );
                        }}
                        variant={
                          property.startsWith(el.name.toLowerCase())
                            ? "contained"
                            : "outlined"
                        }
                        sx={{
                          textTransform: "none",
                          padding: "1rem 0",
                          fontSize: ".8rem",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        disableElevation
                        key={index}
                      >
                        <img
                          width={"22%"}
                          style={{ marginBottom: "-1.1rem" }}
                          src={el.img}
                          alt=""
                        />
                        <br />
                        {el.name}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          )}
          {filter !== "type" && (
            <>
              <Divider sx={{ background: grey[100] }} />
              <CardActions
                sx={{ padding: ".8rem 2rem", justifyContent: "right" }}
              >
                <Button
                  disableRipple
                  color="secondary"
                  sx={{ textTransform: "none" }}
                  onClick={() => resetFilter(filter)}
                >
                  Reset
                </Button>
                <LoadingButton
                  disableRipple
                  loading={loading}
                  variant="contained"
                  disableElevation
                  color="secondary"
                  sx={{ textTransform: "none", color: "#fff", width: "5rem" }}
                  onClick={() => propertyFilter(filter)}
                >
                  Done
                </LoadingButton>
              </CardActions>
            </>
          )}
        </Card>
      </Popover>

      {/* More Filters Drawer */}
      <FilterDrawer
        load={loading}
        bed={bed}
        setBath={setBath}
        bath={bath}
        setBed={setBed}
        property={property}
        setProperty={setProperty}
        price={price}
        setPrice={setPrice}
        open={openDrawer}
        onSearch={filterDrawSearch}
        closeDrawer={() => setOpenDrawer(false)}
        reset={() => resetFilter("all")}
      />

      {/* PROPERTY PREVIEW ON MOBILE */}
      <MobileListingPreview
        listing={listing}
        open={preview}
        closeDrawer={() => setPreview(false)}
      />

      {/* Check for search results */}
      <Dialog open={openRes} maxWidth="sm" fullWidth>
        <DialogContent sx={{ padding: "2rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Do You Mean</Typography>
            <IconButton onClick={() => setOpenRes(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Divider sx={{ my: "10px" }} />
          <Box
            maxHeight={"32rem"}
            sx={{ overflowY: "scroll", minHeight: "fit-content" }}
          >
            {searchType === "city" && (
              <SearchBox>
                <Typography
                  textAlign={"left"}
                  sx={{
                    ml: "1rem",
                    mt: "1rem",
                    fontWeight: 500,
                    color: grey[500],
                  }}
                >
                  Places
                </Typography>
                {searchResult?.slice(0, 5).map((res) => {
                  return (
                    <ListItem
                      component="a"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchFocus(false);
                        setValue((prev) => {
                          const newVal = res?.address?.city;
                          return newVal;
                        });
                        searchFilter(_.startCase(res?.address?.city));
                        setOpenRes(false);
                      }}
                      button
                      key={res.mlsId}
                    >
                      <ListItemIcon sx={{ minWidth: "25px" }}>
                        {<Place sx={{ fontSize: "1rem" }} />}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography>
                          {_.startCase(res.address?.city)}
                        </Typography>
                        <Typography variant="body2" color={"GrayText"}>
                          {_.startCase(res.address?.streetName?.toLowerCase()) +
                            ", " +
                            _.startCase(res.address?.city) +
                            ", " +
                            res.address?.state +
                            " " +
                            res.address?.postalCode}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </SearchBox>
            )}
            {searchType === "address" && (
              <SearchBox>
                <Typography
                  textAlign={"left"}
                  sx={{
                    ml: "1rem",
                    mt: "1rem",
                    fontWeight: 500,
                    color: grey[500],
                  }}
                >
                  Addresses
                </Typography>
                {searchResult?.slice(0, 5).map((res) => {
                  return (
                    <ListItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setSearchFocus(false);
                        setValue("");
                        navigate(
                          `/rental/${res?.mlsId}/details/${res?.listing === "mls" ? "mls" : "crmls"
                          }`
                        );
                        setOpenRes(false);
                      }}
                      button
                      key={res?.mlsId}
                      sx={{ padding: ".5rem 1.5rem", alignItems: "flex-start" }}
                    >
                      <ListItemIcon sx={{ minWidth: "25px", pt: ".6rem" }}>
                        {
                          <Business
                            color="secondary"
                            sx={{ fontSize: "1rem" }}
                          />
                        }
                      </ListItemIcon>
                      <ListItemText>
                        <Typography>
                          {_.startCase(res?.address?.full)}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: ".9rem" }}
                          color={"GrayText"}
                        >
                          {_.startCase(res?.address?.city) +
                            ", " +
                            _.startCase(res?.address?.state) +
                            " " +
                            res?.address?.postalCode}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </SearchBox>
            )}

            <Typography
              mt={2}
              textAlign={"center"}
              sx={{ fontSize: ".8rem" }}
              variant="body2"
            >
              Don't see what you're looking for? <br />
              Your search might be outside our service areas.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Rent;
