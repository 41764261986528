import { Business, Place, Restore, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import {
  Box,
  Button,
  Chip,
  Divider,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { grey } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router-dom";
import base, { listingURL } from "../../../api/apis";

const InputField = styled(TextField)(({ theme }) => ({
  margin: ".7rem 0",

  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    background: "#fff",
  },
}));

const SearchBox = styled(Box)(({ theme }) => ({
  borderBottom: `0px solid ${grey[300]}`,
  display: "block",
}));
export const SearchComp = ({
  load,
  clickAway,
  onChange,
  focus,
  value,
  searchFilter,
  results,
  searchFocus,
  searchTerm,
  type,
  recent,
}) => {
  const navigate = useNavigate();

  const allResults =
    results?.length !== 0
      ? results
      : JSON.parse(localStorage.getItem("places"));

  // get users location if they accept permission
  function getGeoLocation() {
    const geoAlertText =
      "Geolocation is not supported by your browser or it is disabled. Please check your settings. ";

    async function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      try {
        const { data } = await base.post('/bpo_listings/get-location/', {
          latitude,
          longitude,
        });
        await listingURL.post(`search-upload/`, {
          search: data?.city
        });
        navigate(
          `/homes-for-sale/${_.startCase(
            data?.city
          )?.toLowerCase()}`
        );

      } catch (error) { }
    }
    function error(message) {
      alert(geoAlertText);
    }
    if (!navigator?.geolocation) {
      alert(geoAlertText);
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  return (
    <>
      <form onSubmit={searchFilter}>
        <InputField
          type={"search"}
          id="searchbox"
          variant="outlined"
          sx={{ zIndex: 10 }}
          placeholder="Enter an address, city, neighborhood or zip code"
          onBlur={clickAway}
          onChange={onChange}
          onFocus={focus}
          value={searchTerm}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  onClick={searchFilter}
                  disableElevation
                  loading={load}
                  sx={{
                    padding: ".7rem",
                    borderRadius: "10px",
                    marginRight: "-8px",
                    "&:hover": {
                      bgcolor: "primary.main",
                    },
                  }}
                >
                  <Search color="paper" />
                </LoadingButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
      {/* Location Suggestion */}
      {searchFocus && !searchTerm && (
        <Box
          bgcolor={"white"}
          sx={{
            width: "100%",
            mt: "-10px",
            borderRadius: 1,
            position: "absolute",
          }}
        >
          <Button
            onClick={getGeoLocation}
            fullWidth
            sx={{
              textTransform: "none",
              textAlign: "start",
              justifyContent: "start",
            }}
            startIcon={<NearMeOutlinedIcon />}
          >
            {" "}
            Search your Location{" "}
          </Button>
        </Box>
      )}

      {allResults?.length > 0 && value.length > 0 && searchFocus ? (
        <Box
          mt={"-18px"}
          bgcolor={"#fff"}
          sx={{
            overflowY: "scroll",
            position: "absolute",
            pt: ".5rem",
            zIndex: 9,
            height: "fit-content",
            borderRadius: "0 0 8px 8px",
            boxShadow: "0 2px 20px rgba(0,0,0,15%)",
            width: { xs: "76%", sm: "86%", md: "86%", lg: "37.5rem" },
          }}
        >
          {type === "city" ? (
            <SearchBox>
              <Typography
                variant="body2"
                textAlign={"left"}
                sx={{
                  ml: "1rem",
                  mt: "1rem",
                  fontWeight: 500,
                  color: grey[600],
                  fontSize: ".8rem",
                }}
              >
                PLACES
              </Typography>
              {allResults?.slice(0, 4)?.map((res, index) => {
                return (
                  <ListItem
                    onClick={async (e) => {
                      e.stopPropagation();
                      await listingURL.post(`search-upload/`, {
                        search: res?.address?.city || res?.name,
                      });
                      navigate(
                        `/homes-for-sale/${_.startCase(
                          res?.address?.city || res?.name
                        )?.toLowerCase()}`
                      );
                    }}
                    button
                    key={index}
                    sx={{ padding: ".3rem 1.5rem", alignItems: "flex-start" }}
                  >
                    <ListItemIcon sx={{ minWidth: "25px", pt: ".6rem" }}>
                      {<Place color="secondary" sx={{ fontSize: "1rem" }} />}
                    </ListItemIcon>
                    <ListItemText>
                      {index === 0 ? (
                        <>
                          <Typography>
                            {res?.address
                              ? _.startCase(res?.address?.city) +
                              ", " +
                              _.startCase(res?.address?.state)
                              : res?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "0.85rem" }}
                            color={"GrayText"}
                          >
                            {res?.address
                              ? _.startCase(res?.address?.city) +
                              ", " +
                              _.startCase(res?.address?.state) +
                              ", USA"
                              : res?.formatted_address}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography>
                            {res?.address
                              ? _.startCase(res?.address?.city)
                              : res?.name}
                          </Typography>
                          <Typography variant="body2" color={"GrayText"}>
                            {res?.address
                              ? _.startCase(
                                res.address?.crossStreet?.toLowerCase()
                              ) +
                              " " +
                              _.startCase(res.address?.city) +
                              ", " +
                              _.startCase(res.address?.state) +
                              ", USA"
                              : res?.formatted_address}
                          </Typography>
                        </>
                      )}
                    </ListItemText>
                  </ListItem>
                );
              })}
            </SearchBox>
          ) : (
            <SearchBox>
              <Typography
                variant="body2"
                textAlign={"left"}
                sx={{
                  ml: "1rem",
                  mt: "1rem",
                  fontWeight: 500,
                  color: grey[600],
                  fontSize: ".8rem",
                }}
              >
                ADDRESSES
              </Typography>
              {allResults?.slice(0, 4)?.map((res, index) => {
                return (
                  <ListItem
                    onClick={async (e) => {
                      e.stopPropagation();
                      await listingURL.post(`search-upload/`, {
                        search: res?.address?.full || res?.name,
                      });
                      navigate(
                        `/listing/${res?.mlsId}/details/${res?.listing === "mls" ? "mls" : "crmls"
                        }`
                      );
                    }}
                    button
                    key={index}
                    sx={{ padding: ".5rem 1.5rem", alignItems: "flex-start" }}
                  >
                    <ListItemIcon sx={{ minWidth: "25px", pt: ".6rem" }}>
                      {<Business color="secondary" sx={{ fontSize: "1rem" }} />}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>
                        {res?.address
                          ? _.startCase(res?.address?.full)
                          : res?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: ".9rem" }}
                        color={"GrayText"}
                      >
                        {res?.address
                          ? _.startCase(res?.address?.city) +
                          ", " +
                          _.startCase(res?.address?.state) +
                          " " +
                          res?.address?.postalCode
                          : res?.formatted_address}
                      </Typography>
                    </ListItemText>
                    {res?.mls?.status && (
                      <Chip
                        size="small"
                        color={
                          res?.mls?.status?.toLowerCase() === "sold" ||
                            res?.mls?.status?.toLowerCase() === "closed"
                            ? "error"
                            : "default"
                        }
                        sx={{ ml: "auto" }}
                        label={res?.mls?.status}
                      />
                    )}
                  </ListItem>
                );
              })}
            </SearchBox>
          )}

          {/* Recent Search */}
          <Divider light />
          <SearchBox sx={{ padding: "0 1rem", mb: "1rem" }}>
            <Typography
              variant="body2"
              textAlign={"left"}
              sx={{
                ml: "1rem",
                mt: "1rem",
                mb: "1rem",
                fontWeight: 500,
                color: grey[600],
                fontSize: ".8rem",
              }}
            >
              RECENT SEARCH
            </Typography>
            {recent?.slice(0, 2)?.map((res, index) => {
              return (
                <ListItem
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/homes-for-sale/${res?.search}`);
                  }}
                  button
                  key={index}
                  sx={{ padding: ".5rem 1.5rem", alignItems: "flex-start" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px", pt: ".4rem" }}>
                    {<Restore color="secondary" sx={{ fontSize: "1.1rem" }} />}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">
                      {_.startCase(res?.search)}
                    </Typography>
                    {/* <Typography variant='body2' sx={{fontSize: '.9rem'}} color={'GrayText'}>{_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state) + ' ' + res?.address?.postalCode}</Typography> */}
                  </ListItemText>
                </ListItem>
              );
            })}
          </SearchBox>
        </Box>
      ) : allResults?.length === 0 && searchFocus ? (
        <Box
          mt={"-10px"}
          bgcolor={"#fff"}
          sx={{
            overflowY: "scroll",
            position: "absolute",
            zIndex: 99,
            height: "auto",
            borderRadius: "0 0 8px 8px",
            boxShadow: "0 2px 20px rgba(0,0,0,15%)",
            width: { xs: "76%", sm: "86%", md: "86%", lg: "86%" },
          }}
        >
          <SearchBox sx={{ padding: "1rem" }}>
            <Typography textAlign={"center"}>No listing found</Typography>
          </SearchBox>
          <SearchBox sx={{ padding: "1rem", mb: "1rem" }}>
            <Typography
              variant="body2"
              textAlign={"left"}
              sx={{
                ml: "1rem",
                mt: "1rem",
                fontWeight: 500,
                color: grey[600],
                fontSize: ".8rem",
              }}
            >
              RECENT SEARCH
            </Typography>
            {recent?.slice(0, 2)?.map((res, index) => {
              return (
                <ListItem
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/homes-for-sale/${res?.search}`);
                  }}
                  button
                  key={index}
                  sx={{ padding: ".5rem 1.5rem", alignItems: "flex-start" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px", pt: ".4rem" }}>
                    {<Restore color="secondary" sx={{ fontSize: "1.1rem" }} />}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">
                      {_.startCase(res?.search)}
                    </Typography>
                    {/* <Typography variant='body2' sx={{fontSize: '.9rem'}} color={'GrayText'}>{_.startCase(res?.address?.city) + ', ' + _.startCase(res?.address?.state) + ' ' + res?.address?.postalCode}</Typography> */}
                  </ListItemText>
                </ListItem>
              );
            })}
          </SearchBox>
        </Box>
      ) : null}
    </>
  );
};
