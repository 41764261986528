import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Container, FormLabel, Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '2rem'
  },
  wrap: {
    boxShadow: '0 2px 5px rgba(0,0,0, 15%)',
    marginTop: '3rem',
    height: 'fit-content'
  },
  field: {
    marginTop: '.5rem'
  },
  card: {
    boxShadow: '0 2px 5px rgba(0,0,0, 10%)',
  }
}))

const BPOSignup = () => {
  const classes = useStyles()
  const [title, setTitle] = useState('Office Location')
  const [page, setPage] = useState(0)

  // const stepBack = () => {
  //   if(page === 1){
  //     setPage(0)
  //     setTitle('Office Location')
  //   }
  //   if(page === 2){
  //     setPage(1)
  //     setTitle('Agent Info')
  //   }
  // }

  const formSubmit = () => {
    if(page === 0){
      setPage(1)
      setTitle('Agent Info')
    }
    if(page === 1){
      setPage(2)
      setTitle('Select Your Option')
    }
  }
  return (
    <div>
      <Container>
        <Box>
            <Typography textAlign={'center'} variant='h5' color='secondary' sx={{marginTop: '4rem'}}>BPO Homes Realty Sign Up</Typography>
        </Box>

        <Box padding={'4rem'} className={classes.wrap}>
            <Typography color={'secondary'} className={classes.title} variant='h6' textAlign={'center'}>{title}</Typography>
            <Box width={'90%'} margin={'0 auto'}>
              {
                page === 0 &&
                <form>
                  <FormLabel sx={{marginBottom: '1rem'}} >Select Office Satellite Location</FormLabel>
                  <TextField fullWidth variant='outlined' placeholder='Filter By Location' select defaultValue={''} value={''} />
                  <Button variant='contained' color='secondary' disableElevation 
                  sx={{display: 'block', textTransform: 'none', width: '20rem', margin: '1rem auto', height: '3rem'}}
                  onClick={formSubmit}>Continue</Button>
                </form>
              }

              {
                page === 1 &&
                  <form>
                  <Grid container spacing={4}>
                    <Grid item sm={6} >
                      <FormLabel>First Name</FormLabel>
                      <TextField className={classes.field} fullWidth variant='outlined' placeholder='Enter First Name' />
                    </Grid>
                    <Grid item sm={6} >
                      <FormLabel>Last Name</FormLabel>
                      <TextField className={classes.field} fullWidth variant='outlined' placeholder='Enter First Name' />
                    </Grid>
                    <Grid item sm={6} >
                        <FormLabel>License Number</FormLabel>
                      <TextField className={classes.field} fullWidth variant='outlined' placeholder='Enter First Name' />
                    </Grid>
                    <Grid item sm={6} >
                      <FormLabel>Address</FormLabel>
                      <TextField className={classes.field} fullWidth variant='outlined' placeholder='Address' />
                    </Grid>
                    <Grid item sm={6} >
                      <FormLabel>Email Address</FormLabel>
                      <TextField className={classes.field} fullWidth variant='outlined' placeholder='Email Address' />
                    </Grid>
                    <Grid item sm={6} >
                      <FormLabel>Phone Number</FormLabel>
                      <TextField className={classes.field} fullWidth variant='outlined' placeholder='Phone Number' />
                    </Grid>
                    <Button variant='contained' color='secondary' disableElevation 
                    sx={{display: 'block', textTransform: 'none', width: '20rem', margin: '2rem auto', height: '3rem'}}
                    onClick={formSubmit}>Continue</Button>
                  </Grid>
                  </form>
              }
              {
                page === 2 &&
                <Box>
                    <Typography textAlign={'center'} sx={{fontWeight: 600}} variant='h6'>100% Commission</Typography>
                    <Typography textAlign={'center'}>(Flat Fee per Transaction)</Typography>
                    <Typography variant='body2' textAlign={'center'}>(Agent Transaction Fee Capped upon 10 yearly Transactions)</Typography>
                    {/* SUbscription Cards */}
                    <Box width={'80%'} margin={'2rem auto'}>
                      <Grid container spacing={4} >
                        <Grid item sm={6}>
                          <Card variant='outlined' className={classes.card}>
                            <CardContent style={{padding: '2rem', textAlign: 'center'}}>
                                <Typography  sx={{fontWeight: 600}} variant='h6'>BPO Agent</Typography>
                                <Typography>Option 1</Typography>
                                <KeyboardArrowDown fontSize='small' sx={{margin: '1rem 0'}} />
                                <Typography style={{fontWeight: 600}} variant='h5'>$149.00</Typography>
                                <Typography variant='body2' color={'textSecondary'}>per month</Typography>
                                
                                <Typography sx={{fontWeight: 400, margin: '1rem 0'}} variant='body1'>&</Typography>
                                <Typography style={{fontWeight: 600}} variant='h5'>$299.00</Typography>
                                <Typography variant='body2' color={'textSecondary'}>per transaction</Typography>
                                <Button size='large' variant='contained' disableElevation color='primary' fullWidth sx={{textTransform: 'none', height: '3rem', marginTop: '1rem'}} >Subscribe</Button>

                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item sm={6}>
                          <Card variant='outlined' className={classes.card}>
                            <CardContent style={{padding: '2rem', textAlign: 'center'}}>
                                <Typography  sx={{fontWeight: 600}} variant='h6'>BPO Agent</Typography>
                                <Typography>Option 2</Typography>
                                <KeyboardArrowDown fontSize='small' sx={{margin: '1rem 0'}} />
                                <Typography sx={{fontWeight: 600}} variant='h5'>$129.00</Typography>
                                <Typography variant='body2' color={'textSecondary'}>per month</Typography>

                                <Typography sx={{fontWeight: 400, margin: '1rem 0'}} variant='body1'>&</Typography>
                                <Typography style={{fontWeight: 600}} variant='h5'>$399.00</Typography>
                                <Typography variant='body2' color={'textSecondary'}>per transaction</Typography>
                                <Button variant='contained' disableElevation color='primary' fullWidth sx={{textTransform: 'none', height: '3rem', marginTop: '1rem'}} >Subscribe</Button>

                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                </Box>
              }

            </Box>
        </Box>
      </Container>
    </div>
  )
}

export default BPOSignup