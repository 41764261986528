export const buyerFee = [
 {
  "id":"1",
   "Market": "All other",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"2",
   "Market": "Anchorage",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"3",
   "Market": "Atlanta",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"4",
   "Market": "Austin",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"5",
   "Market": "Birmingham",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"6",
   "Market": "Boise",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"hisuh6",
   "Market": "Boston",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$5,000"
 },
 {
  "id":"12",
   "Market": "Buffalo",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"14",
   "Market": "Charlotte",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1kua2",
   "Market": "Chattanooga",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1kuha32",
   "Market": "Chicago",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"12ai3o",
   "Market": "Cincinnati",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1uh8232",
   "Market": "Cleveland",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1oihow32",
   "Market": "Colorado Rockie",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1iho232",
   "Market": "Columbus",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"16832",
   "Market": "Connecticut",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"12ij23",
   "Market": "Dallas",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1kjuhw32",
   "Market": "DC",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$6,000"
 },
 {
  "id":"1sk32",
   "Market": "Denver",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1ij32",
   "Market": "Des Monies",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1lso347",
   "Market": "Detroit",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1ijps39",
   "Market": "El Paso",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1ljsio341",
   "Market": "Fayetteville",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"15",
   "Market": "Flagstaff",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1slihj343",
   "Market": "Florida Panhandle",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"2kjos32",
   "Market": "Fort Myers",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"21",
   "Market": "Grand Rapids",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"142",
   "Market": "Greater British Columbia",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"102",
   "Market": "Greater Ontario",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"132",
   "Market": "Hampton Roads",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"192",
   "Market": "Hawaii",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$6,000"
 },
 {
  "id":"12480",
   "Market": "Houston",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1242",
   "Market": "Indianapolis",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1432",
   "Market": "Inland Empire",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$5,000"
 },
 {
  "id":"1562",
   "Market": "Jackson",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1342",
   "Market": "Jacksonville",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"128u32",
   "Market": "Kanas City",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1092",
   "Market": "Knoxville",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1232",
   "Market": "Lake Tahoe",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1132",
   "Market": "Las Vegas",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"14642",
   "Market": "Little Rock",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"14232",
   "Market": "Los Angeles",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$7,000"
 },
 {
  "id":"12342",
   "Market": "Lousiana",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"190122",
   "Market": "Louisville",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1bs82b2",
   "Market": "Maryland",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1skh32",
   "Market": "Memphis",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1i2h3i2",
   "Market": "Miami",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1usu32",
   "Market": "Minneapolis",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"123b4r2",
   "Market": "Nashville",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1ug322",
   "Market": "Nebraska",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1uyg232",
   "Market": "New Jersey-North",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1o;232",
   "Market": "New Jersey-South",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1ouh32",
   "Market": "New Mexico",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1;sijh32",
   "Market": "New York",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$5,000"
 },
 {
  "id":"1sluh32",
   "Market": "Northern New Englan",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1skjhu32",
   "Market": "Oklahoma",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1ihso2",
   "Market": "Orange Count",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$7,000"
 },
 {
  "id":"1no2",
   "Market": "Orlando",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1sd2",
   "Market": "Ottawa",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"122r",
   "Market": "Palm Beach",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1sdo2",
   "Market": "Palm Springs",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"12",
   "Market": "Philadelphia",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1sod2",
   "Market": "Phoenix",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1sej2",
   "Market": "Pittsburgh",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,000"
 },
 {
  "id":"1sljse2",
   "Market": "Portland",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1so3n2",
   "Market": "Raleigh",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1sok32",
   "Market": "Rhole Island",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1se2",
   "Market": "Richmond ",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"1slije2",
   "Market": "Sacramento",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1s32",
   "Market": "Salt Lake City",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"1sje2",
   "Market": "San Antonio",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1342",
   "Market": "San Diego",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$6,000"
 },
 {
  "id":"1332",
   "Market": "San Francisco",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$7,500"
 },
 {
  "id":"1222",
   "Market": "Savannah",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1112",
   "Market": "Seattle ",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$5,000"
 },
 {
  "id":"1002",
   "Market": "South Carolina",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1992",
   "Market": "SouthTexas ",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1882",
   "Market": "Spokane",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$4,000"
 },
 {
  "id":"17232",
   "Market": "St. Louis ",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$2,500"
 },
 {
  "id":"1so32",
   "Market": "Tampa ",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"19u22",
   "Market": "Toronto",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$5,000"
 },
 {
  "id":"109u23n2",
   "Market": "Tucson",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 },
 {
  "id":"19uso2",
   "Market": "Vancouver",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$6,000"
 },
 {
  "id":"123i342",
   "Market": "Virginia",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$5,000"
 },
 {
  "id":"1pos93",
   "Market": "Wisconsin",
   "Listing Fee": "1.50%",
   "Minimum Commision": "$3,000"
 }
]