import { Circle } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, Container, Divider, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Double from '../../assets/Double.jpg'
import Kitchen4 from '../../assets/kitchen4.jpg'
import Bedroom5 from '../../assets/bedroom5.jpg'
import Perfect from '../../assets/perfect.jpg'
import { scrollUp } from '../../constants'
import SEO from '../../components/SEO'

const useStyles = makeStyles(theme => ({
    banner : {
        height: '60vh',
        backgroundImage: `url(${Double})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    title: {
        fontWeight: 600,
        marginBottom: '1rem'
    },
}))


const DoublePaneWindows = () => {
    const classes = useStyles()
    const navigate = useNavigate()

    const areas = [
        'Window Installation: Professional installation is highly recommended to ensure proper coverage and an airtight seal.',
        'Double Pane Maintenance: Cleaning should be done regularly to keep dirt buildup down and seals intact.',
        'Glazing Requirements: All components must meet certain guidelines in order for your window system to work properly.',
        'Insulation Factors: The right type of glass is essential for keeping temperatures consistent throughout the year.'
    ]


  return (
    <div>
            <SEO>
				<title>The Benefits Of Upgrading To Double Pane Windows</title>
				<meta name='og:title' content="The Benefits Of Upgrading To Double Pane Windows" />
				<meta
					name='description'
					content=""
				/>
				<meta
					name='og:description'
					content=""
				/>
			</SEO>
        <Box className={classes.banner}></Box>
        <Box sx={{background: (theme)=> theme.secondaryColor, padding: '4rem',color: '#fff'}}>
            <Typography textAlign={'center'} gutterBottom variant='h4'>The Benefits Of Upgrading To Double Pane Windows</Typography>
            <Divider sx={{width: '7rem',height: '3px', margin: '1rem auto', background: (theme) => theme.primaryColor}} />
        </Box>

        <Box padding={'3rem 0'}>
            <Container>
                <Box mt={5}>
                    <Typography paragraph mb={5}>
                        Are you ready to upgrade your windows? If so, double pane windows are an excellent choice. Not only will they make your home look more attractive and modern, but they can also save you money in the long run. In this article, we’ll discuss some of the primary benefits of upgrading to double pane windows.
                    </Typography>
                    <Typography paragraph>
                        For starters, let’s talk about energy efficiency and cost savings. Double paned windows provide a better barrier against cold air coming in from outside during winter months, which helps reduce your home heating bills. Moreover, because these windows block heat from entering your home in summertime, it reduces how much energy is needed for cooling as well! This makes double panes an incredibly efficient way to keep your home comfortable all year round—and save on monthly utilities costs too.
                    </Typography>
                    <Typography paragraph>
                        Finally, there’s added comfort that comes with double pane windows. With two layers of glass separating the interior of your home from the outdoors, you get extra soundproofing that greatly reduces noise pollution coming into your living space. All this adds up to improved sleep quality at night and fewer distractions while working or studying during daytime hours!
                    </Typography>
                    <Typography paragraph>
                        By now you’re probably itching to learn even more about what double pane windows have to offer. Keep reading for further details on why investing in new window upgrades could be a great decision for both short-term and long-term benefits!
                    </Typography>


                    <Typography className={classes.title} variant='h6'>Advantages Of Double Pane Windows</Typography>
                    <Typography paragraph>
                        Upgrading to double pane windows is one of the best investments you can make for your home. Not only do they offer superior energy savings, but their insulation value and noise reduction abilities are unparalleled. When it comes to window replacement, double pane windows are a no-brainer.
                    </Typography>
                    <Typography paragraph>
                        The primary benefit of having double pane windows in your home is the remarkable amount of energy they help conserve. During cold winter months, these insulated panes reduce heat loss from inside your home dramatically more than single paned windows ever could. They also offer an exceptional level of protection against damaging UV rays that come through regular glass during hot summer days. Furthermore, air infiltration is significantly reduced when compared with traditional single paned models – meaning less drafts and better temperature control throughout your house year round.
                    </Typography>
                    <Typography paragraph>
                        If that wasn’t enough to convince you, there’s even more: double pane windows have amazing insulation capabilities which help keep sound out from outside sources such as traffic or construction work. All this adds up to provide homeowners with greater comfort and peace of mind when living in their homes. The benefits provided by upgrading to double pane windows are simply too good to pass up!
                    </Typography>


                    <Typography className={classes.title} variant='h6'>Energy Efficiency Considerations</Typography>
                    <Typography paragraph>
                        Aside from the comfort and noise reduction advantages that come with double pane windows, energy efficiency is another major factor to consider. Thanks to their superior insulation capabilities and airtight seal, these windows help drastically reduce heat loss during cold seasons, leading to a lower energy bill in the process. This means you can enjoy all of winter’s cozy charms without breaking your bank account.
                    </Typography>

                    <Typography paragraph>
                        The same goes for hot summer days as well – double pane windows are designed to keep cool air in while blocking out damaging UV rays before they ever have a chance to enter your home. On top of this, since temperature control has been improved significantly with insulated panes, you won’t need to rely on expensive climate control systems as much either! All-in-all, it’s no surprise why so many people are opting for double pane windows when it comes time for window replacement or upgrade projects.
                    </Typography>
                    <Typography paragraph>
                        In summary: Double pane windows offer numerous benefits not found in single paned models and should be seriously considered by any homeowner looking for added value and lower energy bills over the long term. Their excellent insulation properties make them ideal for keeping both sound and temperature levels consistent throughout the year - delivering outstanding results at an affordable cost.
                    </Typography>

                    <Typography className={classes.title} variant='h6'>Installation And Maintenance Requirements</Typography>
                    <Typography paragraph>
                        Now that you understand the advantages of a double pane window, let’s look at what it takes to install and maintain these impressive pieces of engineering. Installing them is no easy task – professional glazing teams are required for best results – but with their help, windows can be installed quickly and efficiently. As far as maintenance goes, not much is needed beyond periodic cleaning; just make sure all seals on your window frames remain tight to ensure maximum insulation factors!
                    </Typography>
                    <Typography paragraph>
                        Here are some important considerations when dealing with double pane windows:
                    </Typography>
                    <Grid container>
                        {
                            areas?.map((el, index) => {
                                return(
                                    <Grid item sm={6} key={index}>
                                        <ListItem>
                                            <ListItemIcon sx={{minWidth: '35px'}}><Circle sx={{fontSize: '.7rem'}} color='secondary' /></ListItemIcon>
                                            <ListItemText>{el}</ListItemText>
                                        </ListItem>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                    <Typography paragraph>
                        All things considered, double pane windows offer countless benefits over single paned models - so if you’re looking for a way to upgrade the value or energy efficiency of your home, this could be one route worth exploring! With the right team behind it, installing new windows doesn’t have to break the bank either - making it a great choice for budget-conscious homeowners who want top tier results without sacrificing quality.

                    </Typography>
                    

                    <Typography className={classes.title} variant='h6'>Cost Analysis</Typography>
                    <Typography paragraph>
                        It’s important to consider the cost of double pane windows when making your decision. Let’s break down the costs associated with this type of upgrade so you can make an informed choice about what’s best for your home.
                    </Typography>

                    <Typography paragraph>
                        First, installation: depending on the size and number of windows being replaced, professional glazing teams may charge anywhere from $400-$1,000 per window - though discounts are often available if multiple windows need replacing at once. The good news is that once installed, these windows will pay themselves off in no time thanks to their impressive energy savings capabilities!
                    </Typography>
                    <Typography paragraph>
                        When it comes to maintenance costs, there isn’t much need beyond occasional cleaning. As long as all seals remain intact and free from dirt buildup, you should expect little more than minimal upkeep over time - which makes them a great value for budget-conscious homeowners who want maximum efficiency without breaking the bank!

                    </Typography>

                    <Typography paragraph>
                        Considering both upfront installation fees and long-term energy savings benefits, investing in double pane windows could be one of the smartest decisions you ever make. After comparing all options carefully, you’ll have a better idea of whether or not this route is worth pursuing – but even if it ends up costing more upfront, the return on investment could easily outweigh any initial expenses!

                    </Typography>

                    <Typography className={classes.title} variant='h6'>Conclusion</Typography>
                    <Typography paragraph>
                        The advantages of double pane windows are clear. If you’re looking for improved energy efficiency and lower utility bills, then the installation of these windows is definitely worth considering. The cost can vary depending on size and quality, but generally speaking, it’s a worthwhile investment that will pay off in the long run.
                    </Typography>

                    <Typography paragraph>
                        When you factor in all the benefits – from greater comfort to increased savings – upgrading to double pane windows makes total sense. Not only does this upgrade add value to your home, but it also helps reduce your carbon footprint by cutting down on energy use.
                    </Typography>

                    <Typography paragraph>
                        Overall, I highly recommend making the switch to double pane windows if you want an easy way to make your home more efficient and comfortable while saving money at the same time. You won’t regret investing in these upgrades!

                    </Typography>
                   
                </Box>
            </Container>
        </Box>

            <Box mt={4} mb={5}>
                <Container>
                    
                    <Typography variant='h6' mt={2} mb={3}>Read Also:</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/perfect-design')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Perfect})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Creating The Perfect Master Suite</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Have you ever dreamed of having a luxurious master suite that’s the envy...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/maximizing-space')
                                scrollUp()
                              }}>
                                <CardHeader style={{backgroundImage: `url(${Bedroom5})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Maximizing Space in Small Bedrooms</Typography>
                                    <Typography variant='body1'  color={'textSecondary'}>Transform your small bedroom into a functional and stylish oasis...</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4}>
                            <Card variant='elevation' sx={{cursor: 'pointer'}} onClick={() => {
                                navigate('/blog/kitchen-remodeling')
                                scrollUp()
                            }}>
                                <CardHeader style={{backgroundImage: `url(${Kitchen4})`, backgroundSize: 'cover', height: '15rem'}}></CardHeader>
                                <CardContent style={{padding: '2rem'}}>
                                    <Typography color={'secondary'} mb={2}>Kitchen Remodel</Typography>
                                    <Typography variant='body1' color={'textSecondary'}>Create a stylish, functional space that will boost your home's appeal... </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>

            </Container>
                </Box>
    </div>
  )
}

export default DoublePaneWindows