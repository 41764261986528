import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  InputAdornment,
  styled,
  Grid,
  Dialog,
  DialogContent,
  Stack,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import BGImage from "../assets/banner.png";
import RealtyCard from "../components/RealtyCard";
import base from "../api/apis";
// import { scrollUp } from '../constants'
import { useParams } from "react-router-dom";
import NOAGENT from "../assets/noListing.png";
import LoadingPropertyItem from "../components/LoadingPropertyItem";
// import { LoadingButton } from '@mui/lab'
import _ from "lodash";
import { grey } from "@mui/material/colors";

const InputField = styled(TextField)(({ theme }) => ({
  margin: ".7rem 0",

  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    background: "#fff",
  },
}));
// const SearchBox = styled(Stack)(({theme}) => ({
// 	borderBottom: `1px solid ${grey[300]}`,
// 	display: 'block'
// }))
// const SearchBtn = styled(LoadingButton)(({theme}) => ({
// 	textTransform: 'none',
// 	height: '2.6rem',
// 	marginRight: '-2px',
//     borderRadius: '10px',
//     background: theme.secondaryColor,
// 	':hover': {
// 		background: theme.secondaryColor,
// 	}
// }))

const RealtyAgentSearch = () => {
  const { search } = useParams();
  const [loading, setLoading] = useState(true);
  // const [searchLoad, setSearchLoad] = useState(false);
  // const [searchFocus, setSearchFocus] = useState(true)
  // const [searchResult, setSearchResult] = useState()
  // const [searchAgents, setSearchAgents] = useState()
  // const [searchAreas, setSearchAreas] = useState()
  // const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [openLoader, setOpenLoader] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);

  //console.log(search)

  useEffect(() => {
    const fetchAgents = async () => {
      const { data: agents } = await base.get(`register/search_realtyagent/`);

      const brkAgents = agents?.results.filter((el) => el.broker);
      const relAgents = agents?.results.filter((el) => !el.broker);
      setData([...brkAgents, ...relAgents]);
      setPageCount(agents?.count);
      setLoading(false);
    };

    fetchAgents();
  }, [search]);

  const handleChange = async (e, page) => {
    setOpenLoader(true);
    const { data: agents } = await base.get(
      `/register/search_realtyagent/?page=${page}`
    );
    const brkAgents = agents?.results.filter((el) => el.broker);
    const relAgents = agents?.results.filter((el) => !el.broker);
    setData([...brkAgents, ...relAgents]);
    setOpenLoader(false);
    window.scrollTo({ top: 220, behavior: "smooth" });
  };

  // const searchFilter = (e) => {
  //     e.stopPropagation()
  // 	if(value === ''){
  // 		return
  // 	}
  // 	if(searchResult?.length > 0){
  //         setOpen(true)
  //         setSearchFocus(false)
  //     }
  // }

  // const clickAway = (e) => {
  // 	window.addEventListener('click', function(e){
  // 		if (document.getElementById('searchbox').contains(e.target)){
  // 		  setSearchFocus(true)
  // 		} else{
  // 		  setSearchFocus(false)
  // 		}
  // 	  });
  // }

  const onSearchHandler = async (obj) => {
    if (obj.search === "agent") {
      setLoading(true);
      // clear timer
      clearTimeout(timer);

      const newTimer = setTimeout(async () => {
        // check for previous search terms
        const { data: agents } = await base.get(
          `register/search_realtyagent/?name=${obj.value}`
        );
        const relAgents = agents?.results.filter((el) => !el.broker);
        //
        setLoading(false);
        setData(relAgents);
        setPageCount(agents?.count);
      }, 200);

      // save timerID
      setTimer(newTimer);
    }
    if (obj.search === "city") {
      setLoading(true);
      // clear timer
      clearTimeout(timer);

      const newTimer = setTimeout(async () => {
        // check for previous search terms
        const { data: areas } = await base.get(
          `register/search_realtyagent/?query=${obj.value}`
        );

        setLoading(false);
        setData([...areas?.results]);
        setPageCount(areas?.count);
      }, 200);

      // save timerID
      setTimer(newTimer);
    }
    if (
      (obj.search === "city" && obj.value === "") ||
      (obj.search === "agent" && obj.value === "")
    ) {
      const { data: agents } = await base.get(`register/search_realtyagent/`);

      const brkAgents = agents?.results.filter((el) => el.broker);
      const relAgents = agents?.results.filter((el) => !el.broker);
      setData([...brkAgents, ...relAgents]);
      setPageCount(agents?.count);
      setLoading(false);
    }
  };

  console.log(data);

  return (
    <>
      <Box>
        {/* Search Banner */}
        <Box
          sx={{
            height: "48vh",
            backgroundImage: `linear-gradient(180deg, rgba(0,0,0, 40%), rgba(0,0,0, 50%)), url(${BGImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
          }}
        >
          <Box
            id="searchbox"
            sx={{
              mt: "10%",
              width: { xs: "80%", sm: "80%", md: "60%", lg: "40%" },
              padding: "3rem",
              textAlign: "center",
            }}
          >
            <Typography
              component={"h3"}
              variant="h4"
              sx={{
                fontWeight: "400",
                color: "#fff",
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.8rem",
                  md: "2rem",
                  lg: "2rem",
                },
              }}
            >
              Find an agent who knows the market best
            </Typography>
            <Stack direction={"row"} gap={"1rem"}>
              <InputField
                autoComplete="off"
                value={value}
                variant="outlined"
                placeholder="Search by Agent name"
                fullWidth
                type="search"
                sx={{ flex: 1 }}
                onChange={(e) => {
                  setValue(e.target.value);
                  onSearchHandler({ search: "agent", value: e.target.value });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ color: grey[400] }} fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
              <InputField
                placeholder="Search by City, Neighbourhood"
                type="search"
                sx={{ flex: 1 }}
                fullWidth
                onChange={(e) =>
                  onSearchHandler({ search: "city", value: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ color: grey[400] }} fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            {/* {
									searchResult?.length > 0 && value && searchFocus ?
									
									<Box bgcolor={'#fff'} sx={{ 
											width: {xs: '80%', sm: '80%', md: '60%', lg: '40%'},
											overflowY: 'scroll', position:'absolute', mt: '-.4rem',
											zIndex:99, maxHeight: '30rem', minHeight: 'fit-content', borderRadius: '0 0 8px 8px',
											boxShadow: '0 2px 20px rgba(0,0,0,15%)'
										}}>
										<SearchBox>
											<Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: '#000'}}>Main Offices</Typography>
												<ListItem component='a' href={`/realty-agents/office`} button>
													<ListItemIcon sx={{minWidth: '30px'}}>{<Place sx={{fontSize: '1rem'}} />}</ListItemIcon>
													<ListItemText>
														<Typography>6920 Santa Teresa</Typography>
														<Typography variant='body2' color={'GrayText'}>Sanjose, CA</Typography>
													</ListItemText>
												</ListItem>
										</SearchBox>

										{
											searchAreas?.length > 0 && 
											<SearchBox>
												<Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: '#000'}}>{searchAreas?.length} Place(s)</Typography>
												{
													searchAreas?.map((res, index) => {
														return (
															<ListItem component='a' href={`/realty-agents/${value}`} button key={index} >
																<ListItemIcon sx={{minWidth: '30px'}}>{<Home sx={{fontSize: '1rem'}} />}</ListItemIcon>
																<ListItemText>
																	<Typography>{_.startCase(res?.city)} </Typography>
																	<Typography variant='body2' color={'textSecondary'}>{'CA, ' + res?.state}</Typography>
																</ListItemText>
															</ListItem>
														)
													})
												}
											</SearchBox>
										}

										{
											searchAgents?.length > 0 && 
											<SearchBox>
												<Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: '#000'}}>{searchAgents?.length} Agent(s)</Typography>
												{
													searchAgents?.map((res, index) => {
														return (
															<ListItem component='a' href={`/agents/${res?.id}`} button key={index} >
																<ListItemIcon sx={{minWidth: '30px'}}>{<Person sx={{fontSize: '1rem'}} />}</ListItemIcon>
																<ListItemText>
																	<Typography>{_.startCase(res?.first_name) + ' ' + _.startCase(res?.last_name)} </Typography>
																	<Typography variant='body2' color={'textSecondary'}>{'Realty agent'}</Typography>
																</ListItemText>
															</ListItem>
														)
													})
												}
											</SearchBox>
										}
										
									
									</Box> 
									: 
                                    searchResult?.length === 0 && value && searchFocus ?
                                    <SearchBox>
                                        <Typography sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: grey[500]}}>No Agents found</Typography>
                                    </SearchBox>
                                    : null

								} */}
          </Box>
        </Box>

        {/* Content */}
        <Box>
          <Container>
            {loading ? (
              <Grid container spacing={3}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((ld) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={ld}>
                      <LoadingPropertyItem />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box mt={"4rem"}>
                {data && data.length > 0 ? (
                  <Typography variant="h6" mb={3} sx={{ fontWeight: 400 }}>
                    {search === "office"
                      ? "6920 Santa Teresa Office"
                      : !search
                      ? ""
                      : `Agents Found in ${_.startCase(search)}`}
                  </Typography>
                ) : null}
                <Grid container spacing={3}>
                  {data && data.length > 0 ? (
                    data.map((agent) => {
                      return (
                        <Grid item xs={12} sm={12} lg={3} key={agent.id}>
                          <RealtyCard
                            broker={agent.broker}
                            intern={agent?.is_internship}
                            name={
                              agent.user.first_name + " " + agent.user.last_name
                            }
                            email={agent.user.email}
                            license={agent?.licenses[0]?.number}
                            referral={agent?.referral_status}
                            phone={agent.phone}
                            onClick={() =>
                              window.open(`/agents/${agent.id}`, "_blank")
                            }
                            image={agent.profile_image}
                          />
                        </Grid>
                      );
                    })
                  ) : (
                    <Box mt={"3rem"} padding="3rem" width="100%">
                      <img
                        src={NOAGENT}
                        width="20%"
                        style={{ margin: "0 auto", display: "block" }}
                        alt="no-agent"
                      />
                      <Typography
                        mt={3}
                        variant="h6"
                        textAlign={"center"}
                        sx={{ fontWeight: 500 }}
                      >
                        Sorry, no agent found
                      </Typography>
                    </Box>
                  )}
                </Grid>

                <Pagination
                  size="large"
                  color="secondary"
                  sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}
                  shape="rounded"
                  variant="outlined"
                  count={Math.ceil(pageCount / 12)}
                  onChange={handleChange}
                />
              </Box>
            )}
          </Container>
        </Box>

        {/* Loading */}
        <Dialog open={openLoader}>
          <DialogContent
            sx={{ padding: "3rem", width: "20rem", textAlign: "center" }}
          >
            <CircularProgress value={30} color="primary" />
            <Typography mt={2}>Loading...</Typography>
          </DialogContent>
        </Dialog>

        {/* Check for search results */}
        {/* <Dialog open={open} maxWidth='sm' fullWidth>
                <DialogContent sx={{padding: '2rem'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography variant='h6'>Do You Mean</Typography>
                        <IconButton onClick={()=> setOpen(false)} ><Close fontSize='small' /></IconButton>
                    </Box>
                    <Divider sx={{my: '10px'}} />
                    <Box maxHeight={'32rem'} sx={{overflowY: 'scroll', minHeight: 'fit-content'}}>
                    <SearchBox>
                            <Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: '#000'}}>Main Offices</Typography>
                                <ListItem component='a' href={`/realty-agents/office`} button>
                                    <ListItemIcon sx={{minWidth: '30px'}}>{<Place sx={{fontSize: '1rem'}} />}</ListItemIcon>
                                    <ListItemText>
                                        <Typography>6920 Santa Teresa</Typography>
                                        <Typography variant='body2' color={'GrayText'}>Sanjose, CA</Typography>
                                    </ListItemText>
                                </ListItem>
                        </SearchBox>

                        {
                            searchAreas?.length > 0 && 
                            <SearchBox>
                                <Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: '#000'}}>{searchAreas?.length} Place(s)</Typography>
                                {
                                    searchAreas?.map((res, index) => {
                                        return (
                                            <ListItem component='a' href={`/realty-agents/${res?.city}`} button key={index} >
                                                <ListItemIcon sx={{minWidth: '30px'}}>{<Home sx={{fontSize: '1rem'}} />}</ListItemIcon>
                                                <ListItemText>
                                                    <Typography>{_.startCase(res?.city)} </Typography>
                                                    <Typography variant='body2' color={'textSecondary'}>{'CA, ' + res?.state}</Typography>
                                                </ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </SearchBox>
                        }

                        {
                            searchAgents?.length > 0 && 
                            <SearchBox>
                                <Typography textAlign={'left'} sx={{ml: '1rem', mt: '1rem', fontWeight: 500, color: '#000'}}>{searchAgents?.length} Agent(s)</Typography>
                                {
                                    searchResult?.map((res, index) => {
                                        return (
                                            <ListItem component='a' href={`/agents/${res?.id}`} button key={index} >
                                                <ListItemIcon sx={{minWidth: '30px'}}>{<Person sx={{fontSize: '1rem'}} />}</ListItemIcon>
                                                <ListItemText>
                                                    <Typography>{_.startCase(res?.first_name) + ' ' + _.startCase(res?.last_name)} </Typography>
                                                    <Typography variant='body2' color={'textSecondary'}>{'Realty agent'}</Typography>
                                                </ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </SearchBox>
                        }
                        <Typography mt={2} textAlign={'center'} sx={{fontSize: '.8rem'}} variant='body2'>Don't see what you're looking for? <br/>Your search might be outside our service areas.</Typography>
                    </Box>
                </DialogContent>
            </Dialog> */}
      </Box>
    </>
  );
};

export default RealtyAgentSearch;
